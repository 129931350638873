import gSass from "@utils/sass";
import csn from "classnames";
import React, { Component, createRef } from "react";
import gImg from "@/utils/img";
import { AppState } from "@/redux/stores/app";
import { Dispatch } from "redux";
import { Action } from "@/typings";
import localeName from "@/utils/localeName";
import * as appAction from "@redux/actions/app";
import { connect } from "react-redux";
import newsApi, { InitMeta, RecruitType } from "@api/news";
import { Helmet } from "react-helmet";
import CommonHeader from "@/components/CommonHeader";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Empty, Icon, Pagination } from "antd";
import RcQueueAnim from "rc-queue-anim";
import ScrollOverPack from "rc-scroll-anim/lib/ScrollOverPack";
import Footer from "@components/Footer";
import Header from "@components/Header";
const style = gSass.index.recruit.school;

export interface ProcedurePreList {
  img: string;
  name: string;
}
export interface WelfareType {
  img: string;
  title: string;
  content: string[];
}
interface Props {}
interface State {
  pageInitMeta: InitMeta;
  recruitList: RecruitType[];
  page: number;
  limit: number;
  count: number;
  filter: Record<string, any>;
  procedureList: ProcedurePreList[];
  welfareList: WelfareType[];
  trip: string;
  type: number;
}
const mapStateToProps = (state: AppState) => ({
  app: state.app,
  lang: state.app.lang,
});
const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    setLanguage: (lang: string) => {
      dispatch(appAction.setLanguage(lang));
    },
  };
};
// @ts-ignore
@injectIntl
// @ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
export default class School extends Component<
  Props &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> &
    InjectedIntlProps,
  State
> {
  scrollDiv = createRef<HTMLDivElement>();
  constructor(props: any) {
    super(props);
    this.state = this.getInitState();
  }
  getInitState = (): State => {
    return {
      type: 1, //代表校园招聘
      trip: "",
      pageInitMeta: {
        title: "",
        subTitle: "",
        keywords: "",
        description: "",
      },
      page: 1,
      limit: 10,
      count: 3,
      filter: {
        languages: "zh-cn",
      },
      recruitList: [],
      procedureList: [
        {
          img: gImg.recruit.apply,
          name: localeName.app.home.招聘.网络申请,
        },
        {
          img: gImg.recruit.meeting,
          name: localeName.app.home.招聘.参加宣讲会,
        },
        {
          img: gImg.recruit.pen,
          name: localeName.app.home.招聘.笔试,
        },
        {
          img: gImg.recruit.interview,
          name: localeName.app.home.招聘.面试,
        },
        {
          img: gImg.recruit.offer,
          name: localeName.app.home.招聘.OFFER,
        },
        {
          img: gImg.recruit.entry,
          name: localeName.app.home.招聘.入职,
        },
      ],
      welfareList: [
        {
          img: gImg.recruit.one,
          title: localeName.app.home.招聘.关爱生活品质,
          content: [
            localeName.app.home.招聘.员工免费定期体检,
            localeName.app.home.招聘.补充商业医疗险,
            localeName.app.home.招聘.弹性工作时间,
          ],
        },
        {
          img: gImg.recruit.two,
          title: localeName.app.home.招聘.关注动作服务,
          content: [
            localeName.app.home.招聘.优质工作餐保障,
            localeName.app.home.招聘.宿舍安排保障,
            localeName.app.home.招聘.交通出行保障,
          ],
        },
        {
          img: gImg.recruit.three,
          title: localeName.app.home.招聘.重视员工体验,
          content: [
            localeName.app.home.招聘.丰富的部门团建活动,
            localeName.app.home.招聘.趣味运动和家庭日,
            localeName.app.home.招聘.员工活动中心,
            localeName.app.home.招聘.各类俱乐部,
          ],
        },
        {
          img: gImg.recruit.four,
          title: localeName.app.home.招聘.贴心定制福利,
          content: [
            localeName.app.home.招聘.传统佳节礼物,
            localeName.app.home.招聘.生日祝福新婚关爱,
            localeName.app.home.招聘.入职纪念,
            localeName.app.home.招聘.长期服务奖励,
          ],
        },
      ],
    };
  };
  componentDidMount() {
    if (this.scrollDiv.current) {
      this.scrollDiv.current.scrollIntoView();
    }
    this.setState(
      {
        filter: {
          ...this.state.filter,
          languages: this.props.app.lang,
        },
      },
      () => {
        this.init();
      }
    );
  }
  init = () => {
    this.listKeywords();
  };
  listKeywords = async () => {
    let { data: pageInitMeta } = await newsApi.getPageInitMeta();
    this.setState({
      pageInitMeta,
    });
    let { filter, trip, page, limit, type } = this.state;
    let { data } = await newsApi.getTrip({ filter });
    trip = data[0].description;
    this.setState({
      trip,
    });
    let {
      data: { list: recruitList },
    } = await newsApi.getSociety({ page, limit, type, filter });
    this.setState({
      recruitList,
    });
  };
  // 获取语言修改后props值
  componentWillReceiveProps(nextProps: any) {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          languages: nextProps.lang,
        },
      },
      () => {
        this.init();
      }
    );
  }
  render() {
    const { messages: m } = this.props.intl;
    let {
      pageInitMeta,
      recruitList,
      page,
      count,
      procedureList,
      welfareList,
    } = this.state;
    return (
      <div className={style.main} ref={this.scrollDiv}>
        <Header activeTitle="home" />
        <Helmet>
          <title>{pageInitMeta.title}</title>
          <meta name="description" content={pageInitMeta.description} />
          <meta name="keywords" content={pageInitMeta.keywords} />
        </Helmet>
        <div className={csn(style.headerBanner, "flex")}>
          <CommonHeader
            banner={gImg.recruit.school}
            mainTitle={m[localeName.app.common.导航.招聘]}
            subTitleOne={m[localeName.app.common.导航.招聘]}
            subTitleTwo={m[localeName.app.home.招聘.校园招聘]}
          />
        </div>
        {/* 标题 */}
        <div className={csn(style.recruitHeader, "flex")}>
          <div className={csn(style.headerContent, "flex")}>
            <Link to="/school" className={csn(style.itemWrap, "flex")}>
              <div className={style.title}>
                {m[localeName.app.home.招聘.校园招聘]}
              </div>
            </Link>
            <Link to="/recruit" className={csn(style.itemWrap, "flex")}>
              <div className={style.title}>
                {m[localeName.app.home.招聘.社会招聘]}
              </div>
            </Link>
          </div>
        </div>
        {/* 招聘流程 */}
        <div className={style.procedureWrap} id="procedure">
          <ScrollOverPack playScale={0} location="procedure">
            <RcQueueAnim
              leaveReverse
              ease="easeInQuart"
              type="bottom"
              delay={300}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div key="1" className={style.mainTitle}>
                {m[localeName.app.home.招聘.校招流程]}
              </div>
              <div key="2" className={csn(style.procedureList, "flex")}>
                <RcQueueAnim
                  leaveReverse
                  ease="easeInQuart"
                  type="top"
                  delay={300}
                  className={style.procedureListWrap}
                >
                  {procedureList.map((v, k) => {
                    return (
                      <div className={csn(style.item, "flex")} key={k + 1}>
                        <div className={csn(style.imgWrap, "flex")}>
                          <img className={style.img} src={v.img} alt="" />
                        </div>
                        <div className={style.name}>{m[v.name as string]}</div>
                      </div>
                    );
                  })}
                </RcQueueAnim>
              </div>
            </RcQueueAnim>
          </ScrollOverPack>
        </div>
        {/* 宣讲行程 */}
        <div className={style.tripWrap} id="trip">
          <div className={csn(style.tripContent, "flex")}>
            <ScrollOverPack playScale={0.1} location="trip">
              <RcQueueAnim
                leaveReverse
                ease="easeInOutBack"
                type="bottom"
                delay={300}
                className={style.tripContainer}
              >
                <div className={csn(style.left, "flex")}>
                  <RcQueueAnim
                    leaveReverse
                    ease="easeInBack"
                    type="bottom"
                    delay={500}
                    className={style.leftWrap}
                  >
                    <div key="1" className={style.title}>
                      {m[localeName.app.home.招聘.为什么选择希荷]}?
                    </div>
                    <div key="2" className={style.content}>
                      {this.state.trip}
                    </div>
                  </RcQueueAnim>
                </div>
                <div key="2" className={csn(style.right, "flex")}>
                  <div className={csn(style.imgWrap, "flex")}>
                    <img src={gImg.recruit.map} alt="" />
                  </div>
                </div>
              </RcQueueAnim>
            </ScrollOverPack>
          </div>
        </div>
        {/* 福利 */}
        <div className={style.welfareWrap} id="welfare">
          <div className={csn(style.welfareContent, "flex")}>
            <ScrollOverPack playScale={0.1} location="welfare">
              <RcQueueAnim
                leaveReverse
                ease="easeInQuart"
                type="bottom"
                delay={100}
                className={csn(style.welfareContainer, "flex")}
              >
                <div key="1" className={style.mainTitle}>
                  {m[localeName.app.home.招聘.福利]}
                </div>
                <div key="2" className={csn(style.welfareList, "flex")}>
                  <RcQueueAnim
                    leaveReverse
                    ease="easeInQuart"
                    type="top"
                    delay={300}
                    className={csn(style.welfareListWrap, "flex")}
                  >
                    {welfareList.map((v, k) => {
                      return (
                        <div className={csn(style.item, "flex")} key={k + 1}>
                          <div className={csn(style.imgWrap, "flex")}>
                            <img className={style.img} src={v.img} alt="" />
                          </div>
                          <div className={style.name}>
                            {m[v.title as string]}
                          </div>
                          <div className={style.content}>
                            {v.content.map((v1, k1) => {
                              return <p key={k1}>{m[v1 as string]}</p>;
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </RcQueueAnim>
                </div>
              </RcQueueAnim>
            </ScrollOverPack>
          </div>
        </div>
        {/* 薪酬 */}
        <div className={csn(style.wageWrap, "flex")} id="wage">
          <div className={csn(style.wageContent, "flex")}>
            <ScrollOverPack playScale={0.3} location="wage">
              <RcQueueAnim
                leaveReverse
                ease="easeInQuad"
                type="bottom"
                delay={300}
              >
                <div key="1" className={style.title}>
                  {m[localeName.app.home.招聘.薪酬]}
                </div>
                <div key="2" className={style.content}>
                  <p> {m[localeName.app.home.招聘.具有行业竞争力的薪资]}</p>
                  <p> {m[localeName.app.home.招聘.绩效导向的多样化激励]}</p>
                  <p> {m[localeName.app.home.招聘.市场匹配的年度涨薪机制]}</p>
                </div>
              </RcQueueAnim>
            </ScrollOverPack>
          </div>
        </div>
        {/* 招聘列表 */}
        <div className={style.recruitWrap} id="recruit">
          <ScrollOverPack playScale={0.2} location="recruit">
            <RcQueueAnim
              leaveReverse
              ease="easeInOutQuart"
              type="bottom"
              delay={100}
              className={csn(style.recruitContent, "flex")}
            >
              <div key="1" className={style.mainTitle}>
                {m[localeName.app.home.招聘.校招岗位]}
              </div>
              <div key="2" className={csn(style.recruitList, "flex")}>
                <RcQueueAnim
                  leaveReverse
                  ease="easeInQuart"
                  type="top"
                  delay={300}
                  className={csn(style.recruitListWrap, "flex")}
                >
                  {recruitList.length > 0 ? (
                    recruitList.map((v, k) => {
                      return (
                        <div
                          className={csn(style.item, "flex")}
                          key={k}
                          onClick={() => {
                            let recruitList = this.state.recruitList;
                            if (recruitList[k].isActive) {
                              for (let recruit of recruitList) {
                                recruit.isActive = false;
                              }
                            } else {
                              for (let recruit of recruitList) {
                                recruit.isActive = false;
                              }
                              recruitList[k].isActive = true;
                            }
                            this.setState({
                              recruitList,
                            });
                          }}
                        >
                          <div className={csn(style.titleWrap, "flex")}>
                            <div className={style.title}>{v.title}</div>
                            <div className={csn(style.iconWrap, "flex")}>
                              {v.isActive ? (
                                <Icon
                                  type="up"
                                  style={{ fontSize: 16, color: "#717171" }}
                                ></Icon>
                              ) : (
                                <Icon
                                  type="down"
                                  style={{ fontSize: 16, color: "#717171" }}
                                ></Icon>
                              )}
                            </div>
                          </div>
                          <div
                            className={csn(
                              v.isActive ? style.active : "",
                              style.detailInfo,
                              "flex"
                            )}
                          >
                            <div className={style.title}>
                              {m[localeName.app.home.招聘.岗位职责]}:
                            </div>
                            <div
                              className={style.demand}
                              dangerouslySetInnerHTML={{ __html: v.data[0] }}
                            ></div>
                            <div className={style.title}>
                              {m[localeName.app.home.招聘.要求]}:
                            </div>
                            <div
                              className={style.demand}
                              dangerouslySetInnerHTML={{ __html: v.data[1] }}
                            ></div>
                            <div className={style.title}>
                              {m[localeName.app.home.招聘.薪资待遇]}:
                            </div>
                            <div
                              className={style.demand}
                              dangerouslySetInnerHTML={{ __html: v.data[2] }}
                            ></div>
                            <a
                              className={csn(style.bottomWrap, "flex")}
                              href={"mailto:" + v.email}
                            >
                              email:
                              <div className={style.bottomItem}>
                                &nbsp;&nbsp;{v.email}
                              </div>
                            </a>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <Empty key="2" style={{ marginTop: "50px" }}></Empty>
                  )}
                </RcQueueAnim>
              </div>
              {recruitList.length > 0 ? (
                <Pagination
                  key="3"
                  className={style.page}
                  defaultCurrent={page}
                  total={count}
                  onChange={(page) => {
                    this.setState({
                      page,
                    });
                  }}
                ></Pagination>
              ) : null}
            </RcQueueAnim>
          </ScrollOverPack>
        </div>
        <Footer />
      </div>
    );
  }
}
