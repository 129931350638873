import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import loadingReducer, { loadingNum } from "../reducers/loading";
import appReducer, { appState } from "../reducers/app";
import createSagaMiddleware from "redux-saga";

import { persistStore, persistReducer, PersistConfig } from "redux-persist";

import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// import rootSaga from "../sagas/app"
const sagaMiddleware = createSagaMiddleware();
export interface AppState {
  app: appState;
  time: loadingNum;
}
const persistConfig: PersistConfig = {
  key: "root",
  storage,
  whitelist: ["app"]
};
persistReducer(
  persistConfig,
  combineReducers({ app: appReducer, time: loadingReducer })
);
export default () => {
  let store = createStore(
    persistReducer(
      persistConfig,
      combineReducers({ app: appReducer, time: loadingReducer })
    ),
    composeWithDevTools(applyMiddleware(sagaMiddleware))
  );
  // sagaMiddleware.run(rootSaga)
  let persistor = persistStore(store);
  return { store, persistor };
};
