import CommonHeader from "@/components/CommonHeader";
import { CurrNav } from "@/components/Header";
import { CDN_BASE_URL } from "@/config/prod";
import { AppState } from "@/redux/stores/app";
import { Action } from "@/typings";
import gImg from "@/utils/img";
import localeName from "@/utils/localeName";
import newsApi, { HistroyList, InitMeta } from "@api/news";
import * as appAction from "@redux/actions/app";
import gSass from "@utils/sass";
import csn from "classnames";
import RcQueueAnim from "rc-queue-anim";
import ScrollOverPack from "rc-scroll-anim/lib/ScrollOverPack";
import React, { Component, createRef } from "react";
import { Helmet } from "react-helmet";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { Dispatch } from "redux";
import Footer from "@components/Footer";
import Header from "@components/Header";
const style = gSass.index.about.index;

interface Props {}
interface State {
  pageInitMeta: InitMeta;
  histroyList: HistroyList[];
  listWrapWidth: number;
  listLeft: number;
  listRight: number;
  selectId: number;
  page: number;
  limit: number;
  count: number;
  filter: {
    languages: string;
  };
}
const mapStateToProps = (state: AppState) => ({
  app: state.app,
  lang: state.app.lang,
});
const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    setLoadingTime: (time: boolean) => {
      dispatch(appAction.setLoadingTime(time));
    },
    setCurrNav: (currNav: CurrNav) => {
      dispatch(appAction.setCurrNav(currNav));
    },
    setLanguage: (lang: string) => {
      dispatch(appAction.setLanguage(lang));
    },
  };
};
// @ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
// @ts-ignore
@injectIntl
export default class Index extends Component<
  Props &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> &
    InjectedIntlProps,
  State
> {
  static defaultProps: ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> &
    InjectedIntlProps;
  scrollDiv = createRef<HTMLDivElement>();
  constructor(props: any) {
    super(props);
    this.state = this.getInitState();
  }
  getInitState = (): State => {
    return {
      page: 0,
      limit: 6,
      count: 0,
      filter: {
        languages: "zh-cn",
      },
      pageInitMeta: {
        title: "",
        subTitle: "",
        keywords: "",
        description: "",
      },
      histroyList: [],
      listWrapWidth: 0,
      listLeft: 0,
      listRight: 0,
      selectId: 0,
    };
  };
  componentDidMount() {
    if (this.scrollDiv.current) {
      this.scrollDiv.current.scrollIntoView();
    }
    this.setState(
      {
        filter: {
          ...this.state.filter,
          languages: this.props.app.lang,
        },
      },
      () => {
        this.init();
      }
    );
  }
  // componentWillUnmount() {
  //   // window.addEventListener("scroll", this.scroll)
  //   this.init();
  // }
  // 获取语言修改后props值
  componentWillReceiveProps(nextProps: any) {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          languages: nextProps.lang,
        },
      },
      () => {
        this.init();
      }
    );
  }
  init = () => {
    this.listKeywords();
  };
  listKeywords = async () => {
    let { data: pageInitMeta } = await newsApi.getPageInitMeta();
    this.setState({
      pageInitMeta,
    });
    let { page, limit, filter } = this.state;
    let { data: histroyLists } = await newsApi.getHistory({
      page,
      limit,
      filter,
    });
    this.setState({
      histroyList: histroyLists,
    });
    let listLength = histroyLists.length * 286;
    this.setState({
      listWrapWidth: listLength,
    });
  };
  left = () => {
    let { selectId } = this.state;
    if (selectId > 0) {
      let left = -((selectId - 1) * 286);
      let currentId = selectId - 1;
      this.setState({
        selectId: currentId,
        listLeft: left,
      });
    }
  };
  right = () => {
    let { listWrapWidth, selectId } = this.state;
    let left = -((selectId + 1) * 286);
    if (listWrapWidth + left <= 1100) {
      return;
    } else {
      let currentId = selectId + 1;
      this.setState({
        selectId: currentId,
        listLeft: left,
      });
    }
  };

  render() {
    let { pageInitMeta, histroyList, listWrapWidth, listLeft } = this.state;
    const { messages: m } = this.props.intl;
    return (
      <div className={style.aboutWrap} ref={this.scrollDiv}>
        <Header activeTitle="home" />
        <Helmet>
          <title>{pageInitMeta.title}</title>
          <meta name="description" content={pageInitMeta.description} />
          <meta name="keywords" content={pageInitMeta.keywords} />
        </Helmet>
        <div className={csn(style.headerBanner, "flex")}>
          <CommonHeader
            banner={gImg.about.aboutBanner}
            mainTitle={m[localeName.app.common.导航.关于我们]}
            subTitleOne={m[localeName.app.common.导航.关于我们]}
            subTitleTwo={""}
          />
        </div>
        
        <div className={style.introduceTwo} id="introduceOne">
        {/* <div className={style.introduceOne} id="introduceOne"> */}
          <ScrollOverPack location="introduceOne">
            <RcQueueAnim
              leaveReverse
              ease="easeInOutQuart"
              type="top"
              delay={300}
            >
              <div
                className={csn(style.introduceItem, "flex")}
                id="introduceOne"
              >
                <div className={csn(style.left, "flex")}>
                  <div className={csn(style.leftWrap, "flex")}>
                    <div key="1" className={style.title}>
                      {m[localeName.app.home.关于我们.公司简介]}
                    </div>
                    <div key="2" className={style.content}>
                      {m[localeName.app.home.关于我们.ES于2010年]}
                    </div>
                  </div>
                </div>
                <div className={csn(style.right, "flex")}>
                  <div className={csn(style.imgWrap, "flex")}>
                    <img src={gImg.about.factory} alt="" />
                  </div>
                </div>
              </div>
            </RcQueueAnim>
          </ScrollOverPack>
        </div>
        {/* <div className={style.introduceTwo} id="introduceTwo">
          <ScrollOverPack playScale={0.1} location="introduceTwo">
            <RcQueueAnim
              leaveReverse
              ease="easeInOutQuart"
              type="top"
              delay={300}
            >
              <div className={csn(style.introduceItemTwo, "flex")}>
                <div className={csn(style.left, "flex")}>
                  <div className={csn(style.imgWrap, "flex")}>
                    <img src={gImg.about.lou} alt="" />
                  </div>
                </div>
                <div className={csn(style.right, "flex")}>
                  <div className={csn(style.rightWrap, "flex")}>
                    <div key="1" className={csn(style.title, "flex")}>
                      {m[localeName.app.home.关于我们.合作伙伴AWM]}
                    </div>
                    <div key="2" className={style.content}>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {m[localeName.app.home.关于我们.AWM是一家]}
                    </div>
                  </div>
                </div>
              </div>
            </RcQueueAnim>
          </ScrollOverPack>
        </div> */}
        <div className={style.history} id="history">
          <MediaQuery minWidth={1200}>
            <div className={csn(style.historyWrap, "flex")}>
              <div className={csn(style.topContent, "flex")}>
                <div className={style.title}>
                  {m[localeName.app.home.关于我们.发展历史]}
                </div>
                <div className={csn(style.arrowWrap, "flex")}>
                  <div
                    className={csn(style.imgWrap, "flex")}
                    onClick={this.left}
                  >
                    <img src={gImg.about.left} alt="" />
                  </div>
                  <div
                    className={csn(style.imgWrap, "flex")}
                    onClick={this.right}
                  >
                    <img src={gImg.about.right} alt="" />
                  </div>
                </div>
              </div>
              <RcQueueAnim
                leaveReverse
                ease="easeInOutQuart"
                type="top"
                delay={300}
                className={style.historyListWrap}
              >
                <div
                  className={csn(style.historyList, "flex")}
                  style={{
                    width: listWrapWidth,
                    transition: " all 1s ease-in-out",
                    left: listLeft + "px",
                  }}
                >
                  {histroyList.map((v, k) => {
                    return (
                      <div key={k + 1} className={csn(style.item, "flex")}>
                        <div className={style.line}></div>

                        <div className={csn(style.contentWrap, "flex")}>
                          <div key="1" className={style.title}>
                            {v.year}
                          </div>
                          <div key="2" className={style.content}>
                            {v.content}
                          </div>
                          <div key="3" className={csn(style.imgWrap, "flex")}>
                            {v.picture.length > 0 ? (
                              <img
                                src={CDN_BASE_URL + v.picture[0].url}
                                alt=""
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </RcQueueAnim>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={1200}>
            <div className={csn(style.mobileHistoryWrap, "flex")}>
              <div className={style.title}>
                {m[localeName.app.home.关于我们.发展历史]}
              </div>
              <RcQueueAnim className={csn(style.mobileHistory, "flex")}>
                {histroyList.map((v, k) => {
                  return (
                    <div key={k + 1} className={csn(style.item, "flex")}>
                      <div className={style.line}></div>
                      <div className={csn(style.contentWrap, "flex")}>
                        <div key="1" className={style.title}>
                          {v.year}
                        </div>
                        <div key="2" className={style.content}>
                          {v.content}
                        </div>
                        <div key="3" className={csn(style.imgWrap, "flex")}>
                          {v.picture.length > 0 ? (
                            <img src={CDN_BASE_URL + v.picture[0].url} alt="" />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </RcQueueAnim>
            </div>
          </MediaQuery>
        </div>
        <div className={style.detailIntro} id="detailIntro">
          <div className={csn(style.detailIntroWrap, "flex")}>
            <div className={style.title}>
              {m[localeName.app.home.关于我们.生产零件批准过程]}
            </div>

            <ScrollOverPack playScale={0.2} location="detailIntro">
              <RcQueueAnim
                leaveReverse
                ease="easeInOutQuart"
                type="bottom"
                delay={100}
              >
                <div className={csn(style.detailList, "flex")}>
                  <div className={csn(style.item, "flex")}>
                    <div className={csn(style.leftWrap, "flex")}>
                      <div className={csn(style.imgWrap, "flex")}>
                        <img src={gImg.about.one} alt="" />
                      </div>
                    </div>
                    <div className={csn(style.rightWrap, "flex")}>
                      <RcQueueAnim
                        leaveReverse
                        ease="easeInOutQuart"
                        type="top"
                        delay={300}
                      >
                        <div key="1" className={csn(style.contentList, "flex")}>
                          <div className={style.title}>
                            {m[localeName.app.home.关于我们.阶段一]}
                          </div>
                          <div className={csn(style.contentWrap, "flex")}>
                            <div className={style.content}>
                              {m[localeName.app.home.关于我们.充分理解客户需求]}
                            </div>
                            <div className={style.content}>
                              {m[localeName.app.home.关于我们.明确项目成员职责]}
                            </div>
                            <div className={style.content}>
                              {m[localeName.app.home.关于我们.制定设计失效]}
                            </div>
                          </div>
                        </div>
                        <div key="2" className={style.numberWrap}>
                          <div className={csn(style.numImg, "flex")}>
                            <img src={gImg.about.num1} alt="" />
                          </div>
                        </div>
                      </RcQueueAnim>
                    </div>
                  </div>
                  <div className={csn(style.item, "flex")}>
                    <div className={csn(style.leftWrap, "flex")}>
                      <div className={csn(style.imgWrap, "flex")}>
                        <img src={gImg.about.two} alt="" />
                      </div>
                    </div>
                    <div className={csn(style.rightWrap, "flex")}>
                      <RcQueueAnim
                        leaveReverse
                        ease="easeInOutQuart"
                        type="bottom"
                        delay={400}
                      >
                        <div key="1" className={csn(style.contentList, "flex")}>
                          <div className={style.title}>
                            {m[localeName.app.home.关于我们.阶段二]}
                          </div>
                          <div className={csn(style.contentWrap, "flex")}>
                            <div className={style.content}>
                              {m[localeName.app.home.关于我们.制定详细的过程]}
                            </div>
                            <div className={style.content}>
                              {m[localeName.app.home.关于我们.控制流程图]}
                            </div>
                            <div className={style.content}>
                              {m[localeName.app.home.关于我们.测量系统分析]}
                            </div>
                          </div>
                        </div>
                        <div key="2" className={style.numberWrap}>
                          <div className={csn(style.numImg, "flex")}>
                            <img src={gImg.about.num2} alt="" />
                          </div>
                        </div>
                      </RcQueueAnim>
                    </div>
                  </div>
                  <div className={csn(style.item, "flex")}>
                    <div className={csn(style.leftWrap, "flex")}>
                      <div className={csn(style.imgWrap, "flex")}>
                        <img src={gImg.about.three} alt="" />
                      </div>
                    </div>

                    <div className={csn(style.rightWrap, "flex")}>
                      <RcQueueAnim
                        leaveReverse
                        ease="easeInOutQuart"
                        type="bottom"
                        delay={500}
                      >
                        <div key="1" className={csn(style.contentList, "flex")}>
                          <div className={style.title}>
                            {m[localeName.app.home.关于我们.阶段三]}
                          </div>
                          <div className={csn(style.contentWrap, "flex")}>
                            <div className={style.content}>
                              {m[localeName.app.home.关于我们.当所有客户需求]}
                            </div>
                            <div className={style.content}>
                              {
                                m[
                                  localeName.app.home.关于我们
                                    .ES会向客户提供样品阶段
                                ]
                              }
                            </div>
                            <div className={style.content}>
                              {m[localeName.app.home.关于我们.ES可为客户提供]}
                            </div>
                          </div>
                        </div>
                        <div key="2" className={style.numberWrap}>
                          <div className={csn(style.numImg, "flex")}>
                            <img src={gImg.about.num3} alt="" />
                          </div>
                        </div>
                      </RcQueueAnim>
                    </div>
                  </div>
                </div>
              </RcQueueAnim>
            </ScrollOverPack>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
