import localeName from "@utils/localeName";
export default {
  [localeName.app.公司名]: "Xihe",
  [localeName.app.集团名]: "Xihe",
  [localeName.app.欢迎浏览我们的网页]: "Welcome to our website",
  [localeName.app.common.导航.我们的产品]: "Products",
  [localeName.app.common.导航.我们的服务]: "Services",
  [localeName.app.common.导航.首页]: "Home",
  [localeName.app.common.导航.关于我们]: "About",
  [localeName.app.common.导航.招聘]: "Recruit",
  [localeName.app.common.导航.新闻下载]: "News",
  [localeName.app.common.导航.联系我们]: "Contact",

  [localeName.app.加载更多]: "load more",
  [localeName.app.common.导航.了解详情]: "Learn more",
  [localeName.app.common.导航.查看更多]: "see more",
  [localeName.app.common.导航.欢迎浏览]: "Welcome to Xihe website!",
  // 地区:
  [localeName.app.common.导航.中国]: "China",
  [localeName.app.common.导航.日本]: "Japan",
  [localeName.app.common.导航.上海]: "Shanghai",
  [localeName.app.common.导航.新加坡]: "Singapore",
  [localeName.app.common.导航.德国]: "Germany",
  [localeName.app.common.导航.波兰]: "Poland",
  [localeName.app.common.导航.瑞士]: "Switzerland",
  [localeName.app.common.导航.法国]: "France",
  [localeName.app.common.导航.西班牙]: "Spain",
  [localeName.app.common.导航.捷克]: "Czech Republic",

  // 首页
  [localeName.app.home.首页.主标题一]:
    "Your Reliable Precision Injection  & Mould Partner",
  [localeName.app.home.首页.主标题二]: "",
  [localeName.app.home.首页.关于希荷]: "About ES",
  [localeName.app.home.首页.ES起步]:
    "ES group was founded in Singapore in the year of 2010, started in the industrial agglomeration and transportation convenient city- Shanghai, China, and expanding in Dusseldorf, Germany which is famous for its lean manufacture.",
  [localeName.app.home.首页.始终坚持匠心]:
    "Es always adhere to the ingenuity of inheritance and innovation beyond. Es has set up a strong partner joint with a Germany company 'AWM' which has 50 years of injection molding experience. Es committed to providing advanced solutions in transmission systems for global customers and high-precision parts in automotive, electronical, medical and other industries. Es is working hard to become a 'trustworthy precision injection mold partner for you'!",
  [localeName.app.home.首页.ES致力于]:
    "ES is committed to providing high-precision molds",
  [localeName.app.home.首页.ES致力于分段]:
    " and injection molded parts to customers worldwide",
  [localeName.app.home.首页.我们的原则]:
    "Our principle 'customer first, communication first'",
  [localeName.app.home.首页.严谨高效]:
    "Rigorous and efficient project management",
  [localeName.app.home.首页.项目咨询]: "Project Consulting",
  [localeName.app.home.首页.客户输入]:
    "Customer input: including drawings (2d, 3d), products, technical descriptions, product function descriptions, etc. ES output: Product feasibility analysis is proposed within 3-5 working days, one round of DFM, project cycle summary, cost estimation.",
  [localeName.app.home.首页.技术方案]: "Technical Solutions",
  [localeName.app.home.首页.产品分析]:
    "Use Moldflow & Kisssoft to fully analyze the product and provide DFM, mold design, raw material selection, prototype, mass production mold and one-time product mass production solution.",

  [localeName.app.home.首页.合同签订]: "Sign a Contract",
  [localeName.app.home.首页.合同签订客户]:
    "Customers can sign contracts with any of es's subsidiaries to ensure their core interests.",
  [localeName.app.home.首页.交货完成]: "Delivery Completed",
  [localeName.app.home.首页.输送到终端客户]:
    "ES will pack and ship on the premise of ensuring the quality of the mold and product, and choose the transportation solution that takes the shortest time, the most efficient and the least cost. ES German subsidiary can do product warehousing for European customers and then deliver to end customers to save costs.",

  [localeName.app.home.首页.分布简介]:
    "ES is committed to providing global customers with high-precision and high-quality automotive parts, electronics and medical parts and components and molds. At present, the company's business has expanded from Singapore to Southeast China, Japan, Germany, Switzerland, Spain, Czech and other developed countries. . ES strives to be a 'trustworthy precision mold injection partner around you'!",
  [localeName.app.home.首页.最新发布]: "Latest Release",
  [localeName.app.home.首页.坚信最初]:
    "Firmly believe in the original dream, work hard, and constantly create new miracles.",
  [localeName.app.home.首页.细小做到精致]:
    "ES not only moves forward quickly, but also strives to make small and delicate!",
  [localeName.app.home.首页.我们的目标]: "Our Goal",
  [localeName.app.home.首页.身边的]:
    "To Be Your Trusted Mould and Injection Partner",

  // 关于我们
  [localeName.app.home.关于我们.公司简介]: "Company Profile",
  [localeName.app.home.关于我们.ES于2010年]:
    "Established in 2010, ES has gradually expanded its business to Southeast China, Japan, Germany, Switzerland, Spain, the Czech Republic and other developed countries. Es' team has a very rich technical background and has very strict quality control requirements for our molds and products. In Singapore, China, and Germany, we have our own technical, sales, and project management teams, which can provide one-stop production solutions for customer needs in different Asian and European markets. We look forward to working with you.",
  [localeName.app.home.关于我们.合作伙伴AWM]: "Business Partner _AWM",
  [localeName.app.home.关于我们.AWM是一家]:
    "AWM is a local German  mold manufacturing and automation company with a cultural history of 50 years. Taking advantage of its unique geographical advantages, the sales and technical services can cover the entire European region. AWM and ES have joined together to guarantee ES a solid foundation for business expansion in Europe.",
  [localeName.app.home.关于我们.发展历史]: "Development History",
  [localeName.app.home.关于我们.生产零件批准过程]: "Project Process",
  [localeName.app.home.关于我们.阶段一]: "Phase One",
  [localeName.app.home.关于我们.充分理解客户需求]:
    "Fully understand customer needs",
  [localeName.app.home.关于我们.明确项目成员职责]:
    "Clear project member responsibilities",
  [localeName.app.home.关于我们.制定设计失效]:
    "Develop design failure mode analysis document (DFEMA), production and assembly feasibility analysis document.",
  [localeName.app.home.关于我们.阶段二]: "Phase Two",
  [localeName.app.home.关于我们.制定详细的过程]:
    "Develop detailed process failure analysis document",
  [localeName.app.home.关于我们.控制流程图]: "Control flow chart, control plan",
  [localeName.app.home.关于我们.测量系统分析]:
    "Measurement System Analysis (MSA) and Stability Analysis (SPC)",
  [localeName.app.home.关于我们.阶段三]: "Phase Three",
  [localeName.app.home.关于我们.当所有客户需求]:
    "When all customer needs are translated into a practically controllable operating process.",

  [localeName.app.home.关于我们.ES会向客户提供样品阶段]:
    "ES will provide customers with sample stage PSW, and if approved by the customer, PPAP level 2 documents will be submitted by default (except for special customer requirements).",
  [localeName.app.home.关于我们.ES可为客户提供]:
    "ES can provide customers with one-stop fully automated production solutions.",
  // 产品
  [localeName.app.home.产品.车身调节系列及传动系列相关齿轮产品]:
    "Body adjustment series and transmission series related gear products.",
  [localeName.app.home.产品.车顶调节器]: "Roof ",
  [localeName.app.home.产品.座椅调节器]: "Seat adjuster",
  [localeName.app.home.产品.车尾调节器]: "Rear ",
  [localeName.app.home.产品.车灯调节器]: "Headlight ",
  [localeName.app.home.产品.电子刹车EPB]: " EPB",
  [localeName.app.home.产品.传动系统EPS]: "EPS",
  [localeName.app.home.产品.产品系列]: "Product Series",
  [localeName.app.home.产品.其他产品]: "other products",
  [localeName.app.home.产品.连接器类产品]: "Connector products",
  [localeName.app.home.产品.电动卷帘和门锁齿轮产品]:
    "Electric shutter and door lock gear products",
  [localeName.app.home.产品.电子医疗]: "E-health",
  [localeName.app.home.产品.结构件]: "Structural member",
  [localeName.app.home.产品.模具描述]: "Mold description",
  // 服务
  [localeName.app.home.服务.项目咨询]: "Project Consulting",
  [localeName.app.home.服务.产品开发]: "Product Development",
  [localeName.app.home.服务.工程设计]: "Engineering",
  [localeName.app.home.服务.精密模具]: "Precision Mould",
  [localeName.app.home.服务.注塑生产]: "Production",
  [localeName.app.home.服务.高精度检测]: "High-Precision Inspection",
  [localeName.app.home.服务.请您将图纸]:
    "Please include drawings (2d, 3d), product, technical name, product function description, etc.",
  [localeName.app.home.服务.发送至]: "send to",
  [localeName.app.home.服务.成本预估]:
    "ES: will provide product feasibility analysis, one round of DFM, project cycle summary, cost estimation.",
  [localeName.app.home.服务.为什么选择希荷]: "Why choose ES?",
  [localeName.app.home.服务.国际制造]:
    "International footprint (local for local) – international manufacturing, local delivery.",
  [localeName.app.home.服务.长期的专业知识]: "Long-standing expertise",
  [localeName.app.home.服务.自己的工具店]: "Own tool shop",
  [localeName.app.home.服务.内部材料数据库]: "In-house material database",
  [localeName.app.home.服务.各种产品开发经验]:
    "Various product develop experience",
  // 招聘
  [localeName.app.home.招聘.校园招聘]: "Campus Recruiting",
  [localeName.app.home.招聘.社会招聘]: "Social Recruitment",
  [localeName.app.home.招聘.网络申请]: "Apply Online",
  [localeName.app.home.招聘.参加宣讲会]: "Attend a Lecture",
  [localeName.app.home.招聘.笔试]: "Written Examination",
  [localeName.app.home.招聘.面试]: "Interview",
  [localeName.app.home.招聘.OFFER]: "OFFER",
  [localeName.app.home.招聘.入职]: "Onboarding",
  [localeName.app.home.招聘.关爱生活品质]: "Caring for quality of life",
  [localeName.app.home.招聘.关注动作服务]: "Follow action services",
  [localeName.app.home.招聘.重视员工体验]: "Value employee experience",
  [localeName.app.home.招聘.贴心定制福利]: "Customized benefits",
  [localeName.app.home.招聘.员工免费定期体检]:
    "Free regular medical check-ups for employees",
  [localeName.app.home.招聘.补充商业医疗险]:
    "Supplementary commercial medical insurance",
  [localeName.app.home.招聘.弹性工作时间]: "Flexible working hours",
  [localeName.app.home.招聘.优质工作餐保障]: "Quality Work Meal Guarantee",
  [localeName.app.home.招聘.宿舍安排保障]: "Guarantee of dormitory arrangement",
  [localeName.app.home.招聘.交通出行保障]: "Transportation guarantee",
  [localeName.app.home.招聘.丰富的部门团建活动]:
    "Rich department building activities",
  [localeName.app.home.招聘.趣味运动和家庭日]: "Fun sports and family day",
  [localeName.app.home.招聘.员工活动中心]: "Staff Activity Center",
  [localeName.app.home.招聘.各类俱乐部]: "Various clubs",
  [localeName.app.home.招聘.传统佳节礼物]: "Traditional holiday gift",
  [localeName.app.home.招聘.生日祝福新婚关爱]: "Birthday greetings",
  [localeName.app.home.招聘.入职纪念]: "Commemoration",
  [localeName.app.home.招聘.长期服务奖励]: "Long service reward",

  [localeName.app.home.招聘.校招流程]: "School recruitment process",
  [localeName.app.home.招聘.为什么选择希荷]: "Why choose ES",
  [localeName.app.home.招聘.岗位职责]: "Job Responsibilities",
  [localeName.app.home.招聘.要求]: "Claim",
  [localeName.app.home.招聘.薪资待遇]: "Salary",
  [localeName.app.home.招聘.薪酬]: "Pay",
  [localeName.app.home.招聘.具有行业竞争力的薪资]:
    "Competitive salary in the industry",
  [localeName.app.home.招聘.绩效导向的多样化激励]:
    "Performance-oriented diversified incentives",
  [localeName.app.home.招聘.市场匹配的年度涨薪机制]:
    "Market-matched annual salary increase mechanism",
  [localeName.app.home.招聘.校招岗位]: "School recruitment positions",
  [localeName.app.home.招聘.投递简历]: "Submit resume",
  [localeName.app.home.招聘.福利]: "welfare",
  // 新闻
  [localeName.app.home.新闻.新闻资讯]: "News",
  [localeName.app.home.新闻.资料下载]: "Download",
  // 联系
  [localeName.app.home.联系.联系我们]: "Contact Us",
  [localeName.app.home.联系.销售经理]: "Sales Manager",
  [localeName.app.home.联系.国际销售经理]: "International Sales Manager",
  [localeName.app.home.联系.总经理]: "General manager",
  [localeName.app.home.联系.公司]: "Company",
  [localeName.app.home.联系.姓名]: "Name",
  [localeName.app.home.联系.电话]: "Phone",
  [localeName.app.home.联系.邮箱]: "E-mail",
  [localeName.app.home.联系.国家]: "Country",
  [localeName.app.home.联系.主题]: "Theme",
  [localeName.app.home.联系.街道]: "Street",
  [localeName.app.home.联系.城镇]: "Town",
  [localeName.app.home.联系.地区]: "Area",
  [localeName.app.home.联系.邮编]: "Postcode",
  [localeName.app.home.联系.您有任何疑问]: "Do you have any questions?",
  [localeName.app.home.联系.对于您提出的关于汽车传动系统齿轮件]:
    "For your questions about gears, medical parts, electronic connectors, etc. of automotive transmission systems, we hope to ensure that you receive fast and professional answers. By using the form below, your question will be sent directly to the application engineering staff in charge of the relevant industry department. Our engineers will contact you shortly.",
  [localeName.app.home.联系.留言]: "Please enter your message",
  [localeName.app.home.联系.提交]: "Submit",
  [localeName.app.home.联系.销售支持]: "sales support",
  [localeName.app.home.联系.新加坡地区技术]: "Singapore Area Technology",
  [localeName.app.home.联系.中国地区技术]: "China Regional Technology",
  [localeName.app.home.联系.欧洲地区技术]: "European Regional Technology",
  [localeName.app.home.联系.模具询价]: "Mould inquiry",
  [localeName.app.home.联系.注塑产品询价]: "Inquiry of injection molding products",
  [localeName.app.home.联系.冲压件产品询价]: "冲Inquiry for stamping products压件产品询价",
  [localeName.app.home.联系.技术支持]: "technical support",
  [localeName.app.home.联系.塑料粒子采购]: "Plastic particle procurement",
  [localeName.app.home.联系.其他服务商]: "Other service providers",
  [localeName.app.home.联系.人事和招聘]: "Personnel and recruitment",
  [localeName.app.home.联系.中国]: "China",
  [localeName.app.home.联系.英国]: "England",
  [localeName.app.home.联系.美国]: "America",
  [localeName.app.home.联系.加拿大]: "Canada",
  [localeName.app.home.联系.俄罗斯]: "Russia",
  [localeName.app.home.联系.埃及]: "Egypt",
  [localeName.app.home.联系.希腊]: "Greece",
  [localeName.app.home.联系.法国]: "France",
  [localeName.app.home.联系.德国]: "Germany",
  [localeName.app.home.联系.日本]: "Japan",
  [localeName.app.home.联系.其它]: "other",
};
