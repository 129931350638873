import gSass from "@utils/sass";
import gImg from "@/utils/img";
import React, { Component } from "react";
import csn from "classnames";
import { AppState } from "@/redux/stores/app";
import { Dispatch } from "redux";
import { Action } from "@/typings";
import * as appAction from "@redux/actions/app";
import { connect } from "react-redux";
import locale, { Lang } from "@utils/locale";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { CurrNav } from "./Header";
import localeName from "@/utils/localeName";
const style = gSass.common.loading;

interface Props {}
interface State {
  isHidden: boolean;
  isZh: boolean;
  isEn: boolean;
  isDe: boolean;
}
const mapStateToProps = (state: AppState) => ({
  app: state.app,
  time: state.time.time,
  currNav: state.app.currNav,
});
const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    setLanguage: (lang: string) => {
      dispatch(appAction.setLanguage(lang));
    },
    setLoadingTime: (time: boolean) => {
      dispatch(appAction.setLoadingTime(time));
    },
    setCurrNav: (currNav: CurrNav) => {
      dispatch(appAction.setCurrNav(currNav));
    },
  };
};
// @ts-ignore
@injectIntl
// @ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
export default class Loading extends Component<
  Props &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> &
    InjectedIntlProps,
  State
> {
  static defaultProps: ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> &
    InjectedIntlProps;
  constructor(props: any) {
    super(props);
    this.state = this.getInitState();
  }
  getInitState = (): State => {
    return {
      isHidden: false,
      isZh: true,
      isDe: false,
      isEn: false,
    };
  };
  componentDidMount() {
    this.init();
  }
  init = () => {
    this.setState({
      isHidden: false,
    });
  };
  changeLang = (language: Lang) => {
    this.props.setLanguage(language);
    this.props.setCurrNav("home");
    this.props.setLoadingTime(true);
    this.setState({
      isHidden: true,
    });
  };
  changePath = () => {};
  render() {
    const { messages: m } = this.props.intl;
    let { isDe, isEn, isZh } = this.state;
    return (
      <div className={this.props.time ? style.hidden : style.center}>
        <div className={style.contentWrap}>
          <div className={csn(style.mainContent, "flex")}>
            <div className={csn(style.logoWrap, "flex")}>
              <img src={gImg.common.logoBigPurple} alt="" />
            </div>
            <div className={csn(style.btnList, "flex")}>
              <div className={csn(style.item, "flex")}>
                <div
                  className={
                    this.props.app.lang === "zh-cn" ? style.title : style.title
                  }
                >
                  {m[localeName.app.欢迎浏览我们的网页]}
                </div>
                <div
                  className={
                    this.props.app.lang === "zh-cn"
                      ? style.languageBtn
                      : style.languageBtn
                  }
                  onClick={() => {
                    if (isZh === false) {
                      isZh = true;
                      isEn = false;
                      isDe = false;
                    }
                    this.setState({
                      isDe: isDe,
                      isEn: isEn,
                      isZh: isZh,
                    });
                    this.changeLang(locale.lang.简体中文);
                  }}
                >
                  中文
                </div>
              </div>
              <div className={csn(style.item, "flex")}>
                <div
                  className={
                    this.props.app.lang === "en" ? style.title : style.title
                  }
                >
                  Welcome To Visit Our Website
                </div>
                <div
                  className={
                    this.props.app.lang === "en"
                      ? style.languageBtn
                      : style.languageBtn
                  }
                  onClick={() => {
                    if (isEn === false) {
                      isEn = true;
                      isDe = false;
                      isZh = false;
                      this.setState({
                        isDe: isDe,
                        isEn: isEn,
                        isZh: isZh,
                      });
                    }

                    this.changeLang(locale.lang.English);
                  }}
                >
                  English
                </div>
              </div>
              <div className={csn(style.item, "flex")}>
                <div
                  className={
                    this.props.app.lang === "de" ? style.title : style.title
                  }
                >
                  Herzlich willkommen auf unserer Website
                </div>
                <div
                  className={
                    this.props.app.lang === "de"
                      ? style.languageBtn
                      : style.languageBtn
                  }
                  onClick={() => {
                    if (isDe === false) {
                      isDe = true;
                      isEn = false;
                      isZh = false;
                      this.setState({
                        isDe: isDe,
                        isEn: isEn,
                        isZh: isZh,
                      });
                    }

                    this.changeLang(locale.lang.德语);
                  }}
                >
                  Deutsch
                </div>
              </div>
            </div>
          </div>
          <div className={csn(style.dotWrap)}>
            <div className={style.name}>
              {m[localeName.app.common.导航.德国]}
            </div>
            <div className={style.dotCircle}>
              <div className={style.dot}></div>
              <div className={style.dot1}></div>
              <div className={style.dot2}></div>
            </div>
          </div>
          <div className={csn(style.dotWrapTwo)}>
            <div className={style.name}>
              {m[localeName.app.common.导航.中国]}
            </div>
            <div className={style.dotCircle}>
              <div className={style.dot}></div>
              <div className={style.dot1}></div>
              <div className={style.dot2}></div>
            </div>
          </div>
          <div className={csn(style.dotWrapThree)}>
            <div className={style.name}>
              {m[localeName.app.common.导航.新加坡]}
            </div>
            <div className={style.dotCircle}>
              <div className={style.dot}></div>
              <div className={style.dot1}></div>
              <div className={style.dot2}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
