import * as appAction from "@redux/actions/app";
import locale from "@utils/locale";
import React, { Component } from "react";
import { addLocaleData, IntlProvider } from "react-intl";
import en from "react-intl/locale-data/en";
import zh from "react-intl/locale-data/zh";
import de from "react-intl/locale-data/de";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { Dispatch } from "redux";
import { AppState } from "./redux/stores/app";
import routes from "./routes/app";
import { Action } from "./typings";
import { buildRoute } from "./utils/fn";

addLocaleData([...zh, ...en, ...de]);
interface Props {}
interface State {}
const mapStateToProps = (state: AppState) => ({
  app: state.app
});
const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    setLanguage: (lang: string) => {
      dispatch(appAction.setLanguage(lang));
    }
  };
};
// @ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
export default class MyHeader extends Component<
  Props &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>,
  State
> {
  static defaultProps: ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>;
  constructor(props: any) {
    super(props);
    this.state = this.getInitState();
  }
  getInitState = (): State => {
    return {};
  };
  render() {
    const { lang } = this.props.app;
    return (
      <IntlProvider locale={lang} messages={locale.msg[lang]}>
        <Router>{buildRoute(routes)}</Router>
      </IntlProvider>
    );
  }
}

