import { request as BuffReq, LocalStorage as storage } from "jsbdk"
import { BASE_URL, SESSION_EXPIRE } from "../config/prod"
import qs from "qs"
export interface GetListParam {
  page?: number
  limit?: number
  type?: number
  filter?: Record<string, any>
}
export interface Picture {
  id: number
  title: string
  url: string
}
export interface Region {
  cid: string
  value: string
  label: string
  areaName: string
  level: number
  children: Region[]
}
const updateSessionTime = async () => {
  const adminIsLogin = storage.get("adminIsLogin")
  const userIsLogin = storage.get("userIsLogin")
  if (adminIsLogin === true) {
    storage.set("adminIsLogin", true, SESSION_EXPIRE)
  }
  if (userIsLogin === true) {
    storage.set("userIsLogin", true, SESSION_EXPIRE)
  }
  if (storage.has("uid") && (userIsLogin || adminIsLogin)) {
    storage.set("uid", storage.get("uid"), SESSION_EXPIRE)
  }
  if (storage.has("nick") && (userIsLogin || adminIsLogin)) {
    storage.set("nick", storage.get("nick"), SESSION_EXPIRE)
  }
}
export interface RequestParam {
  url: string
  body?: any
  query?: any
  data?: any
  method?: string
}
export interface GetListParam {
  page?: number
  limit?: number
  filter?: Record<string, any>
}
export async function request<T = any>({
  url,
  body = {},
  method = "POST",
}: RequestParam) {
  updateSessionTime()
  return BuffReq<T>(BASE_URL, url, body, method)
}
export async function bget<T = any>({ url, query = {} }: RequestParam) {
  updateSessionTime()
  return BuffReq<T>(BASE_URL, url + "?" + qs.stringify(query), null, "GET")
}
export async function bpost<T = any>({ url, data = {} }: RequestParam) {
  updateSessionTime()
  return BuffReq<T>(BASE_URL, url, data)
}
export async function getRegion() {
  if (storage.has("region")) {
    let res: { data: { region: Region[] } } = {
      data: { region: storage.get("region") },
    }
    return res
  }
  return bget<{ region: Region[] }>({ url: "/getRegion" })
}

export default {}
