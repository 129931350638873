import gSass from "@utils/sass";
import React, { Component, FormEvent, createRef } from "react";
import { AppState } from "@/redux/stores/app";
import { Dispatch } from "redux";
import gImg from "@/utils/img";
import { Action } from "@/typings";
import * as appAction from "@redux/actions/app";
import { connect } from "react-redux";
import csn from "classnames";
import { Form, Input, Button, message ,Select } from "antd";
import { FormComponentProps } from "antd/lib/form";
import localeName from "@/utils/localeName";
import CommonHeader from "@/components/CommonHeader";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { InitMeta, saveForm, getPageInitMeta } from "@api/news";
import { Helmet } from "react-helmet";
import Footer from "@components/Footer";
import Header from "@components/Header";
const style = gSass.index.contact.index;
const { Option } = Select;
const htmlEditLayout = {
  wrapperCol: {
    md: {
      span: 24,
      offset: 0,
    },
    xs: {
      span: 12,
      offset: 0,
    },
  },
};
interface ContactList {
  title: string;
  duty: string;
  email: string;
}

interface Props {}
interface State {
  contactList: ContactList[];
  pageInitMeta: InitMeta;
}
const mapStateToProps = (state: AppState) => ({
  app: state.app,
});
const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    setLanguage: (lang: string) => {
      dispatch(appAction.setLanguage(lang));
    },
  };
};
// @ts-ignore
@injectIntl
// @ts-ignore
@Form.create()
// @ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
export default class Index extends Component<
  Props &
    FormComponentProps &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> &
    InjectedIntlProps,
  State
> {
  static defaultProps: FormComponentProps;
  scrollDiv = createRef<HTMLDivElement>();
  constructor(props: any) {
    super(props);
    this.state = this.getInitState();
  }
  getInitState = (): State => {
    return {
      pageInitMeta: {
        title: "",
        subTitle: "",
        keywords: "",
        description: "",
      },
      contactList: [
        {
          title: "ENG CHAN KOON(CK)",
          duty: localeName.app.home.联系.新加坡地区技术,
          email: "ckeng@es-asiatech.com",
        },
        {
          title: "KATHERINE SK",
          duty: localeName.app.home.联系.欧洲地区技术,
          email: "sale@es-asiatech.com",
        },
        {
          title: "DEREK ENG DHIANG YEOW",
          duty: localeName.app.home.联系.中国地区技术,
          email: "derek.eng@es-asiatech.com",
        },
      ],
    };
  };
  componentDidMount() {
    if (this.scrollDiv.current) {
      this.scrollDiv.current.scrollIntoView();
    }
    this.init();
  }
  init = () => {
    this.listKeywords();
  };
  listKeywords = async () => {
    let { data: pageInitMeta } = await getPageInitMeta();
    this.setState({
      pageInitMeta,
    });
  };
  submitForm = (evt: FormEvent<any>) => {
    evt && evt.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      console.log(evt)
      if (err) {
        return;
      }
      let data = {
        company: fieldsValue.company,
        name: fieldsValue.name,
        phone: fieldsValue.phone,
        postcode: fieldsValue.postcode,
        email: fieldsValue.email,
        country: fieldsValue.country,
        remarks: fieldsValue.remarks,
        street: fieldsValue.street,
        town: fieldsValue.town,
        area: fieldsValue.area,
        theme: fieldsValue.theme,
      };
      saveForm(data)
        .then((_) => {
          this.props.form.resetFields();
          message.success("添加成功", 1);
        })
        .catch((err) => {
          message.error("添加失败,失败原因: " + err.msg, 3);
          console.log(err);
        });
    });
  };

  render() {
    const { messages: m } = this.props.intl;
    let { pageInitMeta } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className={style.main} ref={this.scrollDiv}>
        <Header activeTitle="home" />
        <Helmet>
          <title>{pageInitMeta.title}</title>
          <meta name="description" content={pageInitMeta.description} />
          <meta name="keywords" content={pageInitMeta.keywords} />
        </Helmet>
        <div className={csn(style.headerBanner, "flex")}>
          <CommonHeader
            banner={gImg.news.contactBg}
            mainTitle={m[localeName.app.common.导航.联系我们]}
            subTitleOne={m[localeName.app.common.导航.联系我们]}
            subTitleTwo={""}
          />
        </div>
        <div className={style.center}>
          <div className={style.contactDescription}>
            <div className={style.descTitle}>
              {m[localeName.app.home.联系.联系我们]}
            </div>
            <div className={style.descSubTitle}>
              {m[localeName.app.home.联系.您有任何疑问]}
            </div>
            <div className={style.description}>
              {m[localeName.app.home.联系.对于您提出的关于汽车传动系统齿轮件]}
            </div>
          </div>
          <Form className={style.form}>
            <div className={style.moduleItem}>
              <div className={style.formItem}>
                <div className={`${style.title} ${style.required}`}>
                  {m[localeName.app.home.联系.主题]}：
                </div>
                <Form.Item {...htmlEditLayout} className={style.message}>
                  {getFieldDecorator("theme", {
                    rules: [{}],
                  })( <Select  style={{ width: '100%' }} >
                  <Option value={m[localeName.app.home.联系.模具询价]}>{m[localeName.app.home.联系.模具询价]}</Option>
                  <Option value={m[localeName.app.home.联系.注塑产品询价]}>{m[localeName.app.home.联系.注塑产品询价]}</Option>
                  <Option value={m[localeName.app.home.联系.冲压件产品询价]}>{m[localeName.app.home.联系.冲压件产品询价]}</Option>
                  <Option value={m[localeName.app.home.联系.技术支持]}>{m[localeName.app.home.联系.技术支持]}</Option>
                  <Option value={m[localeName.app.home.联系.塑料粒子采购]}>{m[localeName.app.home.联系.塑料粒子采购]}</Option>
                  <Option value={m[localeName.app.home.联系.其他服务商]}>{m[localeName.app.home.联系.其他服务商]}</Option>
                  <Option value={m[localeName.app.home.联系.人事和招聘]}>{m[localeName.app.home.联系.人事和招聘]}</Option>
                  
                </Select>)}
                </Form.Item>
              </div>
              <div className={style.formItem}>
                <div className={`${style.title} ${style.required}`}>
                  {m[localeName.app.home.联系.留言]}：
                </div>
                <Form.Item {...htmlEditLayout} className={style.message}>
                  {getFieldDecorator("remarks", {
                    rules: [{}],
                  })(<Input.TextArea rows={4} maxLength={500} />)}
                </Form.Item>
              </div>
            </div>
            <div className={style.moduleItem}>
              <div className={style.formItem}>
                <div className={`${style.title} ${style.required}`}>
                  {m[localeName.app.home.联系.姓名]}:
                </div>
                <Form.Item {...htmlEditLayout} className={style.item}>
                  {getFieldDecorator("name", {
                   rules : [{}],
                  })(<Input></Input>)}
                </Form.Item>
              </div>
              <div className={style.formItem}>
                <div className={`${style.title} ${style.required}`}>
                  {m[localeName.app.home.联系.公司]}:
                </div>
                <Form.Item {...htmlEditLayout} className={style.item}>
                  {getFieldDecorator("company", {
                    rules: [{}],
                  })(<Input></Input>)}
                </Form.Item>
              </div>
              <div className={style.formItem}>
                <div className={style.title}>
                  {m[localeName.app.home.联系.街道]}：
                </div>
                <Form.Item {...htmlEditLayout} className={style.item}>
                  {getFieldDecorator("street", {
                    rules: [{}],
                  })(<Input></Input>)}
                </Form.Item>
              </div>
              <div className={style.formItem}>
                <div className={style.title}>
                  {m[localeName.app.home.联系.城镇]}：
                </div>
                <Form.Item {...htmlEditLayout} className={style.item}>
                  {getFieldDecorator("town", {
                    rules: [{}],
                  })(<Input></Input>)}
                </Form.Item>
              </div>
              <div className={style.formItem}>
                <div className={style.title}>
                  {m[localeName.app.home.联系.邮编]}：
                </div>
                <Form.Item {...htmlEditLayout} className={style.item}>
                  {getFieldDecorator("postcode", {
                    rules: [{}],
                  })(<Input type="digital"></Input>)}
                </Form.Item>
              </div>
              <div className={style.formItem}>
                <div className={`${style.title} ${style.required}`}>
                  {m[localeName.app.home.联系.国家]}：
                </div>
                <Form.Item {...htmlEditLayout} className={style.select}>
                  {getFieldDecorator("country", {
                    rules: [{}],
                  })( <Select  style={{ width: '100%' }} >
                     <Option value={m[localeName.app.home.联系.中国]}>{m[localeName.app.home.联系.中国]}</Option>
                    <Option value={m[localeName.app.home.联系.英国]}>{m[localeName.app.home.联系.英国]}</Option>
                    <Option value={m[localeName.app.home.联系.加拿大]}>{m[localeName.app.home.联系.加拿大]}</Option>
                    <Option value={m[localeName.app.home.联系.俄罗斯]}>{m[localeName.app.home.联系.俄罗斯]}</Option>
                    <Option value={m[localeName.app.home.联系.埃及]}>{m[localeName.app.home.联系.埃及]}</Option>
                    <Option value={m[localeName.app.home.联系.希腊]}>{m[localeName.app.home.联系.希腊]}</Option>
                    <Option value={m[localeName.app.home.联系.法国]}>{m[localeName.app.home.联系.法国]}</Option>
                    <Option value={m[localeName.app.home.联系.德国]}>{m[localeName.app.home.联系.德国]}</Option>
                    <Option value={m[localeName.app.home.联系.日本]}>{m[localeName.app.home.联系.日本]}</Option>
                    <Option value={m[localeName.app.home.联系.其它]}>{m[localeName.app.home.联系.其它]}</Option>
                  </Select>)}
                </Form.Item>
              </div>
              <div className={style.formItem}>
                <div className={style.title}>
                  {m[localeName.app.home.联系.地区]}：
                </div>
                <Form.Item {...htmlEditLayout} className={style.item}>
                  {getFieldDecorator("area", {
                    rules: [{}],
                  })(<Input></Input>)}
                </Form.Item>
              </div>
              <div className={style.formItem}>
                <div className={`${style.title} ${style.required}`}>
                  {m[localeName.app.home.联系.电话]}：
                </div>
                <Form.Item {...htmlEditLayout} className={style.item}>
                  {getFieldDecorator("phone", {
                    rules: [{}],
                  })(<Input type="digital"></Input>)}
                </Form.Item>
              </div>
              <div className={style.formItem}>
                <div className={`${style.title} ${style.required}`}>
                  {m[localeName.app.home.联系.邮箱]}：
                </div>
                <Form.Item {...htmlEditLayout} className={style.item}>
                  {getFieldDecorator("email", {
                    rules: [{}],
                  })(<Input></Input>)}
                </Form.Item>
              </div>
            </div>
            <div className={csn(style.buttonWrap, "flex ")}>
              <Button className={style.submit} onClick={this.submitForm}>
                {m[localeName.app.home.联系.提交]}
              </Button>
            </div>
          </Form>
        </div>
        <Footer />
      </div>
    );
  }
}
