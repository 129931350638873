import { BuffRoute } from "@/routes/app";
import { buildRoute } from "@/utils/fn";
// import Footer from "@components/Footer";
// import Header from "@components/Header";
import gSass from "@utils/sass";
// import { Layout } from "antd";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { AppState } from "@/redux/stores/app";
import { Dispatch } from "redux";
import { Action } from "@/typings";
import * as appAction from "@/redux/actions/app";
import { connect } from "react-redux";
const style = gSass.index.home;
interface Props {}
interface State {}
const mapStateToProps = (state: AppState) => ({
  app: state.app
});
const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    setLanguage: (lang: string) => {
      dispatch(appAction.setLanguage(lang));
    }
  };
};
// @ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
export default class Basic extends Component<
  Props & { routes: BuffRoute[] } & RouteComponentProps &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>,
  State
> {
  static defaultProps: { routes: BuffRoute[] } & RouteComponentProps &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>;
  constructor(props: any) {
    super(props);
    this.state = this.getInitState();
  }
  getInitState = (): State => {
    return {};
  };
  render() {
    return (
      <div className={style.main}>
        {/* <Layout> */}
        {/* <Header activeTitle="home" /> */}
        {buildRoute(this.props.routes)}
        {/* <Footer /> */}
        {/* </Layout> */}
      </div>
    );
  }
}
