import localeName from "@utils/localeName";
export default {
  [localeName.app.公司名]: "Xihe",
  [localeName.app.集团名]: "Xihe",
  [localeName.app.欢迎浏览我们的网页]:
    "Herzlich willkommen auf unserer Website",
  [localeName.app.common.导航.我们的产品]: "Produkte",
  [localeName.app.common.导航.我们的服务]: "Leistungen",
  [localeName.app.common.导航.首页]: "Zuhause",
  [localeName.app.common.导航.关于我们]: "Überuns",
  [localeName.app.common.导航.招聘]: "Rekrutierung",
  [localeName.app.common.导航.新闻下载]: "News",
  [localeName.app.common.导航.联系我们]: "Kontakt",
  [localeName.app.加载更多]: "Laden Sie mehr",
  [localeName.app.common.导航.了解详情]: "Erfahren Sie mehr",
  [localeName.app.common.导航.查看更多]: "Mehr anzeigen",
  [localeName.app.common.导航.欢迎浏览]: "Willkommen auf der Xihe Webseite!",
  // 地区:
  [localeName.app.common.导航.中国]: "China",
  [localeName.app.common.导航.日本]: "Schweiz",
  [localeName.app.common.导航.上海]: "Shanghai",
  [localeName.app.common.导航.新加坡]: "Singapur",
  [localeName.app.common.导航.德国]: "Deutschland",
  [localeName.app.common.导航.波兰]: "Polen",
  [localeName.app.common.导航.瑞士]: "Schweiz",
  [localeName.app.common.导航.法国]: "Frankreich",
  [localeName.app.common.导航.西班牙]: "Spanien",
  [localeName.app.common.导航.捷克]: "Tschechische Republik",

  // 首页
  [localeName.app.home.首页.主标题一]: "Vertrauenswürdig um dich herum",
  [localeName.app.home.首页.主标题二]: "Präzisions-Spritzguss-Partner",
  [localeName.app.home.首页.关于希荷]: "Überuns ES",
  [localeName.app.home.首页.ES起步]:
    "ES wurde 2010 in Singapur gegründet, hat seinen Ursprung in Shanghai, China, wo industrielle Agglomeration und Transport entwickelt werden, und startete in Düsseldorf, Deutschland, das für seine schlanke Produktion bekannt ist.",
  [localeName.app.home.首页.始终坚持匠心]:
    "Halten Sie stets an Einfallsreichtum, Innovation und Transzendenz fest und arbeiten Sie mit dem seit 50 Jahren bestehenden deutschen Spritzgussunternehmen AWM zusammen, das sich für die Verbesserung der hochpräzisen und hochwertigen Produkte für die Automobil-, Elektronik-, Medizin- und andere Teile und Komponentenbranche für globale Kunden einsetzt. Vertrauenswürdige Präzisions-Spritzguss-Partner um uns herum 'machen weiter Fortschritte!'",
  [localeName.app.home.首页.ES致力于]:
    "ES ist bestrebt, Kunden weltweit hochpräzise Formen ",
  [localeName.app.home.首页.ES致力于分段]:
    "und Spritzgussteile zur Verfügung zu stellen",

  [localeName.app.home.首页.我们的原则]:
    "Unser Grundsatz 'Kunde zuerst, Kommunikation zuerst'",
  [localeName.app.home.首页.严谨高效]:
    "Rigoroses und effizientes Projektmanagement",
  [localeName.app.home.首页.项目咨询]: "Projektberatung",
  [localeName.app.home.首页.客户输入]:
    "Kundeneingang: einschließlich Zeichnungen (2d, 3d), Produkte, technische Beschreibungen, Produktfunktionsbeschreibungen usw. ES-Ausgabe: Die Produktdurchführbarkeitsanalyse wird innerhalb von 3-5 Arbeitstagen, einer DFM-Runde, einer Projektzykluszusammenfassung und einer Kostenschätzung vorgeschlagen.",
  [localeName.app.home.首页.技术方案]: "Technische Lösung",
  [localeName.app.home.首页.产品分析]:
    "Nutzen Sie Moldflow & Kisssoft, um das Produkt umfassend zu analysieren und DFM, Werkzeugdesign, Rohstoffauswahl, Prototyp, Massenproduktion von Werkzeugen und eine einmalige Lösung für die Massenproduktion von Produkten bereitzustellen.",

  [localeName.app.home.首页.合同签订]: "Vertragsunterzeichnung",
  [localeName.app.home.首页.合同签订客户]:
    "Kunden können Verträge mit jeder Tochtergesellschaft von es abschließen, um ihre Kerninteressen sicherzustellen.",
  [localeName.app.home.首页.交货完成]: "Lieferung abgeschlossen",
  [localeName.app.home.首页.输送到终端客户]:
    "ES verpackt und versendet unter der Voraussetzung, dass die Qualität des Werkzeugs und des Produkts sichergestellt wird, und wählt die Transportlösung aus, die die kürzeste Zeit, die effizienteste und die geringsten Kosten verursacht. Es ist eine deutsche Tochtergesellschaft, die Produkte für europäische Kunden lagern und dann an Endkunden liefern kann, um Kosten zu sparen",

  [localeName.app.home.首页.分布简介]:
    "ES ist bestrebt, seinen Kunden auf der ganzen Welt hochpräzise und qualitativ hochwertige Kfz-Teile, Elektronik sowie medizinische Teile und Komponenten und Formen bereitzustellen. Derzeit hat sich das Geschäft des Unternehmens von Singapur nach Südostchina, Japan, Deutschland, in die Schweiz, nach Spanien, nach Tschechien und in andere Industrieländer ausgeweitet . ES ist bestrebt, ein 'vertrauenswürdiger Präzisions-Spritzguss-Partner in Ihrer Nähe' zu sein!",
  [localeName.app.home.首页.最新发布]: "Neueste Version",
  [localeName.app.home.首页.坚信最初]:
    "Glaube fest an den ursprünglichen Traum, arbeite hart und erschaffe ständig neue Wunder.",
  [localeName.app.home.首页.细小做到精致]:
    "ES schreitet nicht nur rasant voran, sondern ist auch bestrebt, kleine Dinge exquisit zu machen!",
  [localeName.app.home.首页.我们的目标]: "Unser Ziel",
  [localeName.app.home.首页.身边的]:
    "Ihr zuverlässiger Partner für Präzisionsspritzguss",

  // 关于我们
  [localeName.app.home.关于我们.公司简介]: "Firmenprofil",
  [localeName.app.home.关于我们.ES于2010年]:
    "ES wurde 2010 gegründet und hat sein Geschäft nach und nach auf Südostchina, Japan, Deutschland, die Schweiz, Spanien, die Tschechische Republik und andere Industrieländer ausgedehnt. Das Team von Es verfügt über einen sehr reichen technischen Hintergrund und stellt sehr strenge Anforderungen an die Qualitätskontrolle unserer Formen und Produkte. In Singapur, China und Deutschland verfügen wir über eigene Teams für Technik, Vertrieb und Projektmanagement, die für Kundenanforderungen in verschiedenen asiatischen und europäischen Märkten Produktionslösungen aus einer Hand anbieten können. Wir freuen uns auf die Zusammenarbeit mit Ihnen",
  [localeName.app.home.关于我们.合作伙伴AWM]: "Partner AWM",
  [localeName.app.home.关于我们.AWM是一家]:
    "AWM ist ein deutsches Unternehmen für Formenbau und Automatisierung mit einer 50-jährigen Kulturgeschichte, das aufgrund seiner einzigartigen geografischen Vorteile mit Vertrieb und technischem Service die gesamte europäische Region abdecken kann Die Erweiterung schafft eine solide Grundlage und beseitigt Sorgen",
  [localeName.app.home.关于我们.发展历史]: "Entwicklungsgeschichte",
  [localeName.app.home.关于我们.生产零件批准过程]:
    "Genehmigungsprozess für Produktionsteile",
  [localeName.app.home.关于我们.阶段一]: "Phase eins",

  [localeName.app.home.关于我们.充分理解客户需求]:
    "Kundenbedürfnisse vollständig verstehen",
  [localeName.app.home.关于我们.明确项目成员职责]:
    "Klare Verantwortlichkeiten der Projektmitglieder",
  [localeName.app.home.关于我们.制定设计失效]:
    "Entwickeln eines Dokuments zur Analyse des Entwurfsfehlermodus (DFEMA), eines Dokuments zur Analyse der Herstellungs- und Montagefähigkeit",
  [localeName.app.home.关于我们.阶段二]: "Phase zwei",
  [localeName.app.home.关于我们.制定详细的过程]:
    "Entwickeln Sie detaillierte Dokumente zur Prozessfehleranalyse",
  [localeName.app.home.关于我们.控制流程图]:
    " Kontrollflussdiagramm, Kontrollplan",
  [localeName.app.home.关于我们.测量系统分析]:
    "Messsystemanalyse (MSA) und Stabilitätsanalyse (SPC)",
  [localeName.app.home.关于我们.阶段三]: "Stufe drei",
  [localeName.app.home.关于我们.当所有客户需求]:
    "Wenn alle Kundenbedürfnisse in einen praktisch kontrollierbaren Betriebsprozess umgesetzt werden",
  [localeName.app.home.关于我们.ES会向客户提供样品阶段]:
    "ES stellt dem Kunden ein PSW-Muster zur Verfügung. Wenn dies vom Kunden genehmigt wurde, werden standardmäßig PPAP-Dokumente der Stufe 2 eingereicht (mit Ausnahme der speziellen Kundenanforderungen).",
  [localeName.app.home.关于我们.ES可为客户提供]:
    "ES bietet Kunden vollautomatisierte Produktionslösungen aus einer Hand",
  // 产品
  // 产品
  [localeName.app.home.产品.车身调节系列及传动系列相关齿轮产品]:
    "Zahnradprodukte für Karosserieverstellung und Getriebeserie",
  [localeName.app.home.产品.车顶调节器]: "Dachregler",
  [localeName.app.home.产品.座椅调节器]: "Sitz",
  [localeName.app.home.产品.车尾调节器]: "Hinten",
  [localeName.app.home.产品.车灯调节器]: "Scheinwerfer",
  [localeName.app.home.产品.电子刹车EPB]: "EPB",
  [localeName.app.home.产品.传动系统EPS]: "EPS",
  [localeName.app.home.产品.产品系列]: "Produktserie",
  [localeName.app.home.产品.其他产品]: "Andere Produkte",
  [localeName.app.home.产品.连接器类产品]: "Steckverbinderprodukte",
  [localeName.app.home.产品.电动卷帘和门锁齿轮产品]:
    "Produkte für elektrische Rollläden und Türschlösser",
  [localeName.app.home.产品.电子医疗]: "E-Health",
  [localeName.app.home.产品.结构件]: "Strukturelle Mitglieder",
  [localeName.app.home.产品.模具描述]: "Beschreibung der Form",
  // 服务
  [localeName.app.home.服务.项目咨询]: "Projektberatung",
  [localeName.app.home.服务.产品开发]: "Produktentwicklung",
  [localeName.app.home.服务.工程设计]: "Engineering-Design",
  [localeName.app.home.服务.精密模具]: "Präzisionsform",
  [localeName.app.home.服务.注塑生产]: "Spritzgussproduktion",
  [localeName.app.home.服务.高精度检测]: "Hochpräzise Inspektion",
  [localeName.app.home.服务.请您将图纸]:
    " Bitte Zeichnungen (2d, 3d), Produkt, technischen Namen, Produktfunktionsbeschreibung usw. beifügen.",
  [localeName.app.home.服务.发送至]: "Senden an",
  [localeName.app.home.服务.成本预估]:
    "ES: Bereitstellung einer Produktdurchführbarkeitsanalyse, einer DFM-Runde, einer Zusammenfassung des Projektzyklus und einer Kostenschätzung",

  [localeName.app.home.服务.为什么选择希荷]: "Warum ES wählen?",
  [localeName.app.home.服务.国际制造]:
    "Internationale Präsenz (lokal für lokal) - internationale Fertigung, lokale Lieferung",
  [localeName.app.home.服务.长期的专业知识]: "Langjährige Expertise",
  [localeName.app.home.服务.自己的工具店]: "Eigener Werkzeugladen",
  [localeName.app.home.服务.内部材料数据库]: "Interne Materialdatenbank",
  [localeName.app.home.服务.各种产品开发经验]:
    "Verschiedene Produktentwicklungserfahrungen",
  // 招聘
  [localeName.app.home.招聘.校园招聘]: " Campus Rekrutierung ",
  [localeName.app.home.招聘.社会招聘]: "Soziale Rekrutierung",
  [localeName.app.home.招聘.网络申请]: "Online bewerben",
  [localeName.app.home.招聘.参加宣讲会]: "Nimm an einer Vorlesung teil",
  [localeName.app.home.招聘.笔试]: "Schriftliche Prüfung",
  [localeName.app.home.招聘.面试]: "Interview",
  [localeName.app.home.招聘.OFFER]: "OFFER",
  [localeName.app.home.招聘.入职]: "Onboarding",
  [localeName.app.home.招聘.关爱生活品质]: "Lebensqualität pflegen",
  [localeName.app.home.招聘.关注动作服务]: "Folgen Sie den Aktionsdiensten",
  [localeName.app.home.招聘.重视员工体验]: "Wertvolle Mitarbeitererfahrung",
  [localeName.app.home.招聘.贴心定制福利]: "Kundenspezifische Vorteile",
  [localeName.app.home.招聘.员工免费定期体检]:
    "Kostenlose regelmäßige ärztliche Untersuchungen für Mitarbeiter",
  [localeName.app.home.招聘.补充商业医疗险]:
    "Gewerbliche Zusatzkrankenversicherung",
  [localeName.app.home.招聘.弹性工作时间]: "Flexible Arbeitszeiten",
  [localeName.app.home.招聘.优质工作餐保障]:
    "Qualitätsgarantie für Arbeitsmahlzeiten",
  [localeName.app.home.招聘.宿舍安排保障]: "Garantie der Wohnheimanordnung",
  [localeName.app.home.招聘.交通出行保障]: "Transportgarantie",
  [localeName.app.home.招聘.丰富的部门团建活动]:
    "Reichhaltige Aktivitäten zum Aufbau von Abteilungen",
  [localeName.app.home.招聘.趣味运动和家庭日]: "Funsport und Familientag",
  [localeName.app.home.招聘.员工活动中心]: "Mitarbeiter-Aktivitätscenter",
  [localeName.app.home.招聘.各类俱乐部]: "Verschiedene Vereine",
  [localeName.app.home.招聘.传统佳节礼物]: "Traditionelles Weihnachtsgeschenk",
  [localeName.app.home.招聘.生日祝福新婚关爱]: "Geburtstagsgrüße",
  [localeName.app.home.招聘.入职纪念]: "Gedenken",
  [localeName.app.home.招聘.长期服务奖励]: "Long service reward",

  [localeName.app.home.招聘.校招流程]: "Rekrutierungsprozess für die Schule",
  [localeName.app.home.招聘.为什么选择希荷]: "Warum ES wählen",
  [localeName.app.home.招聘.岗位职责]: "Jobverantwortung",
  [localeName.app.home.招聘.要求]: "Erforderlich",
  [localeName.app.home.招聘.薪资待遇]: "Gehalt",
  [localeName.app.home.招聘.薪酬]: "Pay",
  [localeName.app.home.招聘.具有行业竞争力的薪资]:
    "Wettbewerbsfähiges Gehalt in der Branche",
  [localeName.app.home.招聘.绩效导向的多样化激励]:
    "Leistungsorientierte, diversifizierte Anreize",
  [localeName.app.home.招聘.市场匹配的年度涨薪机制]:
    "Marktangepasster Mechanismus zur jährlichen Lohnerhöhung",
  [localeName.app.home.招聘.校招岗位]: "Einstellungspositionen in der Schule",
  [localeName.app.home.招聘.投递简历]: "Lebenslauf einreichen",
  [localeName.app.home.招聘.福利]: "Wohlfahrt",
  // 新闻
  [localeName.app.home.新闻.新闻资讯]: "Nachrichten",
  [localeName.app.home.新闻.资料下载]: "Daten herunterladen",
  // 联系
  [localeName.app.home.联系.联系我们]: "Sprechen Sie uns an",
  [localeName.app.home.联系.销售经理]: "Verkaufsleiter",
  [localeName.app.home.联系.国际销售经理]: "Internationaler Verkaufsleiter",
  [localeName.app.home.联系.总经理]: "General Manager",
  [localeName.app.home.联系.公司]: "Gesellschaft",
  [localeName.app.home.联系.姓名]: "Name",
  [localeName.app.home.联系.电话]: "Telefon",
  [localeName.app.home.联系.邮箱]: "Mailbox",
  [localeName.app.home.联系.国家]: "Land",
  [localeName.app.home.联系.主题]: "Themen",
  [localeName.app.home.联系.街道]: "Straße",
  [localeName.app.home.联系.城镇]: "Stadt",
  [localeName.app.home.联系.邮编]: "Postleitzahl",
  [localeName.app.home.联系.地区]: "Region",
  [localeName.app.home.联系.留言]: "Bitte geben Sie Ihre Nachricht ein",
  [localeName.app.home.联系.提交]: "Senden",
  [localeName.app.home.联系.您有任何疑问]: "Haben Sie Fragen?",
  [localeName.app.home.联系.对于您提出的关于汽车传动系统齿轮件]:
    "Bei Ihren Fragen zu Zahnrädern, medizinischen Teilen, elektronischen Steckverbindern usw. von Fahrzeuggetriebesystemen hoffen wir, dass Sie schnelle und professionelle Antworten erhalten. Über das unten stehende Formular wird Ihre Frage direkt an die Mitarbeiter der Anwendungstechnik gesendet, die für die jeweilige Branchenabteilung zuständig sind. Unsere Ingenieure werden Sie in Kürze kontaktieren.",
  [localeName.app.home.联系.销售支持]: "Verkaufsunterstützung",
  [localeName.app.home.联系.新加坡地区技术]: "Singapur-Bereich Technologie",
  [localeName.app.home.联系.中国地区技术]: "China Regionale Technologie",
  [localeName.app.home.联系.欧洲地区技术]: "Europäische Regionale Technologie",
  [localeName.app.home.联系.模具询价]: "Formenuntersuchung",
  [localeName.app.home.联系.注塑产品询价]: "Anfrage von Spritzgussprodukten",
  [localeName.app.home.联系.冲压件产品询价]: "Anfrage für Prägeprodukte",
  [localeName.app.home.联系.技术支持]: "technische Unterstützung",
  [localeName.app.home.联系.塑料粒子采购]: "Beschaffung von Kunststoffpartikeln",
  [localeName.app.home.联系.其他服务商]: "Andere Dienstleister",
  [localeName.app.home.联系.人事和招聘]: "Personal und Einstellung",
  [localeName.app.home.联系.中国]: "China",
  [localeName.app.home.联系.英国]: "Britain",
  [localeName.app.home.联系.美国]: "USA",
  [localeName.app.home.联系.加拿大]: "Kanada",
  [localeName.app.home.联系.俄罗斯]: "Russland",
  [localeName.app.home.联系.埃及]: "Ägypten",
  [localeName.app.home.联系.希腊]: "Griechenland",
  [localeName.app.home.联系.法国]: "Frankreich",
  [localeName.app.home.联系.德国]: "Deutschland",
  [localeName.app.home.联系.日本]: "Japan",
  [localeName.app.home.联系.其它]: "andere",
};
