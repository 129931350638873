// 设置触发的动作
import { CurrNav } from "@components/Header";
export const SET_LANGUAGE = "设置语言";
export const SET_CURR_NAV = "设置当前的导航";
export const SET_LOADSING_TIME = "设置欢迎页的时间";
export function setLanguage(preload: string) {
  return {
    type: SET_LANGUAGE,
    preload
  };
}
export function setCurrNav(preload: CurrNav) {
  return {
    type: SET_CURR_NAV,
    preload
  };
}
export function setLoadingTime(preload: boolean) {
  return {
    type: SET_LOADSING_TIME,
    preload
  };
}
