export default {
  common: {
    aLogo: require("@img/common/aLogo.png"),
    aLogoBlue: require("@img/common/aLogoBlue.png"),
    logo: require("@img/common/logo.png"),
    logoMap: require("@img/common/logoMap.png"),
    mapStart: require("@img/common/mapStart.jpg"),
    mapStart1: require("@img/common/mapStart1.png"),
    logoBlue: require("@img/common/logoBlue.png"),
    logoBigBlue: require("@img/common/logoBigBlue.png"),
    logoBig: require("@img/common/logoBig.png"),
    logoBigPurple: require("@img/common/logoBigPurple.png"),
    left: require("@img/common/left.png"),
    right: require("@img/common/right.png"),
    zh: require("@img/common/zh.png"),
    en: require("@img/common/en.png"),
    de: require("@img/common/de.png"),
    code: require("@img/common/code.jpg")
  },
  home: {
    show1: require("@img/home/show1.jpg"),
    show2: require("@img/home/show2.jpg"),
    show3: require("@img/home/show3.jpg"),
    show4: require("@img/home/show4.jpg"),
    about: require("@img/home/about.jpg"),
    one: require("@img/home/one.png"),
    two: require("@img/home/two.png"),
    three: require("@img/home/three.png"),
    four: require("@img/home/four.png"),
    productOne: require("@img/home/productOne.png"),
    productTwo: require("@img/home/productTwo.png"),
    gear: require("@img/home/gear.jpg"),
    map: require("@img/home/map.png"),
    logo: require("@img/home/logo.png"),
    mobile: require("@img/home/mobile.png"),
    handshake: require("@img/home/handshake.jpg"),
    medical: {
      medical1: require("@img/home/medical/medical1.jpg"),
      medical2: require("@img/home/medical/medical2.jpg"),
      medical3: require("@img/home/medical/medical3.jpg"),
      medical4: require("@img/home/medical/medical4.jpg"),
      other1: require("@img/home/medical/other1.jpg"),
      other2: require("@img/home/medical/other2.jpg"),
      other3: require("@img/home/medical/other3.jpg")
    },
    structure: {
      four: require("@img/home/structure/four.jpg"),
      five: require("@img/home/structure/five.jpg"),
      six: require("@img/home/structure/six.jpg"),
      seven: require("@img/home/structure/seven.jpg")
    },
    gears: {
      one: require("@img/home/gears/one.jpg"),
      two: require("@img/home/gears/two.jpg"),
      three: require("@img/home/gears/three.jpg"),
      four: require("@img/home/gears/four.jpg"),
      five: require("@img/home/gears/five.jpg")
    },
    precise: {
      one: require("@img/home/precise/one.jpeg"),
      two: require("@img/home/precise/two.jpeg"),
      three: require("@img/home/precise/three.jpeg"),
      four: require("@img/home/precise/four.jpeg"),
      five: require("@img/home/precise/five.jpeg"),
      six: require("@img/home/precise/six.jpeg"),
      seven: require("@img/home/precise/seven.jpeg"),
      eight: require("@img/home/precise/eight.jpeg"),
      nine: require("@img/home/precise/nine.jpeg"),
      ten: require("@img/home/precise/ten.jpeg")
    }
  },
  about: {
    bgThree: require("@img/about/bgThree.jpg"),
    aboutOne: require("@img/about/aboutOne.jpg"),
    aboutTitle: require("@img/about/aboutTitle.jpg"),
    factory: require("@img/about/factory.png"),
    bgFour: require("@img/about/bgFour.jpg"),
    lou: require("@img/about/lou.jpg"),
    aboutBanner: require("@img/about/aboutBanner.jpg"),
    one: require("@img/about/one.jpg"),
    left: require("@img/about/left.png"),
    right: require("@img/about/right.png"),
    two: require("@img/about/two.jpg"),
    three: require("@img/about/three.jpg"),
    num1: require("@img/about/1.png"),
    num2: require("@img/about/2.png"),
    people: require("@img/about/people.png"),
    num3: require("@img/about/3.png")
  },
  news: {
    newsOne: require("@img/news/newsOne.jpg"),
    newsTwo: require("@img/news/newsTwo.jpg"),
    newsThree: require("@img/news/newsThree.jpg"),
    newsFour: require("@img/news/newsFour.jpg"),
    newsFive: require("@img/news/newsFive.jpg"),
    files: require("@img/news/files.png"),
    download: require("@img/news/download.png"),
    filesBlue: require("@img/news/filesBlue.png"),
    downloadBlue: require("@img/news/downloadBlue.png"),
    left: require("@img/news/left.png"),
    right: require("@img/news/right.png"),
    newsBg: require("@img/news/newsBg.jpg"),
    newsDetail: require("@img/news/newsDetail.jpg"),
    contactBg: require("@img/news/contactBg.jpg"),
    newBg: require("@img/news/newBg.jpg")
  },
  product: {
    productFour: require("@img/product/productfour.png"),
    productOne: require("@img/product/productone.png"),
    productThree: require("@img/product/productthree.png"),
    productTwo: require("@img/product/producttwo.png"),
    productBanner: require("@img/product/productBanner.jpg"),
    car: require("@img/product/car.jpg"),
    moreOne: require("@img/product/moreOne.jpg"),
    moreTwo: require("@img/product/moreTwo.jpg"),
    more1: require("@img/product/more1.png"),
    more2: require("@img/product/more2.jpg"),
    more3: require("@img/product/more3.jpg"),
    one: require("@img/product/one.png"),
    two: require("@img/product/two.png"),
    three: require("@img/product/three.png"),
    four: require("@img/product/four.png"),
    line1: require("@img/product/1.png"),
    line2: require("@img/product/2.png"),
    line3: require("@img/product/3.png"),
    line4: require("@img/product/4.png"),
    line5: require("@img/product/5.png"),
    line6: require("@img/product/6.png"),
    light1: require("@img/product/light1.jpg"),
    light2: require("@img/product/light2.jpg"),
    light3: require("@img/product/light3.jpg"),
    light4: require("@img/product/light4.jpg"),
    light5: require("@img/product/light5.jpg"),
    EPB2: require("@img/product/EPB2.png"),
    EPB3: require("@img/product/EPB3.png"),
    EPB4: require("@img/product/EPB4.png"),
    EPB1: require("@img/product/EPB1.jpg"),
    EPB5: require("@img/product/EPB5.jpg"),
    EPS1: require("@img/product/EPS1.jpg")
  },
  recruit: {
    school: require("@img/recruit/school.jpg"),
    society: require("@img/recruit/society.jpg"),
    one: require("@img/recruit/one.jpg"),
    two: require("@img/recruit/two.jpg"),
    three: require("@img/recruit/three.jpg"),
    four: require("@img/recruit/four.jpg"),
    apply: require("@img/recruit/apply.png"),
    offer: require("@img/recruit/offer.png"),
    pen: require("@img/recruit/pen.png"),
    meeting: require("@img/recruit/meeting.png"),
    entry: require("@img/recruit/entry.png"),
    interview: require("@img/recruit/interview.png"),
    map: require("@img/recruit/map.png"),
    wageBg: require("@img/recruit/wageBg.jpg")
  },

  service: {
    one: require("@img/service/one.jpg"),
    serviceBg: require("@img/service/serviceBg.jpg"),
    louBg: require("@img/service/louBg.jpg"),
    two: require("@img/service/two.png"),
    mould1: require("@img/service/mould1.jpg"),
    mould2: require("@img/service/mould2.jpg"),
    mould3: require("@img/service/mould3.jpg"),
    mould4: require("@img/service/mould4.jpg"),
    mould5: require("@img/service/mould5.jpg"),
    mould6: require("@img/service/mould6.jpg"),
    mould7: require("@img/service/mould7.jpg"),
    mould8: require("@img/service/mould8.jpg"),
    mould9: require("@img/service/mould9.jpg"),
    mould10: require("@img/service/mould10.jpg"),
    precise1: require("@img/service/precise1.png"),
    precise2: require("@img/service/precise2.jpg"),
    precise3: require("@img/service/precise3.png"),
    precise4: require("@img/service/precise4.png"),
    product1: require("@img/service/product1.jpg"),
    contact: require("@img/service/contact.jpg")
  }
};
