import { GetListParam, Picture, bget, bpost } from "./api";

// 关键字标题等
export interface InitMeta {
  title?: string;
  subTitle: string;
  keywords: string;
  description: string;
}
export interface DataList {
  id: number;
  name: string;
  link?: string;
}
export interface News {
  id: number;
  title: string;
  detail: string;
  ctime: string;
  pic: Picture;
  link: string;
}
export interface NewsDetail {
  id: number;
  name: string;
  content: string;
  source: string;
  author: string;
  ctime: string;
}
export async function listNews({ page, limit, type, filter }: GetListParam) {
  return bget<{ list: News[] }>({
    url: "/index/index/news",
    query: {
      page,
      limit,
      type,
      filter,
    },
  });
}
// 资料下载列表
export async function getDataList({ page, limit, filter }: GetListParam) {
  return bget<{ list: DataList[] }>({
    url: "/index/index/downloadList",
    query: {
      page,
      limit,
      filter,
    },
  });
}
// 资料下载请求列表
export async function getDownload(id: number) {
  return bget<{ list: DataList[] }>({
    url: "/index/index/download",
    query: {
      id,
    },
  });
}
// 新闻详情
export async function getDetail(id: number, language: string) {
  return bget({
    url: "/index/index/detail",
    query: {
      id,
      language,
    },
  });
}
// 联系我们接口
interface SaveForm {
  company: string;
  name: string;
  phone: string;
  email: string;
  country: string;
  remarks: string;
}
// 发展历史

export interface HistroyList {
  picture: { url: string }[];
  year: number;
  content: string;
}
export async function saveForm(data: SaveForm) {
  return bpost({
    url: "index/index/contact",
    data,
  });
}
// 标题接口
export async function getPageInitMeta() {
  return bget<InitMeta>({
    url: "/index/index/config",
    query: {},
  });
}

export interface RecruitType {
  title: string;
  data: string[];
  email: string;
  isActive: boolean;
}
// 社会招聘
export async function getSociety({ page, limit, filter }: GetListParam) {
  return bget<{ list: RecruitType[] }>({
    url: "recruit",
    query: {
      page,
      limit,
      filter,
    },
  });
}
// 发展历史
export async function getHistory({ page, limit, filter }: GetListParam) {
  return bget<HistroyList[]>({
    url: "getCompany",
    query: {
      page,
      limit,
      filter,
    },
  });
}
export interface ServicesList {
  id: number;
  name: string;
  description: string;
  content: string;
  picture: { url: string; is_video: boolean; name: string }[];
  material_name: string;
  list: {
    description: string[];
    name: string;
  }[]; //原材料清单列表
}
export interface MaterialList {
  description: string[];
  name: string;
}
//服务
export async function getList({ page, limit, filter }: GetListParam) {
  return bget<ServicesList[]>({
    url: "getService",
    query: {
      page,
      limit,
      filter,
    },
  });
}
export async function getTrip({ filter }: GetListParam) {
  return bget<{ description: string }[]>({
    url: "preachTrip",
    query: {
      filter,
    },
  });
}

export default {
  listNews,
  getDetail,
  saveForm,
  getPageInitMeta,
  getDataList,
  getDownload,
  getSociety,
  getHistory,
  getList,
  getTrip,
};
