import localeName from "@utils/localeName";
export default {
  [localeName.app.公司名]: "希荷",
  [localeName.app.集团名]: "希荷集团",
  [localeName.app.欢迎浏览我们的网页]: "欢迎浏览我们的网页",
  [localeName.app.common.导航.我们的产品]: "我们的产品",
  [localeName.app.common.导航.我们的服务]: "我们的服务",
  [localeName.app.common.导航.首页]: "首页",
  [localeName.app.common.导航.关于我们]: "关于我们",
  [localeName.app.common.导航.招聘]: "招聘",
  [localeName.app.common.导航.新闻下载]: "新闻下载",
  [localeName.app.common.导航.联系我们]: "联系我们",
  [localeName.app.加载更多]: "加载更多",
  [localeName.app.common.导航.了解详情]: "了解详情",
  [localeName.app.common.导航.查看更多]: "查看更多",
  [localeName.app.common.导航.欢迎浏览]: "欢迎浏览希荷网站！",
  // 地区:
  [localeName.app.common.导航.中国]: "中国",
  [localeName.app.common.导航.日本]: "日本",
  [localeName.app.common.导航.上海]: "上海",
  [localeName.app.common.导航.新加坡]: "新加坡",
  [localeName.app.common.导航.德国]: "德国",
  [localeName.app.common.导航.波兰]: "波兰",
  [localeName.app.common.导航.瑞士]: "瑞士",
  [localeName.app.common.导航.法国]: "法国",
  [localeName.app.common.导航.西班牙]: "西班牙",
  [localeName.app.common.导航.捷克]: "捷克",

  // 首页
  [localeName.app.home.首页.主标题一]: "您身边值得信赖的",
  [localeName.app.home.首页.主标题二]: "精密模具注塑合作伙伴",
  [localeName.app.home.首页.关于希荷]: "关于希荷",

  [localeName.app.home.首页.ES起步]:
    " ES起步于2010年的新加坡,发轫于产业集聚、交通发达的中国上海，腾飞于以精益制造著称的德国杜塞尔多夫。",
  [localeName.app.home.首页.始终坚持匠心]:
    " 始终坚持匠心传承与创新超越，与拥有50年历史积淀的老牌注塑德企AWM强强联合，致力于为全球客户提高高精度高品质的汽车，电子，医疗等行业零部件产品，为成为“您身边值得信赖的精密模具注塑合作伙伴”不断前进奋发!",
  [localeName.app.home.首页.ES致力于]:
    " ES 致力于为全球客户提供高精度的模具及注塑零部件",
  [localeName.app.home.首页.ES致力于分段]: "",

  [localeName.app.home.首页.我们的原则]: "我们的原则'客户至上,沟通第一'",
  [localeName.app.home.首页.严谨高效]: "严谨高效的项目管理",
  [localeName.app.home.首页.项目咨询]: "项目咨询",
  [localeName.app.home.首页.客户输入]:
    "客户输入：包括图纸（2d，3d），产品，技术说明，产品功能叙述等。ES 输出：3-5个工作日内提出产品可行性分析，一轮DFM,项目周期概括，成本预估。",
  [localeName.app.home.首页.技术方案]: "技术方案",
  [localeName.app.home.首页.产品分析]:
    " 利用Moldflow & Kisssoft 对产品进行充分分析，提供DFM,模具设计，原材料选择，样模，量产模以及产品量产一次性解决方案。",

  [localeName.app.home.首页.合同签订]: "合同签订",
  [localeName.app.home.首页.合同签订客户]:
    "客户可与es任何一家子公司进行合同签订，以确保客户核心利益。",
  [localeName.app.home.首页.交货完成]: "交货完成",
  [localeName.app.home.首页.输送到终端客户]:
    "es会在确保模具及产品质量的前提下，进行打包出货，并选择耗时最短，效率最高，成本最少的运输方案。 es德国子公司可为欧洲客户做产品仓储，再输送到终端客户，以节约成本",

  [localeName.app.home.首页.分布简介]:
    "ES致力于为全球客户提供高精度高品质的汽车，电子，医疗等行业零部件产品及模具.目前公司业务已经从新加坡拓展至中国东南部，日本，德国，瑞士，西班牙，捷克等多元发达国家。ES为成为“您身边值得信赖的精密模具注塑合作伙伴”不断前进奋发！",

  [localeName.app.home.首页.我们的目标]: "我们的目标",
  [localeName.app.home.首页.最新发布]: "最新发布",
  [localeName.app.home.首页.坚信最初]:
    " 坚信最初的梦想,努力拼搏,不断创造新的奇迹。",
  [localeName.app.home.首页.细小做到精致]:
    "ES不止快速前进的步伐,更是努力把细小做到精致!",
  [localeName.app.home.首页.身边的]: "您身边值得信赖的精密模具注塑合作伙伴",

  // 关于我们
  [localeName.app.home.关于我们.公司简介]: "公司简介",
  [localeName.app.home.关于我们.ES于2010年]:
    "ES于2010年创建至今已将业务逐步拓展至中国东南部，日本，德国，瑞士，西班牙，捷克等多元发达国家。Es的团队有着非常丰富的技术背景，对于我们的模具及产品有着非常严格的质量管控要求。在新加坡，中国，德国都有自己的技术，销售，项目管理团队，可以针对不同亚洲，欧洲市场的客户需求提供一站式的生产解决方案。我们期待与您合作。",
  [localeName.app.home.关于我们.合作伙伴AWM]: "合作伙伴AWM",
  [localeName.app.home.关于我们.AWM是一家]:
    "AWM是一家已有50年文化历史的德国本土模具制造与自动化公司.利用其独特的地理优势,其销售,技术服务可覆盖整个欧洲地区.AWM与ES的强强联手,为ES在欧洲市场的扩建奠定夯实的基础,扫除后顾之忧.",
  [localeName.app.home.关于我们.发展历史]: "发展历史",
  [localeName.app.home.关于我们.生产零件批准过程]: "生产零件批准过程",
  [localeName.app.home.关于我们.阶段一]: "阶段一",

  [localeName.app.home.关于我们.充分理解客户需求]: "充分理解客户需求",
  [localeName.app.home.关于我们.明确项目成员职责]: "明确项目成员职责",
  [localeName.app.home.关于我们.制定设计失效]:
    "制定设计失效模式分析文件（DFEMA),生产及装配可行性分析文件（Feasibility Study)",
  [localeName.app.home.关于我们.阶段二]: "阶段二",
  [localeName.app.home.关于我们.制定详细的过程]: "制定详细的过程失效分析文件",
  [localeName.app.home.关于我们.控制流程图]: " 控制流程图,控制计划",
  [localeName.app.home.关于我们.测量系统分析]:
    "测量系统分析（MSA)以及稳定生产能力分析（SPC)",
  [localeName.app.home.关于我们.阶段三]: "阶段三",
  [localeName.app.home.关于我们.当所有客户需求]:
    "当所有客户需求都被转化成实际可控的操作过程",

  [localeName.app.home.关于我们.ES会向客户提供样品阶段]:
    "ES会向客户提供样品阶段PSW,客户批准通过的情况下默认递交PPAP等级2的文件(客户特殊需求除外)",
  [localeName.app.home.关于我们.ES可为客户提供]:
    "ES可为客户提供一站式的全自动生产解决方案。",
  // 产品
  [localeName.app.home.产品.车身调节系列及传动系列相关齿轮产品]:
    "车身调节系列及传动系列相关齿轮产品",
  [localeName.app.home.产品.车顶调节器]: "车顶调节器",
  [localeName.app.home.产品.座椅调节器]: "座椅调节器",
  [localeName.app.home.产品.车尾调节器]: "车尾调节器",
  [localeName.app.home.产品.车灯调节器]: "车灯调节器",
  [localeName.app.home.产品.电子刹车EPB]: "电子刹车EPB",
  [localeName.app.home.产品.传动系统EPS]: "传动系统EPS",
  [localeName.app.home.产品.产品系列]: "产品系列",
  [localeName.app.home.产品.其他产品]: "其他产品",
  [localeName.app.home.产品.连接器类产品]: "连接器类产品",
  [localeName.app.home.产品.电动卷帘和门锁齿轮产品]: "电动卷帘和门锁齿轮产品",
  [localeName.app.home.产品.电子医疗]: "电子医疗",
  [localeName.app.home.产品.结构件]: "结构件",
  [localeName.app.home.产品.模具描述]: "模具描述",
  // 服务
  [localeName.app.home.服务.项目咨询]: "项目咨询",
  [localeName.app.home.服务.产品开发]: "产品开发",
  [localeName.app.home.服务.工程设计]: "工程设计",
  [localeName.app.home.服务.精密模具]: "精密模具",
  [localeName.app.home.服务.注塑生产]: "注塑生产",
  [localeName.app.home.服务.高精度检测]: "高精度检测",
  [localeName.app.home.服务.请您将图纸]:
    " 请您将图纸(2d,3d),产品,技术名称,产品功能叙述等",
  [localeName.app.home.服务.发送至]: "发送至",
  [localeName.app.home.服务.成本预估]:
    "ES:将提供产品可行性分析,一轮DFM,项目周期概括,成本预估",

  [localeName.app.home.服务.为什么选择希荷]: "为什么选择希荷?",
  [localeName.app.home.服务.国际制造]:
    "我们生产的产品遍布全世界，有竞争力的产品价格，便携的运输方式（当地存储）",
  [localeName.app.home.服务.长期的专业知识]: " 丰富的开发，制造经验",
  [localeName.app.home.服务.自己的工具店]:
    " 拥有自己的模具加工基地，可时效管控",
  [localeName.app.home.服务.内部材料数据库]: "拥有一手的原材料供应储备及经验",
  [localeName.app.home.服务.各种产品开发经验]: "各种行业产品生产经验",
  // 招聘
  [localeName.app.home.招聘.校园招聘]: "校园招聘",
  [localeName.app.home.招聘.社会招聘]: "社会招聘",
  [localeName.app.home.招聘.网络申请]: "网络申请",
  [localeName.app.home.招聘.参加宣讲会]: "参加宣讲会",
  [localeName.app.home.招聘.笔试]: "笔试",
  [localeName.app.home.招聘.面试]: "面试",
  [localeName.app.home.招聘.OFFER]: "OFFER",
  [localeName.app.home.招聘.入职]: "入职",
  [localeName.app.home.招聘.关爱生活品质]: "关爱生活品质",
  [localeName.app.home.招聘.关注动作服务]: "关注动作服务",
  [localeName.app.home.招聘.重视员工体验]: "重视员工体验",
  [localeName.app.home.招聘.贴心定制福利]: "贴心定制福利",

  [localeName.app.home.招聘.员工免费定期体检]: "员工免费定期体检",
  [localeName.app.home.招聘.补充商业医疗险]: "补充商业医疗险",
  [localeName.app.home.招聘.弹性工作时间]: "弹性工作时间",
  [localeName.app.home.招聘.优质工作餐保障]: "优质工作餐保障",
  [localeName.app.home.招聘.宿舍安排保障]: "宿舍安排保障",
  [localeName.app.home.招聘.交通出行保障]: "交通出行保障",
  [localeName.app.home.招聘.丰富的部门团建活动]: "丰富的部门团建活动",
  [localeName.app.home.招聘.趣味运动和家庭日]: "趣味运动和家庭日",
  [localeName.app.home.招聘.员工活动中心]: "员工活动中心",
  [localeName.app.home.招聘.各类俱乐部]: "各类俱乐部",
  [localeName.app.home.招聘.传统佳节礼物]: "传统佳节礼物",
  [localeName.app.home.招聘.生日祝福新婚关爱]: "生日祝福 新婚关爱",
  [localeName.app.home.招聘.入职纪念]: "入职纪念",
  [localeName.app.home.招聘.长期服务奖励]: "长期服务奖励",

  [localeName.app.home.招聘.校招流程]: "校招流程",
  [localeName.app.home.招聘.为什么选择希荷]: "为什么选择希荷",
  [localeName.app.home.招聘.岗位职责]: "岗位职责",
  [localeName.app.home.招聘.要求]: "要求",
  [localeName.app.home.招聘.薪资待遇]: "薪资待遇",
  [localeName.app.home.招聘.薪酬]: "薪酬",
  [localeName.app.home.招聘.具有行业竞争力的薪资]: "具有行业竞争力的薪资",
  [localeName.app.home.招聘.绩效导向的多样化激励]: "绩效导向的多样化激励",
  [localeName.app.home.招聘.市场匹配的年度涨薪机制]: "市场匹配的年度涨薪机制",
  [localeName.app.home.招聘.校招岗位]: "校招岗位",
  [localeName.app.home.招聘.投递简历]: "投递简历",
  [localeName.app.home.招聘.福利]: "福利",

  // 新闻
  [localeName.app.home.新闻.新闻资讯]: "新闻资讯",
  [localeName.app.home.新闻.资料下载]: "资料下载",
  // 联系
  [localeName.app.home.联系.联系我们]: "联系我们",
  [localeName.app.home.联系.销售经理]: "销售经理",
  [localeName.app.home.联系.国际销售经理]: "国际销售经理",
  [localeName.app.home.联系.总经理]: "总经理",
  [localeName.app.home.联系.公司]: "公司",
  [localeName.app.home.联系.姓名]: "姓名",
  [localeName.app.home.联系.电话]: "电话",
  [localeName.app.home.联系.邮箱]: "邮箱",
  [localeName.app.home.联系.国家]: "国家",
  [localeName.app.home.联系.主题]: "主题",
  [localeName.app.home.联系.街道]: "街道",
  [localeName.app.home.联系.城镇]: "城镇",
  [localeName.app.home.联系.地区]: "地区",
  [localeName.app.home.联系.您有任何疑问]: "您有任何疑问？",
  [localeName.app.home.联系.对于您提出的关于汽车传动系统齿轮件]:
    "对于您提出的关于汽车传动系统齿轮件，医疗件，电子连接器等方面的问题，我们希望确保您收到快捷、专业的答案。通过使用以下表格，您的问题将被直接发送到相关行业部门负责的应用工程人员那里。我们的工程师随即将与您联系。",
  [localeName.app.home.联系.邮编]: "邮编",
  [localeName.app.home.联系.留言]: "请在这里输入您的留言信息",
  [localeName.app.home.联系.提交]: "提交",
  [localeName.app.home.联系.销售支持]: "销售支持",
  [localeName.app.home.联系.新加坡地区技术]: "新加坡地区技术",
  [localeName.app.home.联系.模具询价]: "模具询价",
  [localeName.app.home.联系.注塑产品询价]: "注塑产品询价",
  [localeName.app.home.联系.冲压件产品询价]: "冲压件产品询价",
  [localeName.app.home.联系.技术支持]: "技术支持",
  [localeName.app.home.联系.塑料粒子采购]: "塑料粒子采购",
  [localeName.app.home.联系.其他服务商]: "其他服务商",
  [localeName.app.home.联系.人事和招聘]: "人事和招聘",
  [localeName.app.home.联系.中国]: "中国",
  [localeName.app.home.联系.英国]: "英国",
  [localeName.app.home.联系.美国]: "美国",
  [localeName.app.home.联系.加拿大]: "加拿大",
  [localeName.app.home.联系.俄罗斯]: "俄罗斯",
  [localeName.app.home.联系.埃及]: "埃及",
  [localeName.app.home.联系.希腊]: "希腊",
  [localeName.app.home.联系.法国]: "法国",
  [localeName.app.home.联系.德国]: "德国",
  [localeName.app.home.联系.日本]: "日本",
  [localeName.app.home.联系.其它]: "其他",
};
