// import gImg from "@/utils/img"
import { GetListParam, bget } from "./api";

export interface ProductLists {
  img?: string;
  title: string;
  link?: string;
  description?: string;
  dieSize?: string;
  dieMaterial?: string;
  cycleTime?: string;
  door?: string;
  characteristic?: string;
  isActive?: boolean;
}
export interface ProductsType {
  url: string;
  name: string;
}
export interface ProductsAllType {
  id: number;
  name: string;
  picture: ProductsType[];
}

export interface ProductsActive {
  id: number;
  name: string;
  description: string;
  link: string;
  img: string;
  picture: ProductsType[];
}
// 我们的产品页面
export async function getProducts({ page, limit, filter }: GetListParam) {
  return bget<ProductsActive[]>({
    url: "/index/index/product",
    query: {
      page,
      limit,
      filter
    }
  });
}
export async function getProductsLints({ page, limit, filter }: GetListParam) {
  return bget<ProductsActive[]>({
    url: "index/index/singleProduct",
    query: {
      page,
      limit,
      filter
    }
  });
}
export async function getProductsDetail({ page, limit, filter }: GetListParam) {
  return bget<ProductsActive>({
    url: "/index/index/singleProduct",
    query: {
      page,
      limit,
      filter
    }
  });
}
//首页
export async function getIndexProducts({ page, limit, filter }: GetListParam) {
  return bget<ProductsAllType[]>({
    url: "indexProduct",
    query: {
      page,
      limit,
      filter
    }
  });
}

export interface ServicesList {
  id: number;
  name: string;
  description: string;
  content: string;
  picture: {
    is_video: boolean;
    name: string;
    url: string;
  }[];
  isActive?: boolean;
  materials: string;
  material_name: string;
  list: { description: string; id: number; name: string }[]; //原材料清单列表
}
export interface MaterialList {
  name: string;
  detailList: {
    title: string;
    parts: string[];
  }[];
}
//服务
export async function getServices({ page, limit, filter }: GetListParam) {
  return bget<ServicesList[]>({
    url: "getService",
    query: {
      page,
      limit,
      filter
    }
  });
}
export default {
  getProducts,
  getProductsDetail,
  getIndexProducts,
  getServices
};
