import CommonHeader from "@/components/CommonHeader";
import { CurrNav } from "@/components/Header";
import { CDN_BASE_URL } from "@/config/prod";
import { AppState } from "@/redux/stores/app";
import Slider from "react-slick";
import {
  getProducts,
  getProductsDetail,
  ProductsActive,
} from "@/services/product";
import { Action } from "@/typings";
import gImg from "@/utils/img";
import localeName from "@/utils/localeName";
import { InitMeta } from "@api/news";
import { ProductLists } from "@api/product";
import * as appAction from "@redux/actions/app";
import gSass from "@utils/sass";
import csn from "classnames";
import RcQueueAnim from "rc-queue-anim";
import ScrollOverPack from "rc-scroll-anim/lib/ScrollOverPack";
import React, { Component, createRef, Fragment } from "react";
import { Helmet } from "react-helmet";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
// import productApi, { ProductLists } from "@api/pro2duct";
import { Link } from "react-router-dom";
import * as Scroll from "react-scroll";
import { Dispatch } from "redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Footer from "@components/Footer";
import Header from "@components/Header";
const style = gSass.index.product.index;
let Element = Scroll.Element;
var Links = Scroll.Link;
const settingProduct = {
  slidesToShow: 3,
};
const infor_img = {
  autoplay: true,
  infinite: false,
  // centerPadding: "200px",
  slidesToShow: 1,
};
interface Props {}
interface State {
  apparatus: any;
  isGerät: number;
  page: number;
  limit: number;
  count: number;
  activeImg: string;
  filter: {
    languages: string;
    id: number;
  };
  productsLength: number;
  pageInitMeta: InitMeta;
  isEnd: boolean;
  activesProduct: ProductsActive;
  otherProducts: ProductLists[];
  activeNum: number;
  categoryName: string;
  pageWidth: number;
  showSlidesNum: number;
  categoryProduct: ProductsActive[];
}
const mapStateToProps = (state: AppState) => ({
  app: state.app,
  lang: state.app.lang,
});
const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    setLanguage: (lang: string) => {
      dispatch(appAction.setLanguage(lang));
    },
    setCurrNav: (currNav: CurrNav) => {
      dispatch(appAction.setCurrNav(currNav));
    },
  };
};
// @ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
// @ts-ignore
@injectIntl
export default class Index extends Component<
  Props &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> &
    InjectedIntlProps,
  State
> {
  static defaultProps: ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> &
    InjectedIntlProps;
  scrollDiv = createRef<HTMLDivElement>();
  constructor(props: any) {
    super(props);
    this.state = this.getInitState();
  }
  getInitState = (): State => {
    return {
      apparatus: [],
      isGerät: 0,
      activeImg: "",
      pageWidth: 0,
      showSlidesNum: 0,
      page: 0,
      limit: 1,
      count: 0,
      filter: {
        languages: "zh-cn",
        id: 0,
      },
      productsLength: 0,
      categoryName: "",
      activeNum: 0,
      pageInitMeta: {
        title: "",
        subTitle: "",
        keywords: "",
        description: "",
      },
      isEnd: false,
      activesProduct: {
        id: 0,
        link: "",
        img: "",
        name: "",
        description: "这里是测试描述",
        picture: [
          {
            url: "/uploads/20200118/026965b7315afc770fa33423adeefd18.png",
            name: "light5.png",
          },
        ],
      },
      otherProducts: [
        // {
        //   img: gImg.product.more2,
        //   title: localeName.app.home.产品.连接器类产品,
        //   description: "",
        // },
        {
          img: gImg.product.more1,
          title: localeName.app.home.产品.电动卷帘和门锁齿轮产品,
          description: "",
        },
        // {
        //   img: gImg.product.more3,
        //   title: localeName.app.home.产品.电子医疗,
        //   description: "",
        // },
        // {
        //   img: gImg.product.more2,
        //   title: localeName.app.home.产品.连接器类产品,
        //   description: "",
        // },
        // {
        //   img: gImg.product.more1,
        //   title: localeName.app.home.产品.电动卷帘和门锁齿轮产品,
        //   description: "",
        // },
        // {
        //   img: gImg.product.more3,
        //   title: localeName.app.home.产品.电子医疗,
        //   description: "",
        // },
      ],
      categoryProduct: [],
    };
  };
  componentDidMount() {
    this.setpageWidth();
    window.addEventListener("resize", this.setpageWidth);
    if (this.scrollDiv.current) {
      this.scrollDiv.current.scrollIntoView();
    }
    this.setState(
      {
        filter: {
          ...this.state.filter,
          languages: this.props.app.lang,
        },
      },
      () => {
        this.init();
      }
    );
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.setpageWidth);
  }
  init = () => {
    this.listProducts();
  };
  listProducts = async () => {
    this.setState({
      filter: {
        ...this.state.filter,
        languages: this.props.app.lang,
      },
    });
    let {
      categoryProduct,
      activesProduct,
      activeImg,
      productsLength,
      page,
      limit,
      filter,
      apparatus,
    } = this.state;
    categoryProduct = [];
    let { data: activeProduct } = await getProducts({
      page,
      limit,
      filter,
    });
    activeProduct.forEach((item: any) => {
      if (item.name !== "其他") {
        categoryProduct.push(item);
      }
    });
    // for (let v of activeProduct) {
    //   if(v.name&&v.name==='其它'){
    //     console.log(123)
    //   }else{
    //     categoryProduct.push(v);
    // }
    console.log(activeProduct, "activeProduct");
    apparatus = activeProduct[0];
    activesProduct.description = activeProduct[3].description;
    activesProduct.name = activeProduct[3].name;
    for (let v of activeProduct[3].picture) {
      activesProduct.picture.push(v);
    }
    productsLength = activesProduct.picture.length;
    activeImg = activesProduct.picture[0].url;
    // activeImg = apparatus.picture[0].url;

    this.setState({
      activesProduct,
      activeImg,
      productsLength,
      categoryProduct,
      apparatus,
    });
  };
  // 获取语言修改后props值
  componentWillReceiveProps(nextProps: any) {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          languages: nextProps.lang,
        },
      },
      () => {
        this.init();
      }
    );
  }
  // 改变列表中的数据
  selectProduct = (index: number) => {
    this.setState({
      activeNum: index,
    });
    let { activesProduct, activeImg } = this.state;
    activeImg = activesProduct.picture[index].url;
    this.setState({
      activeImg,
    });
  };
  //ceshi
  getUrl() {
    console.log(123123123123);
  }
  //监听屏幕宽度
  setpageWidth = () => {
    this.setState({
      pageWidth: document.body.clientWidth,
    });
  };
  // 改变产品列表
  changeProductsList = async () => {
    let { page, limit, filter, activesProduct, productsLength, activeImg } =
      this.state;
    let { data: activeProduct } = await getProductsDetail({
      page,
      limit,
      filter,
    });
    activesProduct.name = activeProduct.name;
    activesProduct.description = activeProduct.description;
    activesProduct.picture = [];
    for (let v of activeProduct.picture) {
      activesProduct.picture.push(v);
    }
    productsLength = activesProduct.picture.length;

    activeImg = activesProduct.picture[0].url;
    this.setState({
      activesProduct,
      activeImg,
      productsLength,
    });
  };

  render() {
    const { messages: m } = this.props.intl;
    let {
      pageInitMeta,
      otherProducts,
      // activeNum,
      activesProduct,
      // activeImg,
      categoryProduct,
      productsLength,
      isGerät,
      apparatus,
      pageWidth,
      showSlidesNum,
    } = this.state;
    // if (pageWidth > 1200) {
    //   showSlidesNum = 3;
    // } else if (pageWidth < 500) {
    //   showSlidesNum = 1;
    // } else {
    //   showSlidesNum = 2;
    // }
    showSlidesNum = 1
    console.log(activesProduct, pageWidth, showSlidesNum);
    return (
      <div className={style.main} ref={this.scrollDiv}>
        <Header activeTitle="home" />
        <Helmet>
          <title>{pageInitMeta.title}</title>
          <meta name="description" content={pageInitMeta.description} />
          <meta name="keywords" content={pageInitMeta.keywords} />
        </Helmet>
        <div className={csn(style.headerBanner, "flex")}>
          <CommonHeader
            banner={gImg.product.productBanner}
            mainTitle={m[localeName.app.common.导航.我们的产品]}
            subTitleOne={m[localeName.app.common.导航.我们的产品]}
            subTitleTwo={""}
          />
        </div>
        {pageWidth < 1200 ? (
          ""
        ) : (
          <div className={style.productIntroduce} id="productIntroduce">
            <div className={csn(style.informationsliste, "flex")}>
              <div className={style.infor_l}>
                <div className={style.l_allin}>
                  <div className={style.infor_text}>
                    <h3>{apparatus.name}</h3>
                    <div className={style.text_name}>
                      {m[localeName.app.home.产品.结构件]}
                    </div>
                    <div className={style.text_beschreibung}>
                      {m[localeName.app.home.产品.模具描述]}：
                      {apparatus.mold_description}
                    </div>
                    <ul>
                      {apparatus.mold_characteristics &&
                      apparatus.mold_characteristics.length > 0
                        ? apparatus.mold_characteristics.map(
                            (v: any, k: any) => <li key={k}>{v}</li>
                          )
                        : ""}
                    </ul>
                  </div>
                  <div className={style.infor_img}>
                    {/* {
                      apparatus.picture&&apparatus.picture.length>0?apparatus.picture.map((v:any)=>{
                        return (
                          <img src={CDN_BASE_URL+v.url} alt="" style={{width:apparatus.picture.length>=2&&apparatus.picture.length<=4?'30%':apparatus.picture.length>=5&&apparatus.picture.length<=9?'30%':''}} />

                        )
                      }):''
                    } */}
                    <Slider {...infor_img} className={csn(style.swiperLIst)}>
                      {apparatus.picture && apparatus.picture.length > 0
                        ? apparatus.picture.map((v: any, k: any) => {
                            return (
                              <div key={k}>
                                <img
                                  src={CDN_BASE_URL + v.url}
                                  style={{ width: "100%" }}
                                  alt=""
                                />
                              </div>
                            );
                          })
                        : ""}
                    </Slider>
                  </div>
                </div>
              </div>
              <div className={style.infor_r}>
                {categoryProduct.map((v: any, j) => {
                  return (
                    <div
                      className={j === isGerät ? csn(style.inforCol) : ""}
                      key={j}
                      onClick={() => {
                        this.setState({
                          isGerät: j,
                          apparatus: v,
                        });
                      }}
                    >
                      {v.name}
                    </div>
                  );
                })}
              </div>
            </div>
            {pageWidth >= 1200 ? (
              ""
            ) : (
              <div className={csn(style.carWrap, "flex")}>
                <div className={csn(style.carContent, "flex")}>
                  <RcQueueAnim leaveReverse ease="easeInOutQuart" type="bottom">
                    <div key="1" className={csn(style.carImg, "flex")}>
                      <img src={gImg.product.car} alt="" />
                    </div>
                    <div className={csn(style.contentWrap, "flex")}>
                      <RcQueueAnim
                        leaveReverse
                        ease="easeInOutQuart"
                        type="bottom"
                        delay={300}
                      >
                        {categoryProduct.map((v, k) => {
                          return (
                            <Fragment>
                              {v.link === "" ? (
                                <Links
                                  key={k}
                                  className={style.item}
                                  to="project"
                                  spy={true}
                                  smooth={true}
                                  offset={-100}
                                  duration={500}
                                  onClick={() => {
                                    this.setState(
                                      {
                                        filter: {
                                          ...this.state.filter,
                                          id: v.id,
                                        },
                                      },
                                      () => {
                                        this.changeProductsList();
                                      }
                                    );
                                  }}
                                >
                                  <div className={style.name}>{v.name}</div>
                                  <div className={style.imgWrap}>
                                    <img src={CDN_BASE_URL + v.img} alt="线" />
                                  </div>
                                </Links>
                              ) : (
                                <div key={k + 1} className={style.item}>
                                  <div className={style.name}>{v.name}</div>
                                  <div className={style.imgWrap}>
                                    <img src={CDN_BASE_URL + v.img} alt="线" />
                                  </div>
                                </div>
                              )}
                            </Fragment>
                          );
                        })}
                      </RcQueueAnim>
                    </div>
                    <MediaQuery maxWidth={1200}>
                      <RcQueueAnim
                        className={csn(style.mobileNavContent, "flex")}
                      >
                        {categoryProduct.map((v, k) => {
                          return (
                            <Fragment>
                              {v.link === "" ? (
                                <div
                                  className={style.item}
                                  key={k + 1}
                                  onClick={() => {
                                    this.setState(
                                      {
                                        filter: {
                                          ...this.state.filter,
                                          id: v.id,
                                        },
                                      },
                                      () => {
                                        this.changeProductsList();
                                      }
                                    );
                                  }}
                                >
                                  {v.name}
                                </div>
                              ) : (
                                <Link
                                  to={v.link}
                                  key={k + 1}
                                  className={style.item}
                                  onClick={() => {
                                    this.props.setCurrNav("contact");
                                  }}
                                >
                                  {v.name}
                                </Link>
                              )}
                            </Fragment>
                          );
                        })}
                      </RcQueueAnim>
                    </MediaQuery>
                  </RcQueueAnim>
                </div>
              </div>
            )}
          </div>
        )}
        {/* 产品列表 */}
        {pageWidth >= 1200 ? (
          ""
        ) : (
          <Element name="project">
            <div className={csn(style.productListWrap, "flex")}>
              <MediaQuery minWidth={1200}>
                <div className={csn(style.modifyProductsContainer, "flex")}>
                  <div className={csn(style.modifyProductLeft, "flex")}>
                    <div className={csn(style.left, "flex")}>
                      <div className={style.allTitle}>
                        {apparatus.name}
                        {m[localeName.app.home.产品.产品系列]}
                      </div>
                      <div className={style.title}>
                        {apparatus.mold_description}
                      </div>
                    </div>
                  </div>
                  <div className={csn(style.modifyProductRight, "flex")}>
                    <Slider
                      infinite={true}
                      // slidesToShow={3}
                      speed={500}
                      // slidesToShow={productsLength === 1 ? 1 : 3}
                      rows={productsLength > 6 ? 3 : 2}
                      // slidesPerRow={1}
                      className={csn(style.productsList)}
                    >
                      {apparatus.picture && apparatus.picture.length > 0
                        ? apparatus.picture.map((v: any, k: any) => {
                            return (
                              <div
                                className={csn(style.item, "flex")}
                                key={k}
                                // onClick={() => {
                                //   this.selectProduct(k);
                                // }}
                              >
                                <div className={csn(style.imgWrap, "flex")}>
                                  <img
                                    className={style.img}
                                    src={CDN_BASE_URL + v.url}
                                    alt=""
                                  />
                                </div>
                                <div className={style.maskWrap}>
                                  <div
                                    className={csn(style.maskContent, "flex")}
                                  >
                                    <a
                                      href="/contact"
                                      className={style.detailWrap}
                                    >
                                      <span className={style.detail}>
                                        {m[localeName.app.home.联系.联系我们]}
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </Slider>
                  </div>
                  <div className={style.infor_r}>
                    {categoryProduct.map((v: any, j) => {
                      return (
                        <div
                          className={j === isGerät ? csn(style.inforCol) : ""}
                          key={j}
                          onClick={() => {
                            this.setState({
                              isGerät: j,
                              apparatus: v,
                            });
                          }}
                        >
                          {v.name}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </MediaQuery>
              {/* <MediaQuery minWidth={1200}>
              <div className={csn(style.productsContainer, "flex")}>
                <div className={csn(style.productLeft, "flex")}>
                  <div className={csn(style.detailWrap, "flex")}>
                    <div className={csn(style.left, "flex")}>
                      <div className={style.allTitle}>
                        {activesProduct.name}
                        {m[localeName.app.home.产品.产品系列]}
                      </div>
                      <div className={style.title}>
                        {activesProduct.description}
                      </div>
                    </div>
                    <div className={csn(style.right, "flex")}>
                      <div className={csn(style.imgWrap, "flex")}>
                        <img src={CDN_BASE_URL + activeImg} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={csn(style.productRight, "flex")}>
                  <Slider
                    speed={500}
                    slidesToShow={productsLength < 4 ? productsLength : 4}
                    slidesToScroll={1}
                    vertical={true}
                    verticalSwiping={true}
                    infinite={true}
                    swipeToSlide={true}
                    className={csn(style.productsList, "flex")}
                  >
                    {activesProduct.picture.map((v, k) => {
                      return (
                        <div
                          className={csn(
                            activeNum === k ? style.itemActive : style.item,
                            "flex"
                          )}
                          key={k}
                          onClick={() => {
                            this.selectProduct(k);
                          }}
                        >
                          <div className={csn(style.imgWrap, "flex")}>
                            <img
                              className={style.img}
                              src={CDN_BASE_URL + v.url}
                              alt=""
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </MediaQuery> */}
              <MediaQuery maxWidth={1200}>
                <div className={csn(style.mobileContainer, "flex")}>
                  <div className={csn(style.infor_r, "flex")}>
                    {categoryProduct.map((v: any, j) => {
                      return (
                        <div
                          className={j === isGerät ? csn(style.inforCol) : ""}
                          key={j}
                          onClick={() => {
                            this.setState({
                              isGerät: j,
                              apparatus: v,
                            });
                          }}
                        >
                          {v.name}
                        </div>
                      );
                    })}
                  </div>
                  <div className={csn(style.productLeft, "flex")}>
                    <div className={style.allTitle}>
                      {apparatus.name}
                      {m[localeName.app.home.产品.产品系列]}
                    </div>
                    <div className={style.title}>{apparatus.description}</div>
                  </div>
                  <div className={csn(style.productRight, "flex")}>
                    {/* <Slider
                      speed={500}
                      slidesToShow={productsLength < 4 ? productsLength : 4}
                      slidesToScroll={1}
                      infinite={true}
                      swipeToSlide={true}
                      className={csn(style.mobileProductsList, "flex")}
                    >
                      {apparatus.picture && apparatus.picture.length > 0
                        ? apparatus.picture.map((v: any, k: any) => {
                            return (
                              <div
                                className={csn(
                                  activeNum === k
                                    ? style.itemActive
                                    : style.item,
                                  "flex"
                                )}
                                key={k}
                                onClick={() => {
                                  this.selectProduct(k);
                                }}
                              >
                                <div className={csn(style.imgWrap, "flex")}>
                                  <img
                                    className={style.img}
                                    src={CDN_BASE_URL + v.url}
                                    alt=""
                                  />
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </Slider> */}
                    <div className={style.infor_img}>
                      <Slider
                        swipeToSlide={true}
                        slidesToScroll={1}
                        {...infor_img}
                        className={csn(style.swiperList)}
                      >
                        {apparatus.picture && apparatus.picture.length > 0
                          ? apparatus.picture.map((v: any, k: any) => {
                              return (
                                <div key={k}>
                                  <img
                                    src={CDN_BASE_URL + v.url}
                                    alt=""
                                    style={{ width: "100%" }}
                                  />
                                </div>
                              );
                            })
                          : ""}
                      </Slider>
                    </div>
                  </div>
                </div>
              </MediaQuery>
            </div>
          </Element>
        )}
        <div className={style.otherProductsWrap} id="otherProducts">
          <div className={csn(style.otherProducts, "flex")}>
            <ScrollOverPack playScale={0.1} location="otherProducts">
              <RcQueueAnim
                leaveReverse
                ease="easeInOutQuart"
                type="bottom"
                delay={300}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div key="1" className={style.title}>
                  {m[localeName.app.home.产品.其他产品]}
                </div>
                <div key="2" className={csn(style.otherList, "flex")}>
                
                  <RcQueueAnim
                    leaveReverse
                    ease="easeInOutQuart"
                    type="bottom"
                    delay={300}
                    className={csn(style.otherListWrap)}
                  >
                    
                    <Slider
                      {...settingProduct}
                      className={style.productsList}
                      slidesToShow={showSlidesNum}
                    >
                      {otherProducts.map((v, k) => {
                        return (
                          <div className={csn(style.item, "flex")} key={k + 1}>
                            <div className={csn(style.itemContent, "flex")}>
                              <div className={csn(style.imgWrap, "flex")}>
                                <img src={v.img} alt="" />
                                <div className={style.name}>
                                  {m[v.title as string]}
                                </div>
                              </div>
                              <div className={style.maskWrap}>
                                <div className={csn(style.maskContent, "flex")}>
                                  <span className={style.name}>
                                    {m[v.title as string]}
                                  </span>
                                  <a
                                    key="4"
                                    href="/contact"
                                    className={style.detailWrap}
                                  >
                                    <span className={style.detail}>
                                      {m[localeName.app.home.联系.联系我们]}
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  </RcQueueAnim>
                </div>
              </RcQueueAnim>
            </ScrollOverPack>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
