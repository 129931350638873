import CommonHeader from "@/components/CommonHeader";
import { AppState } from "@/redux/stores/app";
import { Action } from "@/typings";
import gImg from "@/utils/img";
import localeName from "@/utils/localeName";
import newsApi, { DataList, InitMeta, News } from "@api/news";
import * as appAction from "@redux/actions/app";
import gSass from "@utils/sass";
import { Col, Pagination, Row } from "antd";
import csn from "classnames";
import React, { Component, createRef } from "react";
import { Helmet } from "react-helmet";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import { CDN_BASE_URL, BASE_URL } from "../../config/prod";

import Footer from "@components/Footer";

import Header from "@components/Header";

const style = gSass.index.news.index;

interface Props {}
interface State {
  page: number;
  limit: number;
  count: number;
  filter: {
    languages: string;
    id: number;
  };
  newsList: News[];
  dataList: DataList[];
  pageInitMeta: InitMeta;
  language: {};
  type: number;
}

const mapStateToProps = (state: AppState) => ({
  app: state.app,
  lang: state.app.lang,
});
const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    setLanguage: (lang: string) => {
      dispatch(appAction.setLanguage(lang));
    },
  };
};

// @ts-ignore
@injectIntl
// @ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
export default class Index extends Component<
  Props &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> &
    InjectedIntlProps,
  State
> {
  static defaultProps: ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> &
    InjectedIntlProps;
  scrollDiv = createRef<HTMLDivElement>();
  constructor(props: any) {
    super(props);

    this.state = this.getInitState();
  }

  getInitState = (): State => {
    return {
      pageInitMeta: {
        title: "",
        subTitle: "",
        keywords: "",
        description: "",
      },
      type: 3, //1代表英语,2代表德语,3.代表中文
      page: 1,
      limit: 5,
      count: 0,
      filter: {
        languages: "zh-cn",
        id: 0,
      },
      newsList: [],
      dataList: [],
      language: this.props.lang,
    };
  };
  componentDidMount() {
    if (this.scrollDiv.current) {
      this.scrollDiv.current.scrollIntoView();
    }
    this.setState(
      {
        filter: {
          ...this.state.filter,
          languages: this.props.app.lang,
        },
      },
      () => {
        this.init();
      }
    );
  }
  init = () => {
    this.listNews();
  };
  listNews = async () => {
    console.log("init");
    let language = this.state.language;
    console.log(language);
    console.log(this.state.type);
    let { page, limit, filter, dataList, newsList } = this.state;
    let { data: pageInitMeta } = await newsApi.getPageInitMeta();
    newsList = [];
    let {
      data: { list: newsLists },
      count,
    } = await newsApi.listNews({ page, limit, filter });
    newsList.push(...newsLists);
    dataList = [];
    let {
      data: { list: downloadList },
    } = await newsApi.getDataList({ page, limit, filter });
    dataList.push(...downloadList);
    this.setState({
      pageInitMeta,
      dataList,
      newsList,
      count,
    });
  };
  componentWillReceiveProps(nextProps: any) {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          languages: nextProps.lang,
        },
      },
      () => {
        this.init();
      }
    );
  }
  showMore = async (pages: number) => {
    let { page, limit, filter, type } = this.state;
    page = pages;
    let {
      data: { list: newsList },
      count,
    } = await newsApi.listNews({ page, limit, filter, type });
    this.setState({
      newsList,
      count,
    });
  };
  download = (id: number) => {
    newsApi.getDownload(id);
  };
  leftData = async () => {
    let { page, limit, filter, type } = this.state;
    page--;
    let {
      data: { list: downloadList },
    } = await newsApi.getDataList({ page, limit, filter, type });
    this.setState({
      dataList: downloadList,
    });
  };
  rightData = async () => {
    let { page, limit, filter } = this.state;
    page++;
    let {
      data: { list: downloadList },
    } = await newsApi.getDataList({ page, limit, filter });
    this.setState({
      dataList: downloadList,
    });
  };
  render() {
    let { newsList, dataList, page, count, limit, pageInitMeta } = this.state;
    const { messages: m } = this.props.intl;
    return (
      <div className={style.main} ref={this.scrollDiv}>
        <Header activeTitle="home" />
        <Helmet>
          <title>{pageInitMeta.title}</title>
          <meta name="description" content={pageInitMeta.description} />
          <meta name="keywords" content={pageInitMeta.keywords} />
        </Helmet>
        <div className={csn(style.headerBanner, "flex")}>
          <CommonHeader
            banner={gImg.news.newBg}
            mainTitle={m[localeName.app.common.导航.新闻下载]}
            subTitleOne={m[localeName.app.common.导航.新闻下载]}
            subTitleTwo={""}
          />
        </div>
        <div className={style.center}>
          <Row>
            <Col xs={24} sm={24} md={16}>
              <div className={style.left}>
                <div className={style.headerWrap}>
                  <span className={style.headerTitle}>
                    {m[localeName.app.home.新闻.新闻资讯]}
                  </span>
                </div>
                <div className={style.newsList}>
                  {newsList.map((news, k) => {
                    return (
                      <Link
                        className={csn(style.newsItem, "flex")}
                        to={"/news/detail?id=" + news.id}
                        key={k}
                      >
                        <div className={style.newsDetails}>
                          <div className={style.newsTitle}>{news.title}</div>
                          <div className={style.newsDescription}>
                            {news.detail}
                          </div>
                          <div className={style.newsDetail}>
                            {m[localeName.app.common.导航.了解详情]}
                          </div>
                        </div>
                        <div className={style.newsImgFa}>
                          <img
                            className={style.newsImg}
                            src={
                              news.pic
                                ? CDN_BASE_URL + news.pic.url
                                : gImg.news.newsOne
                            }
                            alt="新闻图片"
                          />
                        </div>
                      </Link>
                    );
                  })}
                </div>
                <Pagination
                  className={style.pagination}
                  defaultCurrent={page}
                  defaultPageSize={limit}
                  total={count}
                  onChange={(pages) => {
                    this.setState({
                      page: pages,
                    });
                    this.showMore(pages);
                  }}
                ></Pagination>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <div className={style.right}>
                <div className={style.headerWrap}>
                  <span className={style.headerTitle}>
                    {m[localeName.app.home.新闻.资料下载]}
                  </span>
                </div>
                <div className={style.dataList}>
                  {dataList.map((data, k) => {
                    return (
                      <a
                        className={csn(
                          style.dataItem,
                          "flex alignItemsCenter justifyContentSpaceBetween"
                        )}
                        key={k}
                        href={
                          BASE_URL +
                          data.link +
                          "?id=" +
                          data.id +
                          "&language=" +
                          this.state.filter.languages
                        }
                        download="图片"
                      >
                        <div
                          className={csn(
                            style.dataLeft,
                            "flex alignItemsCenter"
                          )}
                        >
                          <div className={style.filesWrap}>
                            <img
                              className={style.files}
                              src={gImg.news.filesBlue}
                              alt="文件夹"
                            />
                          </div>
                          <div className={style.dataName}>{data.name}</div>
                        </div>
                        <div className={style.downloadWrap}>
                          <img
                            className={style.download}
                            src={gImg.news.downloadBlue}
                            alt="下载"
                          />
                        </div>
                      </a>
                    );
                  })}
                </div>
                <div
                  className={csn(
                    style.switchWrap,
                    "flex alignItemsCenter justifyContentSpaceBetween"
                  )}
                >
                  <div
                    className={style.arrowWrap}
                    onClick={() => {
                      this.leftData();
                    }}
                  >
                    <img
                      className={style.arrow}
                      src={gImg.news.left}
                      alt="左"
                    />
                  </div>
                  <div
                    className={style.arrowWrap}
                    onClick={() => {
                      this.rightData();
                    }}
                  >
                    <img
                      className={style.arrow}
                      src={gImg.news.right}
                      alt="右"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Footer />
      </div>
    );
  }
}
