import * as appAction from "../actions/app";
import { Overwrite } from "utility-types";
import { Action } from "@/typings";
import { defaultLang } from "@/config/app";
import { Lang } from "@/utils/locale";
import { CurrNav } from "@/components/Header";

export interface appState {
  lang: Lang;
  currNav: CurrNav;
}
const initState: appState = {
  lang: defaultLang,
  currNav: "home"
};

// 改变语言函数
function setLanguage(
  state = initState,
  action: Overwrite<Action, { preload: Lang }>
) {
  if (action.type === appAction.SET_LANGUAGE) {
    let newState = Object.assign({}, state);
    newState.lang = action.preload;
    return newState;
  }
  return state;
}
// Object.assign()方法用于将所有可枚举属性的值从一个或多个源对象复制到目标对象.它将返回目标对象
function setCurrNav(
  state = initState,
  action: Overwrite<Action, { preload: CurrNav }>
) {
  if (action.type === appAction.SET_CURR_NAV) {
    let newState = Object.assign({}, state);
    if (newState.currNav === action.preload) {
      return state;
    }
    newState.currNav = action.preload;
    return newState;
  }
  return state;
}

// 具体的动作处理函数,也即状态的更新函数
export default function reducer(
  state = initState,
  action: Overwrite<Action, { preload: any }>
) {
  switch (action.type) {
    case appAction.SET_LANGUAGE:
      return setLanguage(state, action);
    case appAction.SET_CURR_NAV:
      return setCurrNav(state, action);

    default:
      break;
  }
  return state;
}
