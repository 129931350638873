import { BuffRoute } from "@/routes/app";
import React from "react";
import axios from "axios";
import { BASE_URL, withCredentials } from "@/config/prod";
import { Route, Switch, Redirect } from "react-router";
let i = -1,
  j = -1;
export function iota(): number {
  return ++i;
}
export function iotaStr(): string {
  return ++j + "";
}
export function init() {
  axios.defaults.baseURL = BASE_URL;
  axios.defaults.withCredentials = withCredentials;
}
export function buildRoute(routes: BuffRoute[]) {
  return (
    <Switch>
      {routes.map((route, k) => {
        if (route.redirect) {
          return (
            <Redirect key={k} path={route.path as string} to={route.redirect} />
          );
        }
        const ChooseRoute = route.routeComponent ? route.routeComponent : Route;
        const Component = route.component
          ? route.component
          : () => {
              return buildRoute(route.routes || []);
            };
        return (
          <ChooseRoute
            key={k}
            path={route.path}
            exact={route.exact}
            // @ts-ignore
            render={props => <Component {...props} routes={route.routes} />}
          />
        );
      })}
    </Switch>
  );
}
