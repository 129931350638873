import * as appAction from "../actions/app";
import { Overwrite } from "utility-types";
import { Action } from "@/typings";

export interface loadingNum {
  time: boolean;
}
const initState: loadingNum = {
  time: false
};

// 改变欢迎页的时间
function setLoadingTime(
  state = initState,
  action: Overwrite<Action, { preload: boolean }>
) {
  if (action.type === appAction.SET_LOADSING_TIME) {
    let newState = Object.assign({}, state);
    if (newState.time === action.preload) {
      return state;
    }
    newState.time = action.preload;
    return newState;
  }
  return state;
}

// 具体的动作处理函数,也即状态的更新函数
export default function reducer(
  state = initState,
  action: Overwrite<Action, { preload: any }>
) {
  switch (action.type) {
    case appAction.SET_LOADSING_TIME:
      return setLoadingTime(state, action);
    default:
      break;
  }
  return state;
}
