import CommonHeader from "@/components/CommonHeader";
import { CurrNav } from "@/components/Header";
import { BASE_URL, CDN_BASE_URL } from "@/config/prod";
import { AppState } from "@/redux/stores/app";
import { Action } from "@/typings";
import gImg from "@/utils/img";
import localeName from "@/utils/localeName";
import newsApi, { InitMeta, MaterialList, ServicesList } from "@api/news";
import * as appAction from "@redux/actions/app";
import gSass from "@utils/sass";
// import gMedia from "@/utils/media";
import { Carousel } from "antd";
import csn from "classnames";
import React, { Component, createRef } from "react";
import { Helmet } from "react-helmet";
import { InjectedIntlProps, injectIntl } from "react-intl";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Dispatch } from "redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Footer from "@components/Footer";
import Header from "@components/Header";
import * as Scroll from "react-scroll";
var Links = Scroll.Link;
const style = gSass.index.service.index;
// let Element = Scroll.Element;

// export interface MaterialList {
//   name: string;
//   detailList: {
//     title: string;
//     parts: string[];
//   }[];
// }
interface Props {}
interface State {
  page: number;
  limit: number;
  count: number;
  filter: {
    languages: string;
    id: number;
  };
  openMask: boolean;
  pageInitMeta: InitMeta;
  servicesList: ServicesList[];
  isSelectNav: boolean;
  isSelectContent: string;
  isSelectId: number;
  materialList: MaterialList[];

  isImgSelectId: number;
  isCheckedId: number;
}
const mapStateToProps = (state: AppState) => ({
  app: state.app,
  lang: state.app.lang,
});
const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    setLanguage: (lang: string) => {
      dispatch(appAction.setLanguage(lang));
    },
    setCurrNav: (currNav: CurrNav) => {
      dispatch(appAction.setCurrNav(currNav));
    },
  };
};
// @ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
// @ts-ignore
@injectIntl
export default class Index extends Component<
  Props &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> &
    InjectedIntlProps,
  State
> {
  static defaultProps: ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> &
    InjectedIntlProps;
  scrollDiv = createRef<HTMLDivElement>();
  slider = createRef<Slider>();
  imgSlider = createRef<Slider>();
  carousel = createRef<Carousel>();
  constructor(props: any) {
    super(props);
    this.state = this.getInitState();
  }
  getInitState = (): State => {
    return {
      page: 0,
      limit: 8,
      count: 0,
      filter: {
        languages: "zh-cn",
        id: 0,
      },
      isCheckedId: 0,
      openMask: false,
      pageInitMeta: {
        title: "",
        subTitle: "",
        keywords: "",
        description: "",
      },
      servicesList: [],
      materialList: [],
      isSelectNav: false,
      isSelectContent: "",
      isSelectId: 0,
      isImgSelectId: 0,
    };
  };
  componentDidMount() {
    if (this.scrollDiv.current) {
      this.scrollDiv.current.scrollIntoView();
    }
    this.setState(
      {
        filter: {
          ...this.state.filter,
          languages: this.props.app.lang,
        },
      },
      () => {
        this.init();
      }
    );
  }
  // 获取语言修改后props值
  componentWillReceiveProps(nextProps: any) {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          languages: nextProps.lang,
        },
      },
      () => {
        this.init();
      }
    );
  }
  init = () => {
    this.listProducts();
  };
  listProducts = async () => {
    let { data: pageInitMeta } = await newsApi.getPageInitMeta();
    this.setState({
      pageInitMeta,
    });
    let { page, limit, filter, servicesList } = this.state;
    let { data: list } = await newsApi.getList({ page, limit, filter });
    servicesList = [];
    for (let v of list) {
      servicesList.push(v);
    }
    this.setState({
      servicesList,
    });
  };
  openMask = (name: string) => {
    this.setState({
      openMask: true,
    });
    let { materialList, servicesList } = this.state;
    materialList = [];
    for (let v of servicesList) {
      if (v.material_name === name) {
        for (let m of v.list) {
          materialList.push(m);
        }
      }
    }
    this.setState({
      materialList,
    });
  };
  render() {
    const { messages: m } = this.props.intl;
    let { pageInitMeta, servicesList, materialList, openMask, isCheckedId } =
      this.state;
    return (
      <div className={style.main} ref={this.scrollDiv}>
        <Header activeTitle="home" />
        <Helmet>
          <title>{pageInitMeta.title}</title>
          <meta name="description" content={pageInitMeta.description} />
          <meta name="keywords" content={pageInitMeta.keywords} />
        </Helmet>
        <div className={csn(style.headerBanner, "flex")}>
          <CommonHeader
            banner={gImg.service.serviceBg}
            mainTitle={m[localeName.app.common.导航.我们的服务]}
            subTitleOne={m[localeName.app.common.导航.我们的服务]}
            subTitleTwo={""}
          />
        </div>
        <div className={csn(style.serviceNavWrap, "flex")}>
          <div className={csn(style.navContent, "flex")}>
            <MediaQuery minWidth={1200}>
              <div className={csn(style.squareToEllipse, "flex")}>
                <div className={csn(style.logoWrap, "flex")}>
                  <img src={gImg.common.aLogoBlue} alt="" />
                </div>
                {servicesList.map((v, k) => {
                  return (
                    <Links
                      className={
                        v.id === isCheckedId
                          ? csn(style.squareItem, style.squareItemCenter)
                          : style.squareItem
                      }
                      key={k}
                      to="services"
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}
                      onClick={() => {
                        let arr = this.state.servicesList;
                        this.setState(
                          {
                            servicesList: arr,
                            isSelectId: k,
                            openMask: false,
                            isCheckedId: v.id,
                          },
                          () => {
                            this.carousel.current &&
                              this.carousel.current.goTo(this.state.isSelectId);
                          }
                        );
                      }}
                    >
                      <div className={style.name}>{v.name}</div>
                    </Links>
                  );
                })}
              </div>
            </MediaQuery>
            <MediaQuery maxWidth={1200}>
              <div className={csn(style.mobileNav, "flex")}>
                <div className={csn(style.logoWrap, "flex")}>
                  <img src={gImg.common.logoBigBlue} alt="" />
                </div>
                <div className={csn(style.navList, "flex")}>
                  {servicesList.map((v, k) => {
                    return (
                      <div
                        className={
                          v.id === isCheckedId
                            ? csn(
                                style.squareMobileItem,
                                style.squareMobileItemCenter
                              )
                            : style.squareMobileItem
                        }
                        key={k}
                        onClick={() => {
                          let arr = this.state.servicesList;

                          this.setState(
                            {
                              servicesList: arr,
                              isSelectId: k,
                              openMask: false,
                              isCheckedId: v.id,
                            },
                            () => {
                              this.carousel.current &&
                                this.carousel.current.goTo(
                                  this.state.isSelectId
                                );
                            }
                          );
                        }}
                      >
                        <div className={style.name}>{v.name}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </MediaQuery>
          </div>
        </div>
        <div className={csn(style.detailContentWrap, "flex")} id="services">
          <div className={style.detailWrap}>
            <MediaQuery minWidth={1200}>
              <Carousel
                speed={500}
                dots={true}
                slidesToShow={1}
                initialSlide={this.state.isSelectId}
                className={style.serviceList}
                ref={this.carousel}
                swipeToSlide={true}
                focusOnSelect={true}
                touchMove={false}
              >
                {servicesList.map((v, k) => {
                  return (
                    <div className={csn(style.item, "flex")} key={k}>
                      <div className={csn(style.left, "flex")}>
                        <div className={csn(style.leftWrap, "flex")}>
                          <div className={style.title}>{v.name}</div>
                          <div className={style.description}>
                            {v.description}
                          </div>
                          <div className={style.content}>{v.content}</div>
                          {v.material_name !== "" ? (
                            <div
                              className={style.materials}
                              onClick={() => {
                                let name = v.material_name;
                                this.openMask(name);
                              }}
                            >
                              {v.material_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className={csn(style.right, "flex")}>
                        {v.picture[0].is_video === true ? (
                          <div className={csn(style.mediaWrap, "flex")}>
                            <ReactPlayer
                              className={style.player}
                              loop={true}
                              muted
                              playing
                              url={CDN_BASE_URL + v.picture[0].url}
                              width="100%"
                              height="100%"
                            />
                          </div>
                        ) : (
                          <div className={csn(style.imgContentWrap, "flex")}>
                            <Slider
                              slidesToShow={1}
                              swipeToSlide={true}
                              infinite={true}
                              ref={this.slider}
                              slidesToScroll={1}
                              className={csn(style.imgWrap, "flex")}
                            >
                              {v.picture.map((v1, k1) => {
                                return (
                                  <div className={csn(style.imgItem, "flex")}>
                                    <img
                                      src={CDN_BASE_URL + v1.url}
                                      alt=""
                                      key={k1}
                                    />
                                  </div>
                                );
                              })}
                            </Slider>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </MediaQuery>
            <MediaQuery maxWidth={1200}>
              <Carousel
                speed={500}
                dots={true}
                slidesToShow={1}
                initialSlide={this.state.isSelectId}
                className={style.mobileServiceList}
                ref={this.carousel}
                swipeToSlide={true}
                focusOnSelect={true}
                touchMove={false}
              >
                {servicesList.map((v, k) => {
                  return (
                    <div className={csn(style.mobileItem, "flex")} key={k}>
                      <div className={csn(style.left, "flex")}>
                        <div className={csn(style.leftWrap, "flex")}>
                          <div className={style.title}>{v.name}</div>
                          <div className={style.description}>
                            {v.description}
                          </div>
                          <div className={style.content}>{v.content}</div>
                          {v.material_name !== "" ? (
                            <div
                              className={style.materials}
                              onClick={() => {
                                let name = v.material_name;
                                this.openMask(name);
                              }}
                            >
                              {v.material_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className={csn(style.right, "flex")}>
                        {v.picture[0].is_video === true ? (
                          <div className={csn(style.mediaWrap, "flex")}>
                            <ReactPlayer
                              className={style.player}
                              loop={true}
                              muted
                              playing
                              url={BASE_URL + v.picture[0].url}
                              width="100%"
                              height="100%"
                            />
                          </div>
                        ) : (
                          <div className={csn(style.imgContentWrap, "flex")}>
                            <Slider
                              slidesToShow={1}
                              swipeToSlide={true}
                              infinite={true}
                              ref={this.slider}
                              slidesToScroll={1}
                              className={csn(style.imgWrap, "flex")}
                            >
                              {v.picture.map((v1, k1) => {
                                return (
                                  <div className={csn(style.imgItem, "flex")}>
                                    <img
                                      src={CDN_BASE_URL + v1.url}
                                      alt=""
                                      key={k1}
                                    />
                                  </div>
                                );
                              })}
                            </Slider>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </MediaQuery>
          </div>
          {openMask ? (
            <div
              className={style.maskWrap}
              onClick={() => {
                this.setState({
                  openMask: false,
                });
              }}
            >
              <div className={csn(style.maskContent, "flex")}>
                <div className={csn(style.maskDetailContent, "flex")}>
                  {materialList.map((v, k) => {
                    return (
                      <div className={csn(style.detailContent, "flex")} key={k}>
                        <div className={style.title}>{v.name}</div>
                        <div className={csn(style.partsList, "flex")}>
                          {v.description.map((v1, k1) => {
                            return (
                              <div className={style.parts} key={k1}>
                                {v1}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className={style.projectConsultion}>
          <div className={csn(style.projectContent, "flex")}>
            <div className={csn(style.left, "flex")}>
              <div className={csn(style.leftWrap, "flex")}>
                <div className={style.title}>
                  {m[localeName.app.home.服务.为什么选择希荷]}
                </div>
                <div className={style.introduce}>
                  <span className={style.square}></span>
                  <p className={style.pageTxt}>
                    {m[localeName.app.home.服务.国际制造]}
                  </p>
                </div>
                <div className={style.introduce}>
                  <span className={style.square}></span>
                  <p className={style.pageTxt}>
                    {m[localeName.app.home.服务.长期的专业知识]}
                  </p>
                </div>
                <div className={style.introduce}>
                  <span className={style.square}></span>
                  <p className={style.pageTxt}>
                    {m[localeName.app.home.服务.自己的工具店]}
                  </p>
                </div>
                <div className={style.introduce}>
                  <span className={style.square}></span>
                  <p className={style.pageTxt}>
                    {m[localeName.app.home.服务.内部材料数据库]}
                  </p>
                </div>
                <div className={style.introduce}>
                  <span className={style.square}></span>
                  <p className={style.pageTxt}>
                    {m[localeName.app.home.服务.各种产品开发经验]}
                  </p>
                </div>
                {/* <div className={style.email}>sales@es-asiatech.com</div>
                <div className={style.descritpion}>
                  {m[localeName.app.home.服务.成本预估]}
                </div> */}
                <Link
                  to="/contact"
                  className={style.contact}
                  onClick={() => {
                    this.props.setCurrNav("contact");
                  }}
                >
                  {m[localeName.app.home.联系.联系我们]}
                </Link>
              </div>
            </div>
            <div className={csn(style.right, "flex")}>
              <div className={csn(style.rightContent, "flex")}>
                <img src={gImg.service.two} alt="" />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
