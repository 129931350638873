import * as appAction from "@/redux/actions/app";
import { AppState } from "@/redux/stores/app";
import { Action } from "@/typings";
import gImg from "@/utils/img";
import { Lang } from "@/utils/locale";
import localeName from "@utils/localeName";
import gSass from "@utils/sass";
import { Col, Icon, Row } from "antd";
import csn from "classnames";
import React, { Component } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import { CurrNav } from "@/components/Header";

const style = gSass.common.footer;
interface Props {}
interface State {
  productList: {
    name: string;
  }[];
  isOpenCode: boolean;
}
const mapStateToProps = (state: AppState) => ({
  app: state.app
});
const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    setLanguage: (lang: Lang) => {
      dispatch(appAction.setLanguage(lang));
    },
    setCurrNav: (currNav: CurrNav) => {
      dispatch(appAction.setCurrNav(currNav));
    }
  };
};
// @ts-ignore
@injectIntl
// @ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
export default class MyFooter extends Component<
  Props &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> &
    InjectedIntlProps,
  State
> {
  static defaultProps: ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> &
    InjectedIntlProps;
  constructor(props: any) {
    super(props);
    this.state = this.getInitState();
  }
  getInitState = (): State => {
    return {
      productList: [
        {
          name: localeName.app.home.服务.项目咨询
        },
        {
          name: localeName.app.home.服务.产品开发
        },
        {
          name: localeName.app.home.服务.工程设计
        },
        {
          name: localeName.app.home.服务.精密模具
        },
        {
          name: localeName.app.home.服务.注塑生产
        },
        {
          name: localeName.app.home.服务.高精度检测
        }
      ],
      isOpenCode: false
    };
  };
  render() {
    const { messages: m } = this.props.intl;
    return (
      <div className={csn(style.main, "flex")}>
        <Row className={style.footer}>
          <Col xs={24} sm={24} md={6}>
            <div className={style.footerLeft}>
              {/* 底部logo */}
              <Link to="/" className={style.logoWrap}>
                <img className={style.img} alt="logo" src={gImg.common.logo} />
              </Link>
              {/* 联系我们 */}
              <div className={csn(style.contact, "flex alignItemsCenter")}>
                <a
                  className={style.contactItem}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/esolution-mold-plastic-europe-gmbh/?viewAsMember=true"
                >
                  <Icon className={style.contactIcon} type="linkedin" />
                </a>
                <div
                  className={style.contactItem}
                  onClick={() => {
                    this.setState({
                      isOpenCode: !this.state.isOpenCode
                    });
                  }}
                >
                  <Icon className={style.contactIcon} type="wechat" />
                </div>
                <div
                  className={
                    this.state.isOpenCode ? style.wechatImgWrap : style.hidden
                  }
                >
                  <div className={style.wechatImg}>
                    <img src={gImg.common.code} alt="code"></img>
                  </div>
                </div>
                <a
                  className={style.contactItem}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/home"
                >
                  <Icon className={style.contactIcon} type="twitter" />
                </a>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={8}>
            {/* 底部内容简介 */}
            <div className={csn(style.footerMiddle, "flex")}>
              <div className={style.title}>
                {m[localeName.app.common.导航.联系我们]}
              </div>
              <div className={style.email}>
                {m[localeName.app.home.联系.邮箱]}：
                <a
                  className={style.contentTxt}
                  href="mailto:sales@es-asiatech.com"
                >
                  sale@es-asiatech.com
                  <div className={style.line}></div>
                </a>
              </div>
              <div className={style.phone}>
                {m[localeName.app.home.联系.电话]}：
                <div className={style.contentTxt}>
                  021-31167531
                  <div className={style.line}></div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={10}>
            <div className={csn(style.footerRight, "flex")}>
              <div className={style.title}>
                {m[localeName.app.common.导航.我们的服务]}
              </div>
              <div className={csn(style.productList, "flex")}>
                {this.state.productList.map((v, k) => {
                  return (
                    <Link
                      to="/service"
                      className={style.item}
                      key={k}
                      onClick={() => {
                        this.props.setCurrNav("service");
                      }}
                    >
                      {m[v.name as string]}
                    </Link>
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>
        {/* 备案号 */}
        <div className={csn(style.footerBottom, "flex")}>
          {/* <div className={style.recordNumber}>粤ICP 备12047342 号-1</div> */}
          <div className={style.recordInformation}>
            &nbsp;&nbsp; &copy;2020 {m[localeName.app.集团名]}{" "}
            <Link
              to={{ pathname: "http://www.beian.miit.gov.cn/" }}
              target="_blank"
              className={style.recordNum}
            >
              ICP备12047342号-1
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
