import React, { Component } from "react";
import gSass from "@utils/sass";
import csn from "classnames";
import { AppState } from "@/redux/stores/app";
import { Dispatch } from "redux";
import { Action } from "@/typings";
import * as appAction from "@redux/actions/app";
import { connect } from "react-redux";
import { InjectedIntlProps, injectIntl } from "react-intl";
import localeName from "@/utils/localeName";
const style = gSass.common.commonHeader;

interface Props {
  banner: string;
  mainTitle: string;
  subTitleOne: string;
  subTitleTwo: string;
}
interface State {}
const mapStateToProps = (state: AppState) => ({
  app: state.app
});
const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    setLanguage: (lang: string) => {
      dispatch(appAction.setLanguage(lang));
    }
  };
};
// @ts-ignore

@connect(mapStateToProps, mapDispatchToProps)
// @ts-ignore
@injectIntl
export default class CommonHeader extends Component<
  Props &
    InjectedIntlProps &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>,
  State
> {
  static defaultProps: ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> &
    InjectedIntlProps;
  constructor(props: any) {
    super(props);
    this.state = this.getInitState();
  }
  getInitState = (): State => {
    return {};
  };
  componentDidMount() {}
  render() {
    const { messages: m } = this.props.intl;
    return (
      <div
        className={csn(style.bannerWrap, "flex")}
        style={{
          background: "url(" + this.props.banner + ") center/cover no-repeat"
        }}
      >
        {/* <div className={style.bannerImg} >
          <img src={this.props.banner} alt="" />
        </div> */}{" "}
        <div className={csn(style.bannerContent, "flex")}>
          <div className={style.mainTitle}>{this.props.mainTitle}</div>
          <div className={style.subTitle}>
            {m[localeName.app.common.导航.首页]}&nbsp;&nbsp;>&nbsp;&nbsp;
            {this.props.subTitleOne}
            {this.props.subTitleTwo !== "" ? (
              <span>&nbsp;&nbsp;>&nbsp;&nbsp;{this.props.subTitleTwo}</span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
