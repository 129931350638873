import Page404 from "@pages/common/Page404";
import { RouteProps, Route } from "react-router";
import pathMap from "./pathMap";
import About from "@/pages/about/Index";
import Recruit from "@/pages/recruit/Index";
import School from "@/pages/recruit/School";
import News from "@/pages/news/Index";
import NewsDetail from "@/pages/news/Detail";
import Contact from "@/pages/contact/Index";
import Home from "@pages/Home";
import ProductIndex from "@/pages/product/Index";
import ServiceIndex from "@/pages/service/Index";
import Basic from "@components/Basic";
export interface BuffRoute extends RouteProps {
  name?: string;
  // 子路由
  routes?: BuffRoute[];
  // 路由所使用的组件
  routeComponent?: typeof Route;
  redirect?: string;
}

const routes: BuffRoute[] = [
  {
    name: "404",
    path: "/404",
    component: Page404
  },
  {
    name: "home",
    path: pathMap.index,
    component: Basic,
    routes: [
      {
        name: "about",
        exact: true,
        path: "/about",
        component: About
      },
      {
        name: "news",
        path: "/news",
        exact: true,
        component: News
      },
      {
        name: "news/detail",
        path: "/news/detail",
        exact: true,
        component: NewsDetail
      },
      {
        name: "contact",
        path: "/contact",
        exact: true,
        component: Contact
      },
      {
        name: "recruit",
        path: "/recruit",
        exact: true,
        component: Recruit
      },
      {
        name: "school",
        path: "/school",
        exact: true,
        component: School
      },
      {
        name: "product",
        path: "/product",
        exact: true,
        component: ProductIndex
      },
      {
        name: "service",
        path: "/service",
        exact: true,
        component: ServiceIndex
      },
      {
        name: "home",
        path: pathMap.index,
        exact: true,
        component: Home
      },
      {
        name: "404",
        redirect: "/404"
      }
    ]
  }
  // {
  //   name: "home",
  //   path: "/",
  //   exact: true,
  //   redirect: `/${defaultLang}/`,
  // },
];
export default routes;
