import newsApi, { NewsDetail, InitMeta } from "@api/news";
import gSass from "@utils/sass";
import React, { Component, createRef } from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { AppState } from "@/redux/stores/app";
import { Dispatch } from "redux";
import * as appAction from "@redux/actions/app";
import csn from "classnames";
import { Action } from "@/typings";
import qs from "qs";
// import { Link } from "react-router-dom"
import gImg from "@utils/img";
import { message } from "antd";
import { Helmet } from "react-helmet";
import Footer from "@components/Footer";
import Header from "@components/Header";
const style = gSass.index.news.detail;

interface Props {}
interface State {
  languages: string;
  id: number;
  isPlay: boolean;
  page: number;
  limit: number;
  filter: {};
  detail: NewsDetail;
  preArticle: {
    id: number;
    isExist: boolean;
  };
  nextArticle: {
    id: number;
    isExist: boolean;
  };
  pageInitMeta: InitMeta;
}
const mapStateToProps = (state: AppState) => ({
  app: state.app,
  lang: state.app.lang,
});
const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    setLanguage: (lang: string) => {
      dispatch(appAction.setLanguage(lang));
    },
  };
};
// @ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
export default class Detail extends Component<
  Props &
    RouteComponentProps &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>,
  State
> {
  scrollDiv = createRef<HTMLDivElement>();
  constructor(props: any) {
    super(props);
    this.state = this.getInitState();
  }
  getInitState = (): State => {
    return {
      languages: "zh-cn",
      pageInitMeta: {
        title: "",
        subTitle: "",
        keywords: "",
        description: "",
      },
      id: 28,
      isPlay: false,
      page: 1,
      limit: 10,
      filter: {},
      detail: {
        id: 28,
        author: "",
        ctime: "",
        source: "",
        name: "",
        content: "",
      },
      preArticle: {
        id: 0,
        isExist: true,
      },
      nextArticle: {
        id: 0,
        isExist: true,
      },
    };
  };
  componentDidMount() {
    if (this.scrollDiv.current) {
      this.scrollDiv.current.scrollIntoView();
    }
    this.setState(
      {
        languages: this.props.app.lang,
      },
      () => {
        this.init();
      }
    );
  }
  init = () => {
    this.listKeywords();
    let { id} = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    this.setState(
      {
        id:parseInt(id as string) 
      },
      this.getDetail
    );
  };
  listKeywords = async () => {
    let { data: pageInitMeta } = await newsApi.getPageInitMeta();
    this.setState({
      pageInitMeta,
    });
  };
  getDetail = async () => {
    let languages = this.state.languages;
    let { data: detail } = await newsApi.getDetail(this.state.id, languages);
    this.setState({
      detail,
      preArticle: detail.pre,
      nextArticle: detail.next,
    });
  };
  preArticle = async () => {
    console.log(111);
    let languages = this.state.languages;
    if (this.state.preArticle.isExist === true) {
      let { data: detail } = await newsApi.getDetail(
        this.state.preArticle.id,
        languages
      );
      this.setState({
        detail,
        preArticle: detail.pre,
        nextArticle: detail.next,
      });
    } else {
      message.info("当前暂无上一篇");
    }
  };
  nextArticle = async () => {
    console.log(2222);
    let languages = this.state.languages;
    if (this.state.nextArticle.isExist === true) {
      let { data: detail } = await newsApi.getDetail(
        this.state.nextArticle.id,
        languages
      );
      this.setState({
        detail,
        preArticle: detail.pre,
        nextArticle: detail.next,
      });
    } else {
      message.info("当前暂无下一篇");
    }
  };
  componentWillReceiveProps(nextProps: any) {
    this.setState(
      {
        languages: nextProps.lang,
      },
      () => {
        this.init();
      }
    );
  }
  render() {
    let { detail, pageInitMeta } = this.state;
    return (
      <div className={style.main} ref={this.scrollDiv}>
        <Header activeTitle="home" />
        <Helmet>
          <title>{pageInitMeta.title}</title>
          <meta name="description" content={pageInitMeta.description} />
          <meta name="keywords" content={pageInitMeta.keywords} />
        </Helmet>
        <div className={style.center}>
          <div
            className={csn(
              style.article,
              "flex flexDirectionColumn alignItemsCenter"
            )}
          >
            <div className={style.articleTitle}>{detail.name}</div>
            <div className={style.articleTime}>
              {/* {detail.ctime.substr(0, 10)} */}
              日期:&nbsp;&nbsp; &nbsp; {detail.ctime}
            </div>
            <div
              className={csn(
                style.articleContent,
                "flex flexDirectionColumn alignItemsCenter"
              )}
            >
              <div
                className={style.articleDetail}
                dangerouslySetInnerHTML={{ __html: detail.content }}
              ></div>
            </div>
          </div>
          <div
            className={csn(
              style.switchWrap,
              "flex alignItemsCenter justifyContentSpaceBetween"
            )}
          >
            <div
              className={style.arrowWrap}
              onClick={() => {
                this.preArticle();
              }}
            >
              <img className={style.arrow} src={gImg.news.left} alt="左" />
            </div>
            <div
              className={style.arrowWrap}
              onClick={() => {
                this.nextArticle();
              }}
            >
              <img className={style.arrow} src={gImg.news.right} alt="右" />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
