import zhCN from "@/locales/zh-cn";
import EN from "@/locales/en";
import DE from "@/locales/de";

export enum Lang {
  简体中文 = "zh-cn",
  德语 = "de",
  English = "en"
}
const msg: Record<string, any> = {
  [Lang.English]: EN,
  [Lang.简体中文]: zhCN,
  [Lang.德语]: DE
};
export default {
  lang: Lang,
  msg
};
