export default {
  common: {
    global: require("@sass/global.scss"),
    header: require("@sass/common/header.module.scss"),
    footer: require("@sass/common/footer.module.scss"),
    loading: require("@sass/common/loading.module.scss"),
    commonHeader: require("@sass/common/commonHeader.module.scss")
  },
  index: {
    home: require("@sass/index/home.module.scss"),
    contact: {
      index: require("@sass/index/contact/index.module.scss")
    },
    about: {
      index: require("@sass/index/about/about.module.scss")
    },
    news: {
      index: require("@sass/index/news/index.module.scss"),
      detail: require("@sass/index/news/detail.module.scss")
    },
    product: {
      index: require("@sass/index/product/index.module.scss")
    },
    recruit: {
      society: require("@sass/index/recruit/society.module.scss"),
      school: require("@sass/index/recruit/school.module.scss")
    },
    service: {
      index: require("@sass/index/service/index.module.scss")
    }
  }
};
