import { iotaStr as s } from "@utils/fn";
export default {
  app: {
    common: {
      导航: {
        首页: s(),
        关于我们: s(),
        我们的产品: s(),
        我们的服务: s(),
        招聘: s(),
        新闻下载: s(),
        联系我们: s(),
        了解详情: s(),
        查看更多: s(),
        欢迎浏览: s(),
        // 地区
        中国: s(),
        日本: s(),
        上海: s(),
        新加坡: s(),
        德国: s(),
        波兰: s(),
        瑞士: s(),
        法国: s(),
        西班牙: s(),
        捷克: s(),
      },
    },
    欢迎浏览我们的网页: s(),
    公司名: s(),
    集团名: s(),
    加载更多: s(),
    home: {
      首页: {
        主标题一: s(),
        主标题二: s(),
        关于希荷: s(),
        // 关于我们
        ES起步: s(),
        始终坚持匠心: s(),
        // 我们的产品
        ES致力于分段: s(),
        ES致力于: s(),

        // 项目
        严谨高效: s(),
        我们的原则: s(),

        项目咨询: s(),
        客户输入: s(),

        技术方案: s(),
        产品分析: s(),

        合同签订: s(),
        合同签订客户: s(),

        交货完成: s(),
        输送到终端客户: s(),
        // 公司分布
        分布简介: s(),
        // 最新发布
        我们的目标: s(),
        身边的: s(),
        最新发布: s(),
        坚信最初: s(),
        细小做到精致: s(),
      },
      关于我们: {
        公司简介: s(),
        ES于2010年: s(),
        合作伙伴AWM: s(),
        AWM是一家: s(),
        发展历史: s(),
        生产零件批准过程: s(),
        阶段一: s(),
        充分理解客户需求: s(),
        明确项目成员职责: s(),
        制定设计失效: s(),
        阶段二: s(),
        制定详细的过程: s(),
        控制流程图: s(),
        测量系统分析: s(),
        阶段三: s(),
        当所有客户需求: s(),
        ES会向客户提供样品阶段: s(),
        ES可为客户提供: s(),
      },
      产品: {
        车身调节系列及传动系列相关齿轮产品: s(),
        车顶调节器: s(),
        座椅调节器: s(),
        车尾调节器: s(),
        车灯调节器: s(),
        电子刹车EPB: s(),
        传动系统EPS: s(),
        产品系列: s(),
        其他产品: s(),
        连接器类产品: s(),
        电动卷帘和门锁齿轮产品: s(),
        电子医疗: s(),
        结构件:s(),
        模具描述:s(),
      },
      服务: {
        项目咨询: s(),
        产品开发: s(),
        工程设计: s(),
        精密模具: s(),
        注塑生产: s(),
        高精度检测: s(),
        请您将图纸: s(),
        发送至: s(),
        成本预估: s(),
        为什么选择希荷: s(),
        国际制造: s(),
        长期的专业知识: s(),
        自己的工具店: s(),
        内部材料数据库: s(),
        各种产品开发经验: s(),
      },
      招聘: {
        // 流程
        网络申请: s(),
        参加宣讲会: s(),
        笔试: s(),
        面试: s(),
        OFFER: s(),
        入职: s(),
        关爱生活品质: s(),
        关注动作服务: s(),
        重视员工体验: s(),
        贴心定制福利: s(),
        员工免费定期体检: s(),
        补充商业医疗险: s(),
        弹性工作时间: s(),

        优质工作餐保障: s(),
        宿舍安排保障: s(),
        交通出行保障: s(),

        丰富的部门团建活动: s(),
        趣味运动和家庭日: s(),
        员工活动中心: s(),
        各类俱乐部: s(),

        传统佳节礼物: s(),
        生日祝福新婚关爱: s(),
        入职纪念: s(),
        长期服务奖励: s(),

        校招流程: s(),
        为什么选择希荷: s(),
        校园招聘: s(),
        社会招聘: s(),
        岗位职责: s(),
        要求: s(),
        薪资待遇: s(),

        薪酬: s(),
        具有行业竞争力的薪资: s(),
        绩效导向的多样化激励: s(),
        市场匹配的年度涨薪机制: s(),
        校招岗位: s(),
        投递简历: s(),
        福利: s(),
      },
      新闻: {
        新闻资讯: s(),
        资料下载: s(),
      },
      联系: {
        联系我们: s(),
        销售经理: s(),
        国际销售经理: s(),
        总经理: s(),
        公司: s(),
        姓名: s(),
        电话: s(),
        邮箱: s(),
        国家: s(),
        主题: s(),
        街道: s(),
        城镇: s(),
        地区: s(),
        邮编: s(),
        留言: s(),
        提交: s(),
        销售支持: s(),
        您有任何疑问: s(),
        对于您提出的关于汽车传动系统齿轮件: s(),
        新加坡地区技术: s(),
        中国地区技术: s(),
        欧洲地区技术: s(),
        模具询价:s(),
        注塑产品询价:s(),
        冲压件产品询价:s(),
        技术支持:s(),
        塑料粒子采购:s(),
        其他服务商:s(),
        人事和招聘:s(),
        
        中国:s(),
        英国:s(),
        美国:s(),
        加拿大:s(),
        俄罗斯:s(),
        埃及:s(),
        希腊:s(),
        法国:s(),
        德国:s(),
        日本:s(),
        其它:s(),
      },
    },
  },
};
