import { AppState } from "@/redux/stores/app";
import { Action } from "@/typings";
import gImg from "@/utils/img";
import locale, { Lang } from "@/utils/locale";
import * as appAction from "@redux/actions/app";
import gSass from "@utils/sass";
import { Col, Icon, Layout, Row } from "antd";
import csn from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import localeName from "@/utils/localeName";
import { InjectedIntlProps, injectIntl } from "react-intl";
// import "antd/dist/antd.css";
const { Header } = Layout;
const style = gSass.common.header;

export type CurrNav =
  | "home"
  | "about"
  | "product"
  | "service"
  | "school"
  | "news"
  | "contact"
  | "";

export interface Nav {
  navIdx: CurrNav;
  key: string;
  name: string;
  langId?: string;
  href: string;
  isChecked?: boolean;
}
interface Props {
  activeTitle: string;
}
interface State {
  isActiveMobileHeader: boolean;
  isSelectLanguage: boolean;
  isSelectService: boolean;
  navList: Nav[];
  progressShow:boolean;
  languageList: {
    img: string;
    langId: Lang;
  }[];
}
const mapStateToProps = (state: AppState) => ({
  lang: state.app.lang,
  currNav: state.app.currNav
});
// mapStateToProps返回app:state.app,是因为可能需要维护的state属性有很多,但是某组件主要官族的适合标题设置有关的属性,因此只需取出state.pageTitle就可以了,并把该状态转化为属性
// 设置动作与UI的连接函数
// mapDispatchToProps是用于设置属性值改变后的触发函数setLanguage()
const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    setLanguage: (lang: Lang) => {
      dispatch(appAction.setLanguage(lang));
    },
    setCurrNav: (currNav: CurrNav) => {
      dispatch(appAction.setCurrNav(currNav));
    }
  };
};
// @ts-ignore
@injectIntl
// @ts-ignore
@withRouter
// @ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
export default class MyHeader extends Component<
  Props &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> &
    RouteComponentProps &
    InjectedIntlProps,
  State
> {
  static defaultProps: ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> &
    RouteComponentProps &
    InjectedIntlProps;
  constructor(props: any) {
    super(props);
    this.state = this.getInitState();
  }
  getInitState = (): State => {
    return {
      isActiveMobileHeader: false,
      isSelectLanguage: false,
      isSelectService: false,
      navList: [
        {
          key: "home",
          name: "首页",
          langId: localeName.app.common.导航.首页,
          href: "/",
          navIdx: "home",
          isChecked: false
        },
        {
          key: "about",
          name: "关于我们",
          langId: localeName.app.common.导航.关于我们,
          href: "/about",
          navIdx: "about",
          isChecked: false
        },
        {
          key: "product",
          name: "我们的产品",
          langId: localeName.app.common.导航.我们的产品,
          href: "/product",
          navIdx: "product",
          isChecked: false
        },
        {
          key: "service",
          name: "我们的服务",
          langId: localeName.app.common.导航.我们的服务,
          href: "/service",
          navIdx: "service",

          isChecked: false
        },
        {
          key: "resolution",
          name: "招聘",
          langId: localeName.app.common.导航.招聘,
          href: "/school",
          navIdx: "school"
        },
        {
          key: "news",
          name: "新闻/下载",
          langId: localeName.app.common.导航.新闻下载,
          href: "/news",
          navIdx: "news",
          isChecked: false
        },
        {
          key: "contact",
          name: "联系我们",
          langId: localeName.app.common.导航.联系我们,
          href: "/contact",
          navIdx: "contact",
          isChecked: false
        }
      ],
      languageList: [
        {
          langId: locale.lang.简体中文,
          img: gImg.common.zh
        },
        {
          langId: locale.lang.English,
          img: gImg.common.en
        },
        {
          langId: locale.lang.德语,
          img: gImg.common.de
        }
      ],
      progressShow:true,
    };
  };
  showMobileHeader = () => {
    let navList = this.state.navList;
    for (let v of navList) {
      v.isChecked = false;
    }
    this.setState({
      isActiveMobileHeader: !this.state.isActiveMobileHeader,
      navList
    });
  };
  render() {
    let currNav = this.props.currNav;
    const { messages: m } = this.props.intl;
    let {
      isSelectLanguage,
      isActiveMobileHeader,
      navList,
      languageList,
      progressShow
    } = this.state;
    return (
      <div className={style.headerHeight}>
        <Header className={style.header}>
          <Row className={csn(style.main, "flex")}>
            {/* 手机版菜单按钮 */}
            <Col
              xs={6}
              sm={4}
              md={0}
              className={style.home}
              onClick={this.showMobileHeader}
            >
              <Icon
                type={isActiveMobileHeader ? "close-circle" : "menu"}
                className={style.menuIcon}
              ></Icon>
            </Col>
            {/* logo */}
            <Col xs={12} sm={14} md={6} className={style.logoWrap}>
              <Link to={"/"} className={csn(style.logo, "flex")}>
                <img
                  src={gImg.common.aLogo}
                  className={style.logoImg}
                  alt="希荷logo"
                />
              </Link>
            </Col>
            {/* 电脑菜单list */}
            <Col xs={0} sm={8} md={17}>
              <div className={csn(style.navList, "flex")}>
                {this.state.navList.map((nav, k) => {
                  return (
                    <Link
                      to={nav.href}
                      className={csn(style.navItem)}
                      onClick={() => {
                        this.changeCurrNav(nav.navIdx);
                        let arr = this.state.navList;
                        for (let v of arr) {
                          v.isChecked = false;
                        }
                        arr[k].isChecked = true;
                        this.setState({
                          navList: arr
                        });
                      }}
                      key={k}
                    >
                      <div
                        className={
                          currNav === nav.navIdx
                            ? style.navItemTitleActive
                            : style.navItemTitle
                        }
                      >
                        {m[nav.langId as string]}
                      </div>
                    </Link>
                  );
                })}
              </div>
            </Col>
            <Col xs={6} sm={2} md={1} className={style.icons}>
              <Icon
                type="global"
                className={style.icon}
                onClick={() => {
                  this.setState({
                    isSelectLanguage: !isSelectLanguage
                  });
                }}
              ></Icon>
              {/* 语言选择区域 */}
              <div
                className={isSelectLanguage ? style.selectLanguage : "hidden"}
              >
                {languageList.map((v, k) => {
                  return (
                    <div className={csn(style.selectLanguageItem)} key={k}>
                      <img
                        className={style.languageIcon}
                        src={v.img}
                        onClick={() => {
                          this.changeLang(v.langId);
                        }}
                        alt=""
                      ></img>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>

          {/* 手机端菜单导航 */}
          <div className={isActiveMobileHeader ? style.mobileHeader : "hidden"}>
            <div className={style.content}>
              <div className={style.navListMob}>
                {navList.map((nav, k) => {
                  return (
                    <Link
                      to={nav.href}
                      className={csn(
                        style.navItem,
                        "flex alignItemsCenter justifyContentSpaceBetween"
                      )}
                      key={k}
                      onClick={() => {
                        this.setState({
                          isActiveMobileHeader: !isActiveMobileHeader
                        });
                      }}
                    >
                      <div className={style.navTitle}>
                        {m[nav.langId as string]}
                      </div>
                      <Icon className={style.navIcon} type="right"></Icon>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </Header>
        {/* <Progress
          strokeWidth={4}
          strokeColor="#ffffff"
          className={style.progress}
          status="active"
          percent={100}
          showInfo={false}
          type="line"
          strokeLinecap="round"
        /> */}
        <div className={ progressShow?style.progressContanier:'hidden'}>
          <div className={style.progressWrap}>
          {m[localeName.app.common.导航.欢迎浏览]}
          </div>
          <div className={style.close} onClick={() => {
                        this.setState({
                          progressShow:false,
                        });
           }}>×</div>
        </div>
      </div>
    );
  }
  changeCurrNav = (curr: CurrNav) => {
    this.props.setCurrNav(curr);
  };
  changeLang = (language: Lang) => {
    this.setState(
      {
        isSelectLanguage: false
      },
      () => {
        this.props.setLanguage(language);
      }
    );
  };
}
