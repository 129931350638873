import { CurrNav } from "@/components/Header";
import Loading from "@/components/Loading";
import { CDN_BASE_URL } from "@/config/prod";
import { AppState } from "@/redux/stores/app";
import {
  getIndexProducts,
  ProductsAllType,
  ProductsType,
} from "@/services/product";
import { Action } from "@/typings";
import gImg from "@/utils/img";
import localeName from "@/utils/localeName";
import gMedia from "@/utils/media";
import newsApi, { InitMeta } from "@api/news";
import * as appAction from "@redux/actions/app";
import gSass from "@utils/sass";
import csn from "classnames";
import RcQueueAnim from "rc-queue-anim";
import ScrollOverPack from "rc-scroll-anim/lib/ScrollOverPack";
import React, { Component, createRef } from "react";
import { Helmet } from "react-helmet";
import { InjectedIntlProps, injectIntl } from "react-intl";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Dispatch } from "redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Footer from "@components/Footer";
import Header from "@components/Header";

const style = gSass.index.home;
const setting = {
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  infinite: true,
};
const settingProduct = {
  // autoplay: true,
  centerMode: true,
  infinite: true,
  centerPadding: "200px",
  slidesToShow: 1,
  speed: 500,
};
const settingProductMobile = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
export interface Project {
  img: string;
  title: string;
  content: string;
}

export interface PicWall {
  img: string;
}
export interface LocationDot {
  name: string;
}
interface Props {}
interface State {
  page: number;
  limit: number;
  count: number;
  filter: {
    languages: string;
    id: number;
  };
  isPlayVideo: boolean;
  pageInitMeta: InitMeta;
  projectList: Project[];
  productsList: ProductsType[];
  productsAllList: ProductsAllType[];
  clientX: number;
  pictureList: PicWall[];
  categoryList: ProductsAllType[];
  productId: number;
  locationList: LocationDot[];
  language: string;
}
const mapStateToProps = (state: AppState) => ({
  app: state.app,
  lang: state.app.lang,
});
const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    setLoadingTime: (time: boolean) => {
      dispatch(appAction.setLoadingTime(time));
    },
    setCurrNav: (currNav: CurrNav) => {
      dispatch(appAction.setCurrNav(currNav));
    },
    setLanguage: (lang: string) => {
      dispatch(appAction.setLanguage(lang));
    },
  };
};
// @ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
// @ts-ignore
@injectIntl
export default class Home extends Component<
  Props &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> &
    InjectedIntlProps,
  State
> {
  static defaultProps: ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> &
    InjectedIntlProps;
  scrollDiv = createRef<HTMLDivElement>();

  constructor(props: any) {
    super(props);
    this.state = this.getInitState();
  }
  getInitState = (): State => {
    return {
      page: 0,
      limit: 1,
      count: 0,
      filter: {
        languages: "zh-cn",
        id: 0,
      },
      pictureList: [
        {
          img: gImg.home.show2,
        },
        {
          img: gImg.home.show3,
        },
        {
          img: gImg.home.show4,
        },
      ],
      productId: 2,
      clientX: 0,
      isPlayVideo: false,
      pageInitMeta: {
        title: "",
        subTitle: "",
        keywords: "",
        description: "",
      },
      projectList: [
        {
          img: gImg.home.one,
          title: localeName.app.home.首页.项目咨询,
          content: localeName.app.home.首页.客户输入,
        },
        {
          img: gImg.home.two,
          title: localeName.app.home.首页.技术方案,
          content: localeName.app.home.首页.产品分析,
        },
        {
          img: gImg.home.three,
          title: localeName.app.home.首页.合同签订,
          content: localeName.app.home.首页.合同签订客户,
        },
        {
          img: gImg.home.four,
          title: localeName.app.home.首页.交货完成,
          content: localeName.app.home.首页.输送到终端客户,
        },
      ],
      productsAllList: [],
      productsList: [],
      categoryList: [],
      locationList: [
        {
          name: localeName.app.common.导航.日本,
        },
        {
          name: localeName.app.common.导航.上海,
        },
        {
          name: localeName.app.common.导航.新加坡,
        },
        {
          name: localeName.app.common.导航.德国,
        },
        {
          name: localeName.app.common.导航.波兰,
        },
        {
          name: localeName.app.common.导航.瑞士,
        },
        {
          name: localeName.app.common.导航.法国,
        },
        {
          name: localeName.app.common.导航.西班牙,
        },
        {
          name: localeName.app.common.导航.捷克,
        },
      ],
      language: "",
    };
  };
  componentDidMount() {
    if (this.scrollDiv.current) {
      this.scrollDiv.current.scrollIntoView();
    }
    this.init();
  }
  init = () => {
    this.listNews();
  };
  listNews = async () => {
    // let { filter } = this.state;
    this.setState({
      filter: {
        ...this.state.filter,
        languages: this.props.app.lang,
      },
    });
    let { data: pageInitMeta } = await newsApi.getPageInitMeta();
    this.setState({
      pageInitMeta,
    });
    let { productsList, categoryList, page, limit, filter } = this.state;
    let { data: productsAllList } = await getIndexProducts({
      page,
      limit,
      filter,
    });
    if (productsAllList.length > 0) {
      productsList = [];
      categoryList = [];
      for (let i of productsAllList[0].picture) {
        productsList.push(i);
      }
      for (let j of productsAllList) {
        categoryList.push(j);
      }
    }
    this.setState({
      productsList,
      categoryList,
    });
  };
  componentWillUnmount() {
    if (this.scrollDiv.current) {
      this.scrollDiv.current.scrollIntoView();
    }
  }
  // 获取语言修改后props值
  componentWillReceiveProps(nextProps: any) {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          languages: nextProps.lang,
        },
      },
      () => {
        this.init();
      }
    );
  }
  // 改变产品列表
  changeProductList = async (index: number) => {
    let { productsList, page, limit, filter } = this.state;
    let { data: productLists } = await getIndexProducts({
      page,
      limit,
      filter,
    });
    productsList = [];
    for (let v of productLists[0].picture) {
      productsList.push(v);
    }
    this.setState({
      productsList,
      productId: index,
    });
  };
  render() {
    const { messages: m } = this.props.intl;
    let {
      isPlayVideo,
      pageInitMeta,
      projectList,
      productsList,
      categoryList,
      productId,
      pictureList,
      locationList,
      filter,
    } = this.state;
    return (
      <div className={style.center} ref={this.scrollDiv}>
        <Header activeTitle="home" />
        <Helmet>
          <title>{pageInitMeta.title}</title>
          <meta name="description" content={pageInitMeta.description} />
          <meta name="keywords" content={pageInitMeta.keywords} />
        </Helmet>
        <div className={style.loading}>
          <Loading />
        </div>
        {/* banner start */}
        <div className={style.banner}>
          <ReactPlayer
            className={style.player}
            loop={true}
            muted
            url={gMedia.home.banner}
            playing={isPlayVideo ? false : true}
            width="100%"
            height="100%"
          />
          <div className={style.playerModal}>
            <div className={style.playerTitleFa}>
              <div className={style.playerTitle}>
                <div> {m[localeName.app.home.首页.主标题一]}</div>
                <div>{m[localeName.app.home.首页.主标题二]}</div>
              </div>
            </div>
          </div>
        </div>
        {/*关于我们 */}
        <div className={style.about} id="about">
          <div className={csn(style.aboutWrap, "flex")}>
            <ScrollOverPack playScale={0.2} location="about">
              <RcQueueAnim
                leaveReverse
                ease="easeInOutQuart"
                type="top"
                delay={100}
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div key="1" className={csn(style.left, "flex")}>
                  <div className={style.logoAndTitle}>
                    <div className={csn(style.logoTitleWrap, "flex")}>
                      <div className={style.title}>
                        {m[localeName.app.home.首页.关于希荷]}
                      </div>
                    </div>
                  </div>
                  <div className={csn(style.imgWrap, "flex")}>
                    <img src={gImg.home.about} alt="" />
                  </div>
                </div>
                <div key="2" className={csn(style.right, "flex")}>
                  <div className={csn(style.rightContent, "flex")}>
                    <RcQueueAnim
                      leaveReverse
                      ease="easeInOutQuart"
                      type="top"
                      delay={100}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div key="1" className={style.title}>
                        {/* {m[localeName.app.集团名]} */}
                      </div>
                      <div key="2" className={style.content}>
                        {m[localeName.app.home.首页.ES起步]}
                      </div>
                      <div key="3" className={style.content}>
                        {m[localeName.app.home.首页.始终坚持匠心]}
                      </div>
                      <Link
                        key="4"
                        to="/about"
                        className={style.more}
                        onClick={() => {
                          this.props.setCurrNav("about");
                        }}
                      >
                        <span className={style.moreText}>
                          {m[localeName.app.common.导航.查看更多]}
                        </span>
                      </Link>
                    </RcQueueAnim>
                  </div>
                </div>
              </RcQueueAnim>
            </ScrollOverPack>
          </div>
        </div>
        {/* 我们的产品 */}

        <div className={csn(style.ourProducts, "flex")} id="product">
          <div className={csn(style.ourProductsHeader, "flex")}>
            <div className={csn(style.topContent, "flex")}>
              <div className={csn(style.title)}>
                {m[localeName.app.common.导航.我们的产品]}
              </div>
              <div className={style.content}>
                {m[localeName.app.home.首页.ES致力于]} <br />
                {m[localeName.app.home.首页.ES致力于分段]}
              </div>
              <div
                className={csn(
                  filter.languages === "en" || filter.languages === "de"
                    ? csn(style.categoryListDe, style.categoryList, "flex")
                    : style.categoryList,
                  "flex"
                )}
              >
                {categoryList.map((v, k) => {
                  return (
                    <div
                      className={
                        productId === v.id
                          ? csn(style.activeItem, style.item)
                          : style.item
                      }
                      key={k}
                      onClick={() => {
                        this.setState(
                          {
                            filter: {
                              ...this.state.filter,
                              id: v.id,
                            },
                          },
                          () => {
                            this.changeProductList(v.id);
                          }
                        );
                      }}
                    >
                      {v.name}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={style.content}>
              {m[localeName.app.home.首页.ES致力于]}
              <br />
              {m[localeName.app.home.首页.ES致力于分段]}
            </div>
          </div>
          <div
            className={
              filter.languages === "en" || filter.languages === "de"
                ? csn(style.productsContainerDe, style.productsContainer)
                : style.productsContainer
            }
          >
            <div className={style.productsListWrap}>
              <MediaQuery minWidth={1200}>
                <Slider {...settingProduct} className={style.productsList}>
                  {productsList.map((v, k) => {
                    return (
                      <div className={style.item} key={k + 1}>
                        <img
                          className={style.img}
                          alt=""
                          src={CDN_BASE_URL + v.url}
                        />
                      </div>
                    );
                  })}
                </Slider>
              </MediaQuery>
              <MediaQuery maxWidth={1200}>
                <Slider
                  {...settingProductMobile}
                  className={csn(style.mobileImgList, "flex")}
                >
                  {productsList.map((v, k) => {
                    return (
                      <div className={style.item} key={k + 1}>
                        <img
                          className={style.img}
                          alt=""
                          src={CDN_BASE_URL + v.url}
                        />
                      </div>
                    );
                  })}
                </Slider>
              </MediaQuery>
            </div>
          </div>
        </div>

        {/* 项目管理 */}
        <div className={csn(style.projectManage, "flex")}>
          <div className={csn(style.projectContent, "flex")}>
            <div className={csn(style.headerWrap, "flex")}>
              <div className={style.mainTitle}>
                {m[localeName.app.home.首页.严谨高效]}
              </div>
              <div className={style.subTitle}>
                {m[localeName.app.home.首页.我们的原则]}
              </div>
            </div>
            <div className={csn(style.projectList, "flex")}>
              {projectList.map((v, k) => {
                return (
                  <div className={csn(style.item, "flex")} key={k}>
                    <div className={csn(style.topWrap, "flex")}>
                      <div className={style.line}></div>
                      <div className={csn(style.imgWrap, "flex")}>
                        <img src={v.img} alt="" />
                        <div className={style.circle}></div>
                        <div className={style.circle}></div>
                        <div className={style.circle}></div>
                      </div>
                      <div className={style.line}></div>
                    </div>
                    <div className={csn(style.bottomWrap, "flex")}>
                      <div className={style.title}> {m[v.title as string]}</div>
                      <div className={style.content}>
                        <div>{m[v.content as string]}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* 公司分布 */}
        <div className={csn(style.companyLocation, "flex")}>
          <MediaQuery minWidth={1200}>
            <div className={csn(style.locationContent, "flex")}>
              <div className={csn(style.header, "flex")}>
                <div className={csn(style.imgWrap, "flex")}>
                  <img src={gImg.common.logoBig} alt="" />
                </div>
              </div>
              <div className={csn(style.bottom, "flex")}>
                {locationList.map((v, k) => {
                  return (
                    <div className={style.item} key={k}>
                      <div className={style.location}>
                        <span>{m[v.name as string]}</span>
                      </div>
                      <div className={csn(style.items, "flex")}>
                        <div className={style.dot}></div>
                        <div className={csn(style.circle, style.circle1)}></div>
                        <div className={csn(style.circle, style.circle2)}></div>
                        <div className={csn(style.circle, style.circle3)}></div>
                      </div>
                    </div>
                  );
                })}
                <div className={style.introduceWrap}>
                  <div className={csn(style.introduceContent, "flex")}>
                    <div className={style.introduce}>
                      {m[localeName.app.home.首页.分布简介]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={1200}>
            <div className={csn(style.mobileLocationWrap, "flex")}>
              <div className={csn(style.imgWrap, "flex")}>
                <img src={gImg.common.logoBig} alt="" />
              </div>
              <div className={csn(style.imgWrapMap, "flex")}>
                <img src={gImg.home.mobile} alt="" />
              </div>
              <div className={style.introduceWrap}>
                <div className={csn(style.introduceContent, "flex")}>
                  <div className={style.introduce}>
                    {m[localeName.app.home.首页.分布简介]}
                  </div>
                </div>
              </div>
            </div>
          </MediaQuery>
        </div>
        {/* 团队合作 */}
        <div className={csn(style.teamWrap, "flex")}>
          <div className={style.mainTitle}>
            {m[localeName.app.home.首页.最新发布]}
          </div>
          <div className={csn(style.teamContent, "flex")}>
            <div className={csn(style.left, "flex")}>
              <Slider
                {...setting}
                className={
                  filter.languages === "en"
                    ? csn(style.leftEnContent, style.leftContent)
                    : filter.languages === "de"
                    ? csn(style.leftDeContent, style.leftContent)
                    : style.leftContent
                }
              >
                {pictureList.map((v, k) => {
                  return (
                    <div className={csn(style.imgWrap, "flex")} key={k}>
                      <img src={v.img} alt="" />
                      <div className={style.line}></div>
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div className={csn(style.right, "flex")}>
              <div className={csn(style.imgWrap, "flex")}>
                <img src={gImg.home.handshake} alt="" />
              </div>
              <div className={csn(style.detailContent, "flex")}>
                <div className={style.purpose}>
                  {m[localeName.app.home.首页.坚信最初]}
                </div>
                <div className={style.title}>
                  {m[localeName.app.home.首页.我们的目标]}
                </div>
                <div className={style.subTitle}>
                  "{m[localeName.app.home.首页.身边的]}"
                </div>
                <div className={style.content}>
                  {m[localeName.app.home.首页.细小做到精致]}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
