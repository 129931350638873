import "@sass/app.scss";
import { ConfigProvider } from "antd";
import zh_CN from "antd/lib/locale-provider/zh_CN";
import React from "react";
import { hydrate, render } from "react-dom";
import { addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import zh from "react-intl/locale-data/zh";
import de from "react-intl/locale-data/zh";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import ErrorBoundary from "./components/ErrorBoundary";
import configStore from "@redux/stores/app";
import { PersistGate } from "redux-persist/integration/react";
import { init } from "./utils/fn";
import * as Sentry from "@sentry/browser";
import pkg from "../package.json";

addLocaleData([...zh, ...en, ...de]);
const { store, persistor } = configStore();
init();
Sentry.init({
  dsn: "https://91379f7b55224367a84c32c42e0e19c0@sentry.buffge.com/10",
  release: `${pkg.name}@${pkg.version}`,
  environment: process.env.NODE_ENV
});
const rootElement = document.getElementById("root");
const Root = () => (
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider locale={zh_CN}>
          <App />
        </ConfigProvider>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);
if (rootElement!.hasChildNodes()) {
  hydrate(<Root />, rootElement);
} else {
  render(<Root />, rootElement);
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
