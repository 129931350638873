import gSass from "@utils/sass";
import csn from "classnames";
import React, { Component, createRef } from "react";
import gImg from "@/utils/img";
import { AppState } from "@/redux/stores/app";
import { Dispatch } from "redux";
import { Action } from "@/typings";
import localeName from "@/utils/localeName";
import * as appAction from "@redux/actions/app";
import { connect } from "react-redux";
import newsApi, { InitMeta, RecruitType } from "@api/news";
import { Helmet } from "react-helmet";
import CommonHeader from "@/components/CommonHeader";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Empty, Icon, Pagination } from "antd";
import RcQueueAnim from "rc-queue-anim";
import Footer from "@components/Footer";
import Header from "@components/Header";
// import ScrollOverPack from "rc-scroll-anim/lib/ScrollOverPack";

const style = gSass.index.recruit.society;

interface Props {}
interface State {
  pageInitMeta: InitMeta;
  recruitList: RecruitType[];
  page: number;
  limit: number;
  count: number;
  filter: {
    languages: string;
    id: number;
  };
  type: number;
}

const mapStateToProps = (state: AppState) => ({
  app: state.app,
  lang: state.app.lang,
});
const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    setLanguage: (lang: string) => {
      dispatch(appAction.setLanguage(lang));
    },
  };
};
// @ts-ignore
@injectIntl
// @ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
export default class Index extends Component<
  Props &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> &
    InjectedIntlProps,
  State
> {
  scrollDiv = createRef<HTMLDivElement>();
  constructor(props: any) {
    super(props);
    this.state = this.getInitState();
  }
  getInitState = (): State => {
    return {
      type: 0, //代表社会招聘
      pageInitMeta: {
        title: "",
        subTitle: "",
        keywords: "",
        description: "",
      },
      page: 1,
      limit: 5,
      count: 5,
      filter: {
        languages: "zh-cn",
        id: 0,
      },
      recruitList: [],
    };
  };
  componentDidMount() {
    if (this.scrollDiv.current) {
      this.scrollDiv.current.scrollIntoView();
    }
    this.setState(
      {
        filter: {
          ...this.state.filter,
          languages: this.props.app.lang,
        },
      },
      () => {
        this.init();
      }
    );
  }
  init = () => {
    this.listKeywords();
  };
  listKeywords = async () => {
    console.log(1111);
    console.log(this.state.filter);
    let { page, limit, filter } = this.state;
    let { data: pageInitMeta } = await newsApi.getPageInitMeta();
    this.setState({
      pageInitMeta,
    });
    let {
      data: { list: recruitList },
    } = await newsApi.getSociety({ page, limit, filter });
    this.setState({
      recruitList,
    });
  };
  // 获取语言修改后props值
  componentWillReceiveProps(nextProps: any) {
    console.log(222);
    this.setState(
      {
        filter: {
          ...this.state.filter,
          languages: nextProps.lang,
        },
      },
      () => {
        this.init();
      }
    );
  }
  render() {
    const { messages: m } = this.props.intl;
    let { pageInitMeta, recruitList, page, count } = this.state;
    return (
      <div className={style.main} ref={this.scrollDiv}>
        <Header activeTitle="home" />
        <Helmet>
          <title>{pageInitMeta.title}</title>
          <meta name="description" content={pageInitMeta.description} />
          <meta name="keywords" content={pageInitMeta.keywords} />
        </Helmet>
        <div className={csn(style.headerBanner, "flex")}>
          <CommonHeader
            banner={gImg.recruit.society}
            mainTitle={m[localeName.app.common.导航.招聘]}
            subTitleOne={m[localeName.app.common.导航.招聘]}
            subTitleTwo={m[localeName.app.home.招聘.社会招聘]}
          />
        </div>
        {/* 标题 */}
        <div className={csn(style.recruitHeader, "flex")}>
          <div className={csn(style.headerContent, "flex")}>
            <Link to="/school" className={csn(style.itemWrap, "flex")}>
              <div className={style.title}>
                {m[localeName.app.home.招聘.校园招聘]}
              </div>
            </Link>
            <Link to="/recruit" className={csn(style.itemWrap, "flex")}>
              <div className={style.title}>
                {m[localeName.app.home.招聘.社会招聘]}
              </div>
            </Link>
          </div>
        </div>
        {/* 招聘列表 */}
        <div className={style.recruitList} id="society">
          {/* <ScrollOverPack location="society"> */}
          <RcQueueAnim
            leaveReverse
            ease="easeInOutQuart"
            type="bottom"
            delay={300}
          >
            {recruitList.length > 0 ? (
              recruitList.map((v, k) => {
                return (
                  <div
                    className={csn(style.item, "flex")}
                    key={k}
                    onClick={() => {
                      let recruitList = this.state.recruitList;
                      if (recruitList[k].isActive) {
                        for (let recruit of recruitList) {
                          recruit.isActive = false;
                        }
                      } else {
                        for (let recruit of recruitList) {
                          recruit.isActive = false;
                        }
                        recruitList[k].isActive = true;
                      }
                      this.setState({
                        recruitList,
                      });
                    }}
                  >
                    <div className={csn(style.titleWrap, "flex")}>
                      <div className={style.title}>{v.title}</div>
                      <div className={csn(style.iconWrap, "flex")}>
                        {v.isActive ? (
                          <Icon
                            type="up"
                            style={{ fontSize: 16, color: "#717171" }}
                          ></Icon>
                        ) : (
                          <Icon
                            type="down"
                            style={{ fontSize: 16, color: "#717171" }}
                          ></Icon>
                        )}
                      </div>
                    </div>
                    <div
                      className={csn(
                        v.isActive ? style.active : "",
                        style.detailInfo,
                        "flex"
                      )}
                    >
                      <div className={style.title}>
                        {m[localeName.app.home.招聘.岗位职责]}:
                      </div>
                      <div
                        className={style.demand}
                        dangerouslySetInnerHTML={{ __html: v.data[0] }}
                      ></div>
                      <div className={style.title}>
                        {m[localeName.app.home.招聘.要求]}:
                      </div>
                      <div
                        className={style.demand}
                        dangerouslySetInnerHTML={{ __html: v.data[1] }}
                      ></div>
                      <div className={style.title}>
                        {m[localeName.app.home.招聘.薪资待遇]}:
                      </div>
                      <div
                        className={style.demand}
                        dangerouslySetInnerHTML={{ __html: v.data[2] }}
                      ></div>
                      <a
                        className={csn(style.bottomWrap, "flex")}
                        href={"mailto:" + v.email}
                      >
                        email:
                        <div className={style.bottomItem}>
                          &nbsp;&nbsp;{v.email}
                        </div>
                      </a>
                    </div>
                  </div>
                );
              })
            ) : (
              <Empty style={{ marginTop: "50px" }}></Empty>
            )}
          </RcQueueAnim>
          {/* </ScrollOverPack> */}
          {recruitList.length > 0 ? (
            <Pagination
              className={style.page}
              defaultCurrent={page}
              total={count}
              onChange={(page) => {
                this.setState({
                  page,
                });
              }}
            ></Pagination>
          ) : null}
        </div>
        <Footer />
      </div>
    );
  }
}
