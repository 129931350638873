/*
 * Author: buff <admin@buffge.com>
 * Created on : 2019-1-4, 16:50:36
 * QQ:1515888956
 */

export const BASE_URL = "https://www.esgroup-tech.com/"
export const CDN_BASE_URL = "https://xihecdn.imlimit.com/"
export const SESSION_EXPIRE = 15 * 60
export const withCredentials = true
